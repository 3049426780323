import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import EvaluationParticipantSurveyContainer from "src/app/containers/Evaluation/EvaluationParticipantSurvey.container";
import { useParams } from "react-router-dom";
import { getSurveyById } from "src/app/store/features/survey/survey.selectors";
import ForceFreshStrategy from "src/app/hoc/caching/ForceFreshStrategy.hoc";
import { fetchSurveyAsync } from "src/app/store/features/survey/survey.actions";
import { SaveSurveyQuestion } from "src/app/types/api/survey.types";
import { uiSaveSurvey } from "src/app/store/features/ui/survey/ui.survey.actions";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function EvaluationParticipantSurveyView(props: Props) {

	const {
		fetchSurvey,
		saveSurvey,
		survey,
	} = props;

	const {
		evaluationId = "0",
		surveyId = "0",
	} = useParams();

	const _handleSaveSurvey = (questions: SaveSurveyQuestion[]) => {
		saveSurvey({
			surveyId: +surveyId,
			evaluationId: +evaluationId,
			questions,
		});
	};

	return (
		<ForceFreshStrategy
			request={ () => fetchSurvey(+surveyId) }
			state={ survey(+surveyId) }
		>
			{
				survey =>
					<EvaluationParticipantSurveyContainer
						survey={ survey }
						onSave={ _handleSaveSurvey }
					/>
			}
		</ForceFreshStrategy>
	);
}

const mapStateToProps = (state: RootState) => ({
	survey: (surveyId: number) => getSurveyById(state, surveyId),
});

const mapDispatchToProps = {
	fetchSurvey: fetchSurveyAsync.request,
	saveSurvey: uiSaveSurvey,
};

export default connect(mapStateToProps, mapDispatchToProps)(EvaluationParticipantSurveyView);
