import { Error, FailurePayload, FailurePayloadWithId, SuccessPayload, SuccessPayloadWithId } from "src/app/types/api/api.types";
import { EmptyActionCreator, PayloadActionCreator } from "typesafe-actions";
import { PayloadAction } from "typesafe-actions/dist/type-helpers";
import moment from "moment";
import { Nullable } from "src/app/types/util.types";

export type NetworkAsyncAction<T, R = any> = {
	request: EmptyActionCreator<`${ string }_REQUEST`> | PayloadActionCreator<`${ string }_REQUEST`, R>,
	success: PayloadActionCreator<`${ string }_SUCCESS`, SuccessPayload<T>> | PayloadActionCreator<`${ string }_SUCCESS`, SuccessPayloadWithId<T, any>>,
	failure: PayloadActionCreator<`${ string }_FAILURE`, FailurePayload> | PayloadActionCreator<`${ string }_FAILURE`, FailurePayloadWithId>
}

export type Action<T, R = any> =
	| PayloadAction<`${ string }_FAILURE`, FailurePayload>
	| PayloadAction<`${ string }_REQUEST`, R>
	| PayloadAction<`${ string }_SUCCESS`, SuccessPayload<T>>

export type ActionWithIds<T, R = any> =
	| PayloadAction<`${ string }_FAILURE`, FailurePayloadWithId>
	| PayloadAction<`${ string }_REQUEST`, R>
	| PayloadAction<`${ string }_SUCCESS`, SuccessPayloadWithId<T>>

export type PaginatedActions<T, R extends FetchPaginatedDataBasicRequest> =
	| PayloadAction<`${ string }_FAILURE`, FailurePayloadWithId<any>>
	| PayloadAction<`${ string }_REQUEST`, R>
	| PayloadAction<`${ string }_SUCCESS`, SuccessPayloadWithId<T, R>>


export enum DataState {
	PRESENT = "DATA_PRESENT",
	NOT_PRESENT = "DATA_NOT_PRESENT"
}

export enum ErrorState {
	PRESENT = "ERROR_PRESENT",
	NOT_PRESENT = "ERROR_NOT_PRESENT"
}

export enum LoadingState {
	LOADING = "LOADING",
	NOT_LOADING = "NOT_LOADING"
}

type InitialState = {
	dataState: DataState.NOT_PRESENT,
	loadingState: LoadingState,
	errorState: ErrorState.NOT_PRESENT
};

type DataPresent<T> = {
	dataState: DataState.PRESENT,
	loadingState: LoadingState,
	errorState: ErrorState.NOT_PRESENT,
	fetchedAt: moment.Moment,
	data: T
};

type ErrorPresent = {
	dataState: DataState.NOT_PRESENT,
	errorState: ErrorState.PRESENT,
	loadingState: LoadingState,
	errors: Error[]
};

type DataAndErrorPresent<T> = {
	dataState: DataState.PRESENT,
	loadingState: LoadingState,
	errorState: ErrorState.PRESENT,
	data: T,
	fetchedAt: moment.Moment,
	errors: Error[]
}

export type StateReducer<T> =
	| InitialState
	| DataPresent<T>
	| ErrorPresent
	| DataAndErrorPresent<T>;

export type ArrayStateReducer<T> = {
	id: string | number,
	reducer: StateReducer<T>
}[];

// Pagination

export type PaginationStateReducer<
	T,
	S extends Nullable<string> = Nullable<string>,
	F extends FetchPaginatedDataBasicRequestFilters = FetchPaginatedDataBasicRequestFilters
> = {
	pages: {
		pageIndex: number
		data: StateReducer<T>
	}[]
	meta: PaginationStateReducerMeta<S, F>
}

export type PaginationStateReducerMeta<
	S extends Nullable<string>,
	F extends FetchPaginatedDataBasicRequestFilters
> = {
	totalCount: number
	actualPageIndex: number
	actualPageSize: number
	actualSearch: Nullable<string>
	actualSort: S
	actualFilters: F
}

export type FetchPaginatedDataBasicRequest = {
	pageIndex: number // default pageIndex = 0
	pageSize: number // default pageSize = 30
	sort: Nullable<string>
	isBoundaryPage: boolean
	filters: FetchPaginatedDataBasicRequestFilters
	search: Nullable<string>
}

export type FetchPaginatedDataBasicRequestFilters = {
	[ key: string ]: string[]
}
