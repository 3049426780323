import { RouteObject } from "react-router/dist/lib/context";
import { Route, Routes } from "react-router-dom";
import { Fragment } from "react";
import { isNull } from "src/app/utils/typeguards";
import { v4 as createUniqueId } from "uuid";

type Props = {
	routes: RouteObject[]
}

function RouterMapper(props: Props) {
	const { routes } = props;

	return (
		<Routes>
			{ createRoutes(routes) }
		</Routes>
	);
}

const createRoutes = (routes: RouteObject[]) => routes.map(renderNestedRoute);

const renderRoutes = (route: RouteObject) => {
	const { children, index, ...props } = route;
	if (isNull(children) || children.length === 0) {
		return <Route index={ index } { ...props }/>;
	} else {
		return (
			<Route { ...props }>
				{ children.map(renderNestedRoute) }
			</Route>
		);
	}
};

const renderNestedRoute = (route: RouteObject) => {
	return (
		<Fragment key={ createUniqueId() }>
			{ renderRoutes(route) }
		</Fragment>
	);
};

export default (RouterMapper);
