import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import { uiCreateEvaluation } from "src/app/store/features/ui/evaluation/ui.evaluation.actions";
import EvaluationCreateContainer from "src/app/containers/Evaluation/EvaluationCreate.container";
import CallbackPromptProvider from "src/app/hoc/providers/CallbackPrompt.provider";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function EvaluationCreateView(props: Props) {
	const {
		createEvaluation,
	} = props;

	return (
		<CallbackPromptProvider>
			<EvaluationCreateContainer
				onCreateEvaluation={ createEvaluation }
			/>
		</CallbackPromptProvider>
	);
}

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {
	createEvaluation: uiCreateEvaluation,
};

export default connect(mapStateToProps, mapDispatchToProps)(EvaluationCreateView);
