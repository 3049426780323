import { createNetworkAction } from "src/app/utils/redux";
import { FailurePayloadWithId, SuccessPayload, SuccessPayloadWithId } from "src/app/types/api/api.types";
import { DetailedSurvey, ExternalSurvey, FetchExternalSurveyRequest, SaveExternalSurveyPayload, SaveSurveyPayload, SubmitSurveyPayload } from "src/app/types/api/survey.types";

export const fetchExternalSurveyAsync = createNetworkAction<FetchExternalSurveyRequest, SuccessPayloadWithId<ExternalSurvey>, FailurePayloadWithId>("SURVEY__FETCH_EXTERNAL_SURVEY");
export const saveExternalSurveyAsync = createNetworkAction<SaveExternalSurveyPayload, SuccessPayload<ExternalSurvey>>("SURVEY__SAVE_EXTERNAL_SURVEY");
export const submitExternalSurveyAsync = createNetworkAction<SaveExternalSurveyPayload, SuccessPayload<ExternalSurvey>>("SURVEY__SUBMIT_EXTERNAL_SURVEY");

export const fetchSurveyAsync = createNetworkAction<number, SuccessPayloadWithId<DetailedSurvey>, FailurePayloadWithId>("SURVEY__FETCH_SURVEY");
export const saveSurveyAsync = createNetworkAction<SaveSurveyPayload, SuccessPayload<DetailedSurvey>>("SURVEY__SAVE_SURVEY");
export const submitSurveyAsync = createNetworkAction<SubmitSurveyPayload, SuccessPayload<DetailedSurvey>>("SURVEY__SUBMIT_SURVEY");
