import * as axios from "src/app/store/services/api.service";
import * as toast from "src/app/store/services/toast.service";
import * as file from "src/app/store/services/file.service";

export type Services = typeof services;

const services = {
	axios,
	toast,
	file,
};

export default services;
