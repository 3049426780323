import { EvaluationStatus as Status } from "src/app/types/api/evaluation.types";
import { HiCheckCircle, HiPlus } from "react-icons/hi";
import { Badge, FlowbiteColors } from "flowbite-react";
import { EnumDictionary } from "src/app/types/util.types";
import { ComponentProps, FC } from "react";
import { FaFlagCheckered, FaHourglassStart } from "react-icons/fa";
import { evaluationStatusDictionary } from "src/app/utils/constants/dictionaries";

type Props = {
	status: Status
};

const evaluationStatusBadgeColorDictionary: EnumDictionary<Status, keyof FlowbiteColors> = {
	[ Status.CREATED ]: "blue",
	[ Status.STARTED ]: "warning",
	[ Status.PUBLISHED ]: "success",
	[ Status.FINISHED ]: "gray",
};

const evaluationStatusBadgeIconDictionary: EnumDictionary<Status, FC<ComponentProps<"svg">>> = {
	[ Status.CREATED ]: HiPlus,
	[ Status.STARTED ]: FaHourglassStart,
	[ Status.PUBLISHED ]: HiCheckCircle,
	[ Status.FINISHED ]: FaFlagCheckered,
};

function EvaluationStatus(props: Props) {

	const {
		status,
	} = props;

	return (
		<Badge
			className="w-fit"
			color={ evaluationStatusBadgeColorDictionary[ status ] }
			size="xs"
			icon={ evaluationStatusBadgeIconDictionary[ status ] }
		>
			{ evaluationStatusDictionary[ status ] }
		</Badge>
	);
}

export default (EvaluationStatus);
