import { LoginPayload } from "src/app/types/api/user.types";
import { Form, FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateEmail, validatePassword } from "src/app/utils/forms";

export type LoginReducerForm = Required<LoginPayload>;

export const loginFormInitialState: Form<LoginReducerForm> = {
	email: createFormField(""),
	password: createFormField(""),
	remember: createFormField(false),
	otp: createFormField(""),
};

export const loginFormValidator: FormValidator<LoginReducerForm> = {
	email: validateEmail,
	password: validatePassword,
	remember: () => null,
	otp: () => null,
};
