import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import { displayToast, addMessage, removeMessageById, removeMessagesFromView } from "src/app/store/features/message/message.actions";
import { ReducerSnackbarMessage } from "src/app/types/ui/message.types";

const reducer = combineReducers({
	notificationCounter: createReducer(0)
		.handleAction([ displayToast ], (state) => (state + 1)),
	messages: createReducer([] as ReducerSnackbarMessage[])
		.handleAction([ addMessage ], (state, action) => {
			if (state.length > 0) {
				return [ ...state, { id: _getGreatestId(state), ...action.payload } ];
			} else {
				return [ { id: INITIAL_ID, ...action.payload } ];
			}
		})
		.handleAction([ removeMessagesFromView ], (state, action) => state.filter(message => message.view !== action.payload))
		.handleAction([ removeMessageById ], (state, action) => state.filter(message => message.id !== action.payload)),
});

const INITIAL_ID = 1;
const _getGreatestId = (state: ReducerSnackbarMessage[]) => state.reduce((prev, next) => next.id > prev ? next.id : prev, INITIAL_ID);

export default reducer;
