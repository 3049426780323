/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserScopeContext } from "src/app/hoc/providers/UserScope.provider";
import { To } from "@remix-run/router";
import { NavigateOptions } from "react-router/dist/lib/context";
import { isNotNull, isString } from "src/app/utils/typeguards";

function useUserScopeNavigate() {
	const navigate = useNavigate();
	const userScope = useContext(UserScopeContext);

	const _navigateToOrganization = (to: To, options?: NavigateOptions) => {
		const urlPrefix = `/organization/${ userScope.organization.id }`;
		_navigate(urlPrefix, to, options);
	};

	const _navigate = (urlPrefix: string, to: To, options?: NavigateOptions) => {
		if (isString(to)) {
			navigate(`${ urlPrefix }${ to }`, options);
		} else {
			navigate({
				...to,
				pathname: isNotNull(to.pathname) ? `${ urlPrefix }${ to.pathname }` : undefined,
			}, options);
		}
	};

	// Skip useFormPrompt hook logic
	const _forceNavigateToOrganization = (to: To, options?: NavigateOptions) => {
		const urlPrefix = `/organization/${ userScope.organization.id }`;
		_navigate(urlPrefix, to, { ...options, state: { ...options?.state, forcePrompt: true } });
	};

	const _getLink = (link: string) => {
		const urlPrefix = `/organization/${ userScope.organization.id }`;
		return `${ urlPrefix }${ link }`;
	};

	return {
		navigate: _navigateToOrganization,
		forceNavigate: _forceNavigateToOrganization,
		getLink: _getLink,
	};
}

export default useUserScopeNavigate;
