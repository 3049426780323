import { Component, ReactNode } from "react";
import { Button } from "flowbite-react";
import { HiOutlineRefresh } from "react-icons/hi";
import { RootState } from "src/app/store/root.reducer";
import { getLoggedUser } from "src/app/store/features/user/user.selectors";
import { sendErrorAsync } from "src/app/store/features/misc/misc.actions";
import { connect } from "react-redux";
import { getBrowserInfo } from "src/app/utils/helpers";
import { DataState } from "src/app/types/redux.types";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps
	& {
		children: ReactNode
	};

type State = {
	hasError: boolean
};

class AppErrorBoundary extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidCatch(error: any, errorInfo: any) {
		this.props.sendError({
			errorBoundary: "App",
			...(this.props.loggedUser.dataState === DataState.PRESENT && { loggedUser: this.props.loggedUser.data }),
			location: this.props.location,
			error: error.toString(),
			browserInfo: getBrowserInfo(),
			errorInfo,
		});
	}

	render() {
		if (this.state.hasError) {
			return (
				<div className="flex min-h-screen flex-col items-center justify-center py-16">
					<h1 className="mb-3 w-4/5 text-center font-bold dark:text-white text-xl lg:text-2xl xl:text-4xl">
						Coś poszło nie tak
					</h1>
					<p className="mb-6 w-4/5 text-center text-base lg:text-lg text-gray-500 dark:text-gray-300">
						Jeżeli odświeżenie strony nie przynosi oczekiwanego rezultatu, skontaktuj się z administratorem
					</p>
					<Button href="/">
						<div className="mr-1 flex items-center gap-x-2">
							<span><HiOutlineRefresh className="text-xl"/></span>
							<span>Odśwież</span>
						</div>
					</Button>
				</div>
			);
		}

		return this.props.children;
	}
}

const mapStateToProps = (state: RootState) => ({
	location: state.router.location,
	loggedUser: getLoggedUser(state),
});

const mapDispatchToProps = {
	sendError: sendErrorAsync.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppErrorBoundary);
