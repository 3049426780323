import Avatar from "src/app/components/Utils/Avatar.component";
import { SimpleUser } from "src/app/types/api/user.types";
import useUserScopeNavigate from "src/app/utils/hooks/useUserScopeNavigate";
import { canViewUser } from "src/app/utils/abilities";
import { Link } from "react-router-dom";

type Props = {
	user: SimpleUser
	withoutLink?: boolean
};

function UserAvatar(props: Props) {

	const {
		user,
		withoutLink = false
	} = props;

	const { getLink } = useUserScopeNavigate();

	if (canViewUser(user) && !withoutLink) {
		return (
			<Link
				className="flex items-center gap-2 w-fit group"
				to={ getLink(`/users/${ user.id }`) }
			>
				<Avatar
					alt={ `${ user.name }-${ user.surname }-avatar` }
					img={ user.image?.thumb }
					placeholderInitials={ `${ user.name[ 0 ] }${ user.surname[ 0 ] }` }
					size="sm"
				/>
				<span className="text-primary-800 group-hover:text-primary-600 translate">
					{ `${ user.name } ${ user.surname }` }
				</span>
			</Link>
		);
	} else {
		return (
			<div className="flex items-center gap-2">
				<Avatar
					alt={ `${ user.name }-${ user.surname }-avatar` }
					img={ user.image?.thumb }
					placeholderInitials={ `${ user.name[ 0 ] }${ user.surname[ 0 ] }` }
					size="sm"
				/>
				<span>
					{ `${ user.name } ${ user.surname }` }
				</span>
			</div>
		);
	}
}

export default (UserAvatar);
