/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { createNetworkAction } from "src/app/utils/redux";
import { SuccessPayload, SuccessPayloadWithId } from "src/app/types/api/api.types";
import { CreateExternalParticipantPayload, CreateParticipantPayload, DeleteExternalParticipantPayload, DeleteParticipantPayload, DetailedParticipant, ExternalImportParticipantsPayload, ExternalSendReminderToParticipantsPayload, ImportParticipantsPayload, SendReminderToParticipantsPayload, UpdateExternalParticipantPayload, UpdateParticipantPayload } from "src/app/types/api/participant.types";
import { DetailedEvaluation } from "src/app/types/api/evaluation.types";

export const createParticipantAsync = createNetworkAction<CreateParticipantPayload, SuccessPayload<DetailedParticipant>>("PARTICIPANT__CREATE_PARTICIPANT");
export const updateParticipantAsync = createNetworkAction<UpdateParticipantPayload, SuccessPayload<DetailedParticipant>>("PARTICIPANT__UPDATE_PARTICIPANT");
export const deleteParticipantByIdAsync = createNetworkAction<DeleteParticipantPayload, SuccessPayloadWithId<{ id: number }>>("PARTICIPANT__DELETE_PARTICIPANT");

export const sendReminderToParticipantsAsync = createNetworkAction<SendReminderToParticipantsPayload, SuccessPayload<DetailedEvaluation>>("PARTICIPANT__SEND_REMINDER_TO_PARTICIPANTS");
export const importParticipantsAsync = createNetworkAction<ImportParticipantsPayload, SuccessPayload<DetailedEvaluation>>("PARTICIPANT__IMPORT_PARTICIPANTS");

export const createExternalParticipantAsync = createNetworkAction<CreateExternalParticipantPayload, SuccessPayload<DetailedParticipant>>("PARTICIPANT__CREATE_EXTERNAL_PARTICIPANT");
export const updateExternalParticipantAsync = createNetworkAction<UpdateExternalParticipantPayload, SuccessPayload<DetailedParticipant>>("PARTICIPANT__UPDATE_EXTERNAL_PARTICIPANT");
export const deleteExternalParticipantByIdAsync = createNetworkAction<DeleteExternalParticipantPayload, SuccessPayloadWithId<{ id: number }>>("PARTICIPANT__DELETE_EXTERNAL_PARTICIPANT");

export const externalSendReminderToParticipantsAsync = createNetworkAction<ExternalSendReminderToParticipantsPayload, SuccessPayload<DetailedEvaluation>>("PARTICIPANT__EXTERNAL_SEND_REMINDER_TO_PARTICIPANTS");
export const externalImportParticipantsAsync = createNetworkAction<ExternalImportParticipantsPayload, SuccessPayload<DetailedEvaluation>>("PARTICIPANT__EXTERNAL_IMPORT_PARTICIPANTS");
