/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { Organization, SimpleOrganization } from "src/app/types/api/organization.types";
import { Nullable } from "src/app/types/util.types";
import { SimpleUser, User } from "src/app/types/api/user.types";
import { Participant } from "src/app/types/api/participant.types";

export enum EvaluationAbility {
	VIEW = "evaluation.view",
	UPDATE = "evaluation.update",
	DELETE = "evaluation.delete",
	ADD_OR_DELETE_PARTICIPANT = "evaluation.addOrDeleteParticipant",
	FINISH = "evaluation.finishEvaluation",
	GENERATE_REPORT = "evaluation.generateReport",
	PUBLISH_REPORT = "evaluation.publishReport",
	MASS_REMINDER = "evaluation.massReminder"
}

export enum EvaluationStatus {
	CREATED = "CREATED",
	STARTED = "STARTED",
	PUBLISHED = "PUBLISHED",
	FINISHED = "FINISHED"
}

export enum EvaluationType {
	MPI = "MPI",
	LPI = "LPI"
}

export enum EvaluationLanguage {
	POLISH = "POLISH",
	ENGLISH = "ENGLISH"
}

export enum EvaluationScheduledStartType {
	MANUAL = "MANUAL",
	SCHEDULED_AT = "SCHEDULED_AT",
}

export type SimpleEvaluation = { // EvaluationViewModel::simple
	id: number
	name: string
	evaluationStatus: EvaluationStatus
	evaluationType: EvaluationType

	organizationId: string
	organization: Nullable<SimpleOrganization>

	creatorUserId: number
	creatorUser: SimpleUser

	coordinatorUserId: number
	coordinatorUser: SimpleUser

	companyName: string
	evaluationLanguage: EvaluationLanguage
	evaluationScheduledStart: Nullable<string>
	startedAt: Nullable<string>
	endedAt: Nullable<string>

	subjectName: string
	subjectEmail: string
	representativeName: string
	representativePosition: Nullable<string>
	representativeEmail: string
	representativePhone: string
	representativePanelAccess: boolean
	representativeAccessToken: Nullable<string>
	note: Nullable<string>

	createdAt: string
	updatedAt: string
	meta: {
		abilities: EvaluationAbility[]
	}
}

export type Evaluation = // EvaluationViewModel::standard
	SimpleEvaluation
	& {}

export type DetailedEvaluation = // EvaluationViewModel::detailed
	Evaluation
	& {
		organization: Organization
		creatorUser: User
		coordinatorUser: User
		participants: Participant[]
		report: Nullable<EvaluationReport>

		openQuestion1: Nullable<string>
		openQuestion1En: Nullable<string>
		openQuestion2: Nullable<string>
		openQuestion2En: Nullable<string>

		participantCustomNameSubject: Nullable<string>
		participantCustomNameSubjectEn: Nullable<string>
		participantCustomNameSupervisor: Nullable<string>
		participantCustomNameSupervisorEn: Nullable<string>
		participantCustomNameSubordinate: Nullable<string>
		participantCustomNameSubordinateEn: Nullable<string>
		participantCustomNameColleague: Nullable<string>
		participantCustomNameColleagueEn: Nullable<string>
		shouldCountAverageForSupervisors: boolean
	}

export type EvaluationReport = { // EvaluationReportViewModel::standardResource
	id: number
	filePath: string
	evaluationId: number
	createdAt: string
	updatedAt: string
}

export type ExternalEvaluation = {
	id: number
	name: string
	evaluationStatus: EvaluationStatus
	evaluationType: EvaluationType

	organizationId: string
	organization: Organization

	creatorUserId: number
	creatorUser: User

	coordinatorUserId: number
	coordinatorUser: User

	companyName: string
	evaluationLanguage: EvaluationLanguage
	evaluationScheduledStart: Nullable<string>
	startedAt: Nullable<string>
	endedAt: Nullable<string>

	subjectName: string
	subjectEmail: string
	representativeName: string
	representativePosition: Nullable<string>
	representativeEmail: string
	representativePhone: string
	representativePanelAccess: boolean

	participants: Participant[]
	report: Nullable<EvaluationReport>

	createdAt: string
	updatedAt: string
	meta: {
		abilities: EvaluationAbility[]
	}
}

export type FetchExternalEvaluationRequest = {
	evaluationId: number
	email: string
	token: string
}
export type ChangeEvaluationManagerRequestPayload = {
	id: number
	coordinatorUserId: number
}

export type CreateEvaluationPayload = {
	coordinatorUserId: number
	name: Nullable<string>
	evaluationType: EvaluationType
	companyName: string
	evaluationLanguage: EvaluationLanguage
	evaluationScheduledStart: Nullable<string>
	representativePanelAccess: boolean
	openQuestion1: string
	openQuestion1En: string
	openQuestion2: string
	openQuestion2En: string
	subjectName: string
	subjectEmail: string
	representativeName: string
	representativePosition: string
	representativeEmail: string
	representativePhone: string
	note: string

	participantCustomNameSubject: string
	participantCustomNameSubjectEn: string
	participantCustomNameSupervisor: string
	participantCustomNameSupervisorEn: string
	participantCustomNameSubordinate: string
	participantCustomNameSubordinateEn: string
	participantCustomNameColleague: string
	participantCustomNameColleagueEn: string
	shouldCountAverageForSupervisors: boolean
}

export type UpdateEvaluationPayload = {
	id: number
	coordinatorUserId?: number
	name?: Nullable<string>
	evaluationType?: EvaluationType
	companyName?: string
	evaluationLanguage?: EvaluationLanguage
	evaluationScheduledStart?: Nullable<string>
	openQuestion1?: string
	openQuestion1En?: string
	openQuestion2?: string
	openQuestion2En?: string
	subjectName?: string
	subjectEmail?: string
	representativeName?: string
	representativePosition?: string
	representativeEmail?: string
	representativePhone?: string
	note?: string
	participantCustomNameSubject?: string
	participantCustomNameSubjectEn?: string
	participantCustomNameSupervisor?: string
	participantCustomNameSupervisorEn?: string
	participantCustomNameSubordinate?: string
	participantCustomNameSubordinateEn?: string
	participantCustomNameColleague?: string
	participantCustomNameColleagueEn?: string
	shouldCountAverageForSupervisors?: boolean
}
