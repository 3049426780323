import { combineReducers, Reducer } from "redux";
import { StateType } from "typesafe-actions";
import { RouterState } from "redux-first-history";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import messageReducer from "src/app/store/features/message/message.reducer";
import formReducer from "src/app/store/features/form/form.reducer";
import userReducer from "src/app/store/features/user/user.reducer";
import miscReducer from "src/app/store/features/misc/misc.reducer";
import uiReducer from "src/app/store/features/ui/ui.reducer";
import wsReducer from "src/app/store/features/ws/ws.reducer";
import paginationReducer from "src/app/store/features/pagination/pagination.reducer";
import organizationReducer from "src/app/store/features/organization/organization.reducer";
import evaluationReducer from "src/app/store/features/evaluation/evaluation.reducer";
import surveyReducer from "src/app/store/features/survey/survey.reducer";
// Reducers imports - don't remove

export type RootState = StateType<ReturnType<typeof rootReducer>>;

const userPersistConfig = {
	key: "user",
	storage: storage,
	whitelist: [ "loggedUser", "userScope" ],
};

const miscPersistConfig = {
	key: "misc",
	storage: storage,
	whitelist: [ "impersonateUserId" ],
};

const rootReducer = (routerReducer: Reducer<RouterState>) =>
	combineReducers({
		router: routerReducer,
		message: messageReducer,
		form: formReducer,
		misc: persistReducer(miscPersistConfig, miscReducer),
		ui: uiReducer,
		ws: wsReducer,
		user: persistReducer(userPersistConfig, userReducer),
		pagination: paginationReducer,
		organization: organizationReducer,
		evaluation: evaluationReducer,
		survey: surveyReducer,
		// Reducers object - don't remove
	});

export default rootReducer;
