import { createAction, createAsyncAction, createReducer, isActionOf } from "typesafe-actions";
import { Error, FailurePayload, SuccessPayload } from "src/app/types/api/api.types";
import { Action, ActionWithIds, ArrayStateReducer, DataState, ErrorState, FetchPaginatedDataBasicRequest, FetchPaginatedDataBasicRequestFilters, LoadingState, NetworkAsyncAction, PaginatedActions, PaginationStateReducer, StateReducer } from "src/app/types/redux.types";
import moment from "moment";
import { ArrayElement, Nullable } from "src/app/types/util.types";
import { Form, FormActions, FormComparator, FormValidator, HandleBlurFormActionPayload, HandleChangeFormActionPayload, ReducerForm, SetErrorFormActionPayload, SetFormActionPayload, ToggleDisableFormActionPayload } from "src/app/types/ui/form.types";
import { isDifferentPaginationOptions } from "src/app/utils/helpers";
import { isArray, isNull } from "src/app/utils/typeguards";
import { DEFAULT_PAGINATION_PAGE_INDEX, DEFAULT_PAGINATION_PAGE_SIZE } from "src/app/utils/constants/constants";
import { PayloadAction } from "typesafe-actions/dist/type-helpers";

export function createNetworkAction<R, S, F = FailurePayload>(actionName: string) {
	return createAsyncAction(
		`${ actionName }_REQUEST`,
		`${ actionName }_SUCCESS`,
		`${ actionName }_FAILURE`,
	)<R, S, F>();
}

export function createFormActions<T>(actionsPrefix: string): FormActions<T> {
	return {
		setForm: createAction(`FORM__${ actionsPrefix }_SET_FORM`)<SetFormActionPayload<T>>(),
		handleChange: createAction(`FORM__${ actionsPrefix }_HANDLE_CHANGE`)<HandleChangeFormActionPayload<T>>(),
		handleBlur: createAction(`FORM__${ actionsPrefix }_HANDLE_BLUR`)<HandleBlurFormActionPayload<T>>(),
		toggleDisable: createAction(`FORM__${ actionsPrefix }_TOGGLE_DISABLE`)<ToggleDisableFormActionPayload<T>>(),
		setError: createAction(`FORM__${ actionsPrefix }_SET_ERROR`)<SetErrorFormActionPayload<T>>(),
		resetForm: createAction(`FORM__${ actionsPrefix }_RESET_FORM`)<undefined>(),
	};
}

export function createFormReducer<T>(
	form: Form<T>,
	formActions: FormActions<T>,
	validator: FormValidator<T>,
	comparator: FormComparator<T> = {},
) {
	return createReducer({ form, validator, comparator } as ReducerForm<T>)
		.handleAction([ ...Object.values(formActions) ], (state, action) => {
			if (isActionOf(formActions.handleChange, action)) {
				const prop = action.payload.prop;
				if (state.form[ prop ].disabled) return state;

				return {
					...state,
					form: {
						...state.form,
						[ prop ]: {
							...state.form[ prop ],
							touched: true,
							value: action.payload.value,
						},
					},
				};
			} else if (isActionOf(formActions.handleBlur, action)) {
				const prop = action.payload.prop;
				const formItem = state.form[ prop ];
				if (!formItem.disabled && formItem.touched) {
					const error = validator[ prop ](formItem.value as never, formItem.optional, state.form);

					return {
						...state,
						form: {
							...state.form,
							[ prop ]: {
								...state.form[ prop ],
								error: error,
								success: error === null,
							},
						},
					};
				}

				return state;
			} else if (isActionOf(formActions.toggleDisable, action)) {
				const prop = action.payload.prop;
				return {
					...state,
					form: {
						...state.form,
						[ prop ]: {
							...state.form[ prop ],
							disabled: action.payload.isDisabled,
						},
					},
				};
			} else if (isActionOf(formActions.setError, action)) {
				const prop = action.payload.prop;

				return {
					...state,
					form: {
						...state.form,
						[ prop ]: {
							...state.form[ prop ],
							error: action.payload.error,
						},
					},
				};
			} else if (isActionOf(formActions.setForm, action)) {
				return {
					...state,
					form: action.payload.form,
				};
			} else if (isActionOf(formActions.resetForm, action)) {
				return {
					...state,
					form,
				};
			} else {
				return state;
			}
		});
}

export function replaceOrPushObject<T>(stateArray: T[], responseObject: T, compare: (a: T, b: T) => boolean, overwrite: boolean = false): T[] {
	const newState: T[] = [];
	if (stateArray.some(stateObject => compare(stateObject, responseObject))) {
		for (let i = 0 ; i < stateArray.length ; i++) {
			if (compare(stateArray[ i ], responseObject)) {
				if (overwrite) {
					newState.push({
						...stateArray[ i ],
						...responseObject,
					});
				} else {
					newState.push(responseObject);
				}
			} else {
				newState.push(stateArray[ i ]);
			}
		}
		return newState;
	} else {
		return [ ...stateArray, responseObject ];
	}
}

export function deleteObject<T>(stateArray: T[], compare: (a: T) => boolean): T[] {
	const newState: T[] = [];
	for (let i = 0 ; i < stateArray.length ; i++) {
		if (!compare(stateArray[ i ])) {
			newState.push(stateArray[ i ]);
		}
	}
	return newState;
}

export const initialStateReducer: StateReducer<any> = { dataState: DataState.NOT_PRESENT, loadingState: LoadingState.NOT_LOADING, errorState: ErrorState.NOT_PRESENT };

export function handleBasicActions<T>(networkAction: NetworkAsyncAction<T>) {
	return (state: StateReducer<T>, action: Action<T>): StateReducer<T> => {
		if (isActionOf(networkAction.request, action)) {
			return ({
				...state,
				loadingState: LoadingState.LOADING,
			});
		} else if (isActionOf(networkAction.success, action)) {
			return ({
				dataState: DataState.PRESENT,
				loadingState: LoadingState.NOT_LOADING,
				errorState: ErrorState.NOT_PRESENT,
				fetchedAt: moment(),
				data: action.payload.data,
			});
		} else {
			return ({
				...state,
				loadingState: LoadingState.NOT_LOADING,
				errorState: ErrorState.PRESENT,
				errors: action.payload.errors,
			});
		}
	};
}

export function handleBasicActionsForArray<T, R>(networkAction: NetworkAsyncAction<T, R>, getId?: (requestPayload: R) => string | number) {
	const defaultGetter = (a: R) => (a as string | number)!;
	const getter = getId ?? defaultGetter;
	return (state: ArrayStateReducer<T>, action: ActionWithIds<T, R>): ArrayStateReducer<T> => {
		if (isActionOf(networkAction.request, action)) {
			if (state.some(stateItem => getter(action.payload) === stateItem.id)) {
				const actionPayloadId = getter(action.payload);
				return replaceStateReducer(
					state,
					actionPayloadId,
					stateItem => ({
						id: stateItem.id,
						reducer: {
							...stateItem.reducer,
							loadingState: LoadingState.LOADING,
						},
					}),
				);
			} else {
				return [
					...state,
					{
						id: getter(action.payload),
						reducer: {
							...initialStateReducer,
							loadingState: LoadingState.LOADING,
						},
					},
				];
			}
		} else if (isActionOf(networkAction.success, action)) {
			const actionPayloadId = action.payload.id;
			return replaceStateReducer(
				state,
				actionPayloadId,
				stateItem => ({
					id: stateItem.id,
					reducer: {
						dataState: DataState.PRESENT,
						loadingState: LoadingState.NOT_LOADING,
						errorState: ErrorState.NOT_PRESENT,
						fetchedAt: moment(),
						data: action.payload.data,
					},
				}),
			);
		} else {
			const actionPayloadId = action.payload.id;
			return replaceStateReducer(
				state,
				actionPayloadId,
				stateItem => ({
					id: stateItem.id,
					reducer: {
						...stateItem.reducer,
						loadingState: LoadingState.NOT_LOADING,
						errorState: ErrorState.PRESENT,
						errors: action.payload.errors,
					},
				}),
			);
		}
	};
}

export const replaceStateReducer = <T>(state: ArrayStateReducer<T>, id: string | number, getNewElement: (stateItem: ArrayElement<typeof state>) => ArrayElement<typeof state>): ArrayStateReducer<T> => {
	const newState: ArrayStateReducer<T> = [];
	for (let i = 0 ; i < state.length ; i++) {
		const stateItem = state[ i ];
		if (stateItem.id === id) {
			newState.push(getNewElement(stateItem));
		} else {
			newState.push(stateItem);
		}
	}
	return newState;
};

export const deleteStateReducer = <T>(state: ArrayStateReducer<T>, id: string | number): ArrayStateReducer<T> => {
	const newState: ArrayStateReducer<T> = [];
	for (let i = 0 ; i < state.length ; i++) {
		const stateItem = state[ i ];
		if (stateItem.id !== id) {
			newState.push(stateItem);
		}
	}
	return newState;
};

export const mergeTwoStateReducers = <T, R, S>(
	firstReducer: StateReducer<T>,
	secondReducer: StateReducer<R>,
	getMergedData: (firstData: T, secondData: R) => S,
): StateReducer<S> => {
	let stateReducer: StateReducer<S> = initialStateReducer;
	if (firstReducer.loadingState === LoadingState.LOADING || secondReducer.loadingState === LoadingState.LOADING) {
		stateReducer = {
			...stateReducer,
			loadingState: LoadingState.LOADING,
		};
	}

	if (firstReducer.errorState === ErrorState.PRESENT || secondReducer.errorState === ErrorState.PRESENT) {
		stateReducer = {
			...stateReducer,
			errorState: ErrorState.PRESENT,
			errors: _getErrorsFromStateReducers([ firstReducer, secondReducer ]),
		};
	}

	if (firstReducer.dataState === DataState.PRESENT && secondReducer.dataState === DataState.PRESENT) {
		const firstReducerFetchedAt = firstReducer.fetchedAt;
		const secondReducerFetchedAt = secondReducer.fetchedAt;
		stateReducer = {
			...stateReducer,
			dataState: DataState.PRESENT,
			fetchedAt: moment(Math.max(+firstReducerFetchedAt, +secondReducerFetchedAt)),
			data: getMergedData(firstReducer.data, secondReducer.data),
		};
	}

	return stateReducer;
};

export const mergeThreeStateReducers = <T, R, S, U>(
	firstReducer: StateReducer<T>,
	secondReducer: StateReducer<R>,
	thirdReducer: StateReducer<S>,
	getMergedData: (first: T, second: R, third: S) => U,
): StateReducer<U> => {
	let stateReducer: StateReducer<U> = initialStateReducer;
	if (firstReducer.loadingState === LoadingState.LOADING || secondReducer.loadingState === LoadingState.LOADING || thirdReducer.loadingState === LoadingState.LOADING) {
		stateReducer = {
			...stateReducer,
			loadingState: LoadingState.LOADING,
		};
	}

	if (firstReducer.errorState === ErrorState.PRESENT || secondReducer.errorState === ErrorState.PRESENT || thirdReducer.errorState === ErrorState.PRESENT) {
		stateReducer = {
			...stateReducer,
			errorState: ErrorState.PRESENT,
			errors: _getErrorsFromStateReducers([ firstReducer, secondReducer, thirdReducer ]),
		};
	}

	if (firstReducer.dataState === DataState.PRESENT && secondReducer.dataState === DataState.PRESENT && thirdReducer.dataState === DataState.PRESENT) {
		const firstReducerFetchedAt = firstReducer.fetchedAt;
		const secondReducerFetchedAt = secondReducer.fetchedAt;
		const thirdReducerFetchedAt = thirdReducer.fetchedAt;

		stateReducer = {
			...stateReducer,
			dataState: DataState.PRESENT,
			fetchedAt: moment(Math.max(+firstReducerFetchedAt, +secondReducerFetchedAt, +thirdReducerFetchedAt)),
			data: getMergedData(firstReducer.data, secondReducer.data, thirdReducer.data),
		};
	}

	return stateReducer;
};

const _getErrorsFromStateReducers = (reducers: StateReducer<any>[]): Error[] => {
	const errors: Error[] = [];
	for (let i = 0 ; i < reducers.length ; i++) {
		const reducer = reducers[ i ];
		if (reducer.errorState === ErrorState.PRESENT) {
			errors.push(...reducer.errors);
		}
	}

	return errors;
};

// Pagination
export const initialPaginatedStateReducer: PaginationStateReducer<any, any, any> = {
	pages: [],
	meta: {
		totalCount: 0,
		actualPageIndex: DEFAULT_PAGINATION_PAGE_INDEX,
		actualPageSize: DEFAULT_PAGINATION_PAGE_SIZE,
		actualSearch: null,
		actualSort: null,
		actualFilters: {},
	},
};

export function handleDeleteItemFromPagination<R extends string | number, T extends { id: R }[], S extends Nullable<string>, F extends FetchPaginatedDataBasicRequestFilters>() {
	return (state: PaginationStateReducer<T, S, F>, action: PayloadAction<`${ string }_SUCCESS`, SuccessPayload<{ id: R }>>) => {
		const pageWithDeletedCase = state.pages.find(page => page.data.dataState === DataState.PRESENT && page.data.data.map(item => item.id).includes(action.payload.data.id));

		if (isNull(pageWithDeletedCase)) return state;

		return {
			...state,
			pages: state.pages.filter(page => page.pageIndex <= pageWithDeletedCase.pageIndex),
		};
	};
}

export function handleBasicActionsForPagination<T, R extends FetchPaginatedDataBasicRequest>(networkAction: NetworkAsyncAction<T, R>) {
	return (state: PaginationStateReducer<T, R["sort"], R["filters"]>, action: PaginatedActions<T, R>): PaginationStateReducer<T, R["sort"], R["filters"]> => {
		if (isActionOf(networkAction.request, action)) {
			const newPageSize = action.payload.pageSize;
			const newPageIndex = action.payload.pageIndex;
			const newSearch = action.payload.search;
			const newSort = action.payload.sort;
			const newFilters = action.payload.filters;
			const isBoundaryPage = action.payload.isBoundaryPage;

			if (
				isDifferentPaginationOptions(
					{ actualPageSize: state.meta.actualPageSize, newPageSize },
					{ actualSearch: state.meta.actualSearch, newSearch },
					{ actualSort: state.meta.actualSort, newSort },
					{ actualFilters: state.meta.actualFilters, newFilters },
				)
			) { // Resetting state of table because there is new sort, filter or search value
				const firstPage = state.pages.find(page => page.pageIndex === 0);
				return {
					pages: [
						{
							pageIndex: 0,
							data: {
								...(firstPage?.data ?? initialStateReducer),
								loadingState: LoadingState.LOADING,
							},
						},
					],
					meta: {
						totalCount: 0,
						actualPageIndex: 0,
						actualPageSize: newPageSize,
						actualSearch: newSearch,
						actualSort: newSort,
						actualFilters: newFilters,
					},
				};
			} else if (!state.pages.some(page => newPageIndex === page.pageIndex)) { // Page with pageIndex doesn't exist
				return {
					...state,
					pages: [
						...state.pages,
						{
							pageIndex: newPageIndex,
							data: {
								...initialStateReducer,
								loadingState: LoadingState.LOADING,
							},
						},
					],
					meta: {
						...state.meta,
						actualPageIndex: isBoundaryPage ? state.meta.actualPageIndex : newPageIndex,
					},
				};
			} else { // Page with pageIndex exist
				return {
					...state,
					meta: {
						totalCount: state.meta.totalCount,
						actualPageIndex: newPageIndex,
						actualPageSize: newPageSize,
						actualSearch: newSearch,
						actualSort: newSort,
						actualFilters: newFilters,
					},
				};
			}
		} else if (isActionOf(networkAction.success, action)) { // Replace existing page object in ArrayStateReducer
			if (
				isDifferentPaginationOptions(
					{ actualPageSize: state.meta.actualPageSize, newPageSize: action.payload.id.pageSize },
					{ actualSearch: state.meta.actualSearch, newSearch: action.payload.id.search },
					{ actualSort: state.meta.actualSort, newSort: action.payload.id.sort },
					{ actualFilters: state.meta.actualFilters, newFilters: action.payload.id.filters },
				)
			) {
				return state;
			}
			return {
				pages: replaceOrPushObject(
					state.pages,
					{
						pageIndex: action.payload.meta?.pageIndex ?? DEFAULT_PAGINATION_PAGE_INDEX,
						data: {
							dataState: DataState.PRESENT,
							loadingState: LoadingState.NOT_LOADING,
							errorState: ErrorState.NOT_PRESENT,
							data: action.payload.data,
							fetchedAt: moment(),
						},
					},
					(a, b) => a.pageIndex === b.pageIndex,
				),
				meta: {
					...state.meta,
					totalCount: action.payload.meta?.totalCount ?? (isArray(action.payload.data) ? action.payload.data.length : 0),
				},
			};
		} else if (isActionOf(networkAction.failure, action)) {
			return {
				...state,
				pages: state.pages.map(page => {
					if (page.pageIndex !== action.payload.id) return page;

					return {
						...page,
						data: {
							...page.data,
							loadingState: LoadingState.NOT_LOADING,
							errorState: ErrorState.PRESENT,
							errors: action.payload.errors,
						},
					};
				}),
			};
		} else {
			return state;
		}
	};
}
