/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { filter, map } from "rxjs/operators";
import { apiAsync } from "src/app/store/features/api/api.actions";
import { isActionOf } from "typesafe-actions";
import { RootEpic } from "src/app/store/root.epic";
import { FailurePayload, SuccessPayload } from "src/app/types/api/api.types";
import { DetailedOrganization } from "src/app/types/api/organization.types";
import { fetchOrganizationsAsync, fetchOrganizationByIdAsync, createOrganizationAsync, updateOrganizationAsync, deleteOrganizationByIdAsync } from "src/app/store/features/organization/organization.actions";

export const fetchOrganizationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchOrganizationsAsync.request)),
		map(() =>
			apiAsync.request({
				url: `/organizations`,
				method: "GET",
				withScope: true,
				onSuccess: fetchOrganizationsAsync.success,
				onFailure: fetchOrganizationsAsync.failure,
			}),
		),
	);

export const fetchOrganizationByIdEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchOrganizationByIdAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/organizations/${ action.payload }`,
				method: "GET",
				withScope: true,
				onSuccess: (payload: SuccessPayload<DetailedOrganization>) => fetchOrganizationByIdAsync.success({ ...payload, id: action.payload }),
				onFailure: (payload: FailurePayload) => fetchOrganizationByIdAsync.failure({ ...payload, id: action.payload }),
			}),
		),
	);

export const createOrganizationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(createOrganizationAsync.request)),
		map(action =>
			apiAsync.request({
				url: "/organizations",
				method: "POST",
				data: action.payload,
				withScope: true,
				onSuccess: createOrganizationAsync.success,
				onFailure: createOrganizationAsync.failure,
			}),
		),
	);

export const updateOrganizationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(updateOrganizationAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/organizations/${ action.payload.id }`,
				method: "PUT",
				data: action.payload,
				withScope: true,
				onSuccess: updateOrganizationAsync.success,
				onFailure: updateOrganizationAsync.failure,
			}),
		),
	);

export const deleteOrganizationByIdEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(deleteOrganizationByIdAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/organizations/${ action.payload }`,
				method: "DELETE",
				withScope: true,
				onSuccess: deleteOrganizationByIdAsync.success,
				onFailure: deleteOrganizationByIdAsync.failure,
			}),
		),
	);
