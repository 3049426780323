/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import { ArrayStateReducer, DataState, ErrorState, LoadingState, StateReducer } from "src/app/types/redux.types";
import { deleteObject, handleBasicActions, handleBasicActionsForArray, initialStateReducer, replaceOrPushObject, replaceStateReducer } from "src/app/utils/redux";
import moment from "moment";
import { DetailedEvaluation, Evaluation, ExternalEvaluation } from "src/app/types/api/evaluation.types";
import { deleteEvaluationByIdAsync, fetchEvaluationByIdAsync, fetchEvaluationsAsync, fetchExternalEvaluationByIdAsync, finishEvaluationAsync, generateEvaluationReportAsync, publishEvaluationReportAsync, shareEvaluationToRepresentativeAsync, startEvaluationAsync, undoShareEvaluationToRepresentativeAsync, updateEvaluationAsync } from "src/app/store/features/evaluation/evaluation.actions";
import { forgetSession, logoutAsync } from "src/app/store/features/user/user.actions";
import { createExternalParticipantAsync, createParticipantAsync, deleteExternalParticipantByIdAsync, deleteParticipantByIdAsync, externalImportParticipantsAsync, externalSendReminderToParticipantsAsync, importParticipantsAsync, sendReminderToParticipantsAsync, updateExternalParticipantAsync, updateParticipantAsync } from "src/app/store/features/participant/participant.actions";

const reducer = combineReducers({
		evaluations: createReducer(initialStateReducer as StateReducer<Evaluation[]>)
			.handleAction([ forgetSession, logoutAsync.success ], () => initialStateReducer)
			.handleAction([ fetchEvaluationsAsync.request, fetchEvaluationsAsync.success, fetchEvaluationsAsync.failure ], handleBasicActions(fetchEvaluationsAsync))
			.handleAction([ updateEvaluationAsync.success ], (state, action) =>
				state.dataState === DataState.PRESENT ?
					{
						...state,
						data: state.data.map(evaluation => {
							if (evaluation.id !== action.payload.data.id) return evaluation;

							return action.payload.data;
						}),
					}
					:
					state,
			)
			.handleAction([ deleteEvaluationByIdAsync.success ], (state, action) => {
				if (state.dataState === DataState.PRESENT) {
					return {
						...state,
						data: deleteObject(state.data, (a) => a.id === action.payload.data.id),
					};
				} else {
					return state;
				}
			}),
		singleEvaluation: createReducer([] as ArrayStateReducer<DetailedEvaluation>)
			.handleAction([ forgetSession, logoutAsync.success ], () => [])
			.handleAction([ fetchEvaluationByIdAsync.request, fetchEvaluationByIdAsync.success, fetchEvaluationByIdAsync.failure ], handleBasicActionsForArray(fetchEvaluationByIdAsync))
			.handleAction([
					updateEvaluationAsync.success,
					startEvaluationAsync.success,
					finishEvaluationAsync.success,
					generateEvaluationReportAsync.success,
					publishEvaluationReportAsync.success,
					shareEvaluationToRepresentativeAsync.success,
					undoShareEvaluationToRepresentativeAsync.success,
					sendReminderToParticipantsAsync.success,
					importParticipantsAsync.success,
					externalSendReminderToParticipantsAsync.success,
				],
				(state, action) =>
					replaceStateReducer(
						state,
						action.payload.data.id,
						stateItem => ({
							id: stateItem.id,
							reducer: {
								dataState: DataState.PRESENT,
								loadingState: LoadingState.NOT_LOADING,
								errorState: ErrorState.NOT_PRESENT,
								fetchedAt: moment(),
								data: action.payload.data,
							},
						}),
					))
			.handleAction([ createParticipantAsync.success ], (state, action) =>
				replaceStateReducer(
					state,
					action.payload.data.evaluationId,
					stateItem => {
						if (stateItem.reducer.dataState !== DataState.PRESENT) return stateItem;

						return {
							id: stateItem.id,
							reducer: {
								...stateItem.reducer,
								data: {
									...stateItem.reducer.data,
									participants: [
										...stateItem.reducer.data.participants,
										action.payload.data,
									],
								},
							},
						};
					},
				),
			)
			.handleAction([ updateParticipantAsync.success ], (state, action) =>
				replaceStateReducer(
					state,
					action.payload.data.evaluationId,
					stateItem => {
						if (stateItem.reducer.dataState !== DataState.PRESENT) return stateItem;

						return {
							id: stateItem.id,
							reducer: {
								...stateItem.reducer,
								data: {
									...stateItem.reducer.data,
									participants: replaceOrPushObject(stateItem.reducer.data.participants, action.payload.data, (a, b) => a.id === b.id),
								},
							},
						};
					},
				),
			)
			.handleAction([ deleteParticipantByIdAsync.success ], (state, action) => replaceStateReducer(
					state,
					action.payload.id,
					stateItem => {
						if (stateItem.reducer.dataState !== DataState.PRESENT) return stateItem;

						return {
							id: stateItem.id,
							reducer: {
								...stateItem.reducer,
								data: {
									...stateItem.reducer.data,
									participants: stateItem.reducer.data.participants.filter(participant => participant.id !== action.payload.data.id),
								},
							},
						};
					},
				),
			),
		externalEvaluations: createReducer([] as ArrayStateReducer<ExternalEvaluation>)
			.handleAction([ forgetSession, logoutAsync.success ], () => [])
			.handleAction([ fetchExternalEvaluationByIdAsync.request, fetchExternalEvaluationByIdAsync.success, fetchExternalEvaluationByIdAsync.failure ], handleBasicActionsForArray(fetchExternalEvaluationByIdAsync, request => request.evaluationId))
			.handleAction([ createExternalParticipantAsync.success ], (state, action) =>
				replaceStateReducer(
					state,
					action.payload.data.evaluationId,
					stateItem => {
						if (stateItem.reducer.dataState !== DataState.PRESENT) return stateItem;

						return {
							id: stateItem.id,
							reducer: {
								...stateItem.reducer,
								data: {
									...stateItem.reducer.data,
									participants: [
										...stateItem.reducer.data.participants,
										action.payload.data,
									],
								},
							},
						};
					},
				),
			)
			.handleAction([ updateExternalParticipantAsync.success ], (state, action) =>
				replaceStateReducer(
					state,
					action.payload.data.evaluationId,
					stateItem => {
						if (stateItem.reducer.dataState !== DataState.PRESENT) return stateItem;

						return {
							id: stateItem.id,
							reducer: {
								...stateItem.reducer,
								data: {
									...stateItem.reducer.data,
									participants: replaceOrPushObject(stateItem.reducer.data.participants, action.payload.data, (a, b) => a.id === b.id),
								},
							},
						};
					},
				),
			)
			.handleAction([ deleteExternalParticipantByIdAsync.success ], (state, action) => replaceStateReducer(
					state,
					action.payload.id,
					stateItem => {
						if (stateItem.reducer.dataState !== DataState.PRESENT) return stateItem;

						return {
							id: stateItem.id,
							reducer: {
								...stateItem.reducer,
								data: {
									...stateItem.reducer.data,
									participants: stateItem.reducer.data.participants.filter(participant => participant.id !== action.payload.data.id),
								},
							},
						};
					},
				),
			)
			.handleAction([
					externalImportParticipantsAsync.success,
					externalSendReminderToParticipantsAsync.success,
				], (state, action) =>
					replaceStateReducer(
						state,
						action.payload.data.id,
						stateItem => {
							if (stateItem.reducer.dataState !== DataState.PRESENT) return stateItem;

							return {
								id: stateItem.id,
								reducer: {
									...stateItem.reducer,
									data: action.payload.data,
								},
							};
						},
					),
			),
	})
;

export default reducer;
