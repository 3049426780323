import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import { handleBasicActionsForPagination, handleDeleteItemFromPagination, initialPaginatedStateReducer } from "src/app/utils/redux";
import { PaginationStateReducer } from "src/app/types/redux.types";
import { FetchTestPaginationRequestPayload } from "src/app/types/api/pagination.types";
import { forgetSession } from "src/app/store/features/user/user.actions";
import { deleteTestPaginationAsync, fetchTestPaginationAsync, uiChangeTestPaginationActualPageIndex, uiChangeTestPaginationMeta, uiResetTestPagination } from "src/app/store/features/pagination/pagination.actions";

const reducer = combineReducers({
	paginatedTest: createReducer(initialPaginatedStateReducer as PaginationStateReducer<any[], FetchTestPaginationRequestPayload["sort"], FetchTestPaginationRequestPayload["filters"]>)
		.handleAction([ forgetSession, uiResetTestPagination ], () => initialPaginatedStateReducer)
		.handleAction([ fetchTestPaginationAsync.request, fetchTestPaginationAsync.success, fetchTestPaginationAsync.failure ], handleBasicActionsForPagination(fetchTestPaginationAsync))
		.handleAction([ deleteTestPaginationAsync.success ], handleDeleteItemFromPagination())
		.handleAction([ uiChangeTestPaginationMeta ], (state, action) => ({
			...state,
			meta: {
				...state.meta,
				...action.payload,
			},
		}))
		.handleAction([ uiChangeTestPaginationActualPageIndex ], (state, action) => ({
			...state,
			meta: {
				...state.meta,
				actualPageIndex: action.payload,
			},
		})),
});

export default reducer;
