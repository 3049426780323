import { Nullable } from "src/app/types/util.types";
import moment from "moment";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { createSelector } from "reselect";
import { RootState } from "src/app/store/root.reducer";
import { DataState } from "src/app/types/redux.types";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";

/* Organizations */
const organizationsSelector = (state: RootState) => state.organization.organizations;
const singleOrganizationsSelector = (state: RootState) => state.organization.singleOrganization;

/* Evaluations */
const evaluationsSelector = (state: RootState) => state.evaluation.evaluations;
const singleEvaluationsSelector = (state: RootState) => state.evaluation.singleEvaluation;

/* Survey */
const surveysSelector = (state: RootState) => state.survey.surveys;
// Breadcrumbs selector import plop - don't remove

export const isAppLoading = createSelector(
	[
		(state: RootState) => didLoadingRecordExist(state, { loadableType: LoadableType.FETCH_USER_SCOPES }),
		(state: RootState) => didLoadingRecordExist(state, { loadableType: LoadableType.AUTH_ME }),
	],
	(...loadingRecords) => loadingRecords.some(loadingRecord => loadingRecord),
);

/* Users */
const loggedUserSelector = (state: RootState) => state.user.loggedUser;
const usersSelector = (state: RootState) => state.user.users;
const singleUsersSelector = (state: RootState) => state.user.singleUsers;

export const getUserFullName = createSelector(
	[
		loggedUserSelector,
		usersSelector,
		singleUsersSelector,
		(_, userId: string) => userId, // Don't cast there to number, because string => NaN
	],
	(loggedUser, users, singleUsers, userId) => {
		const models: ModelInfo[] = [];
		if (loggedUser.dataState === DataState.PRESENT && loggedUser.data.id === +userId) {
			models.push({
				name: `${ loggedUser.data.name } ${ loggedUser.data.surname }`,
				fetchedAt: loggedUser.fetchedAt,
			});
		}

		if (users.dataState === DataState.PRESENT && users.data.some(user => user.id === +userId)) {
			const user = users.data.find(user => user.id === +userId);
			if (isNotNull(user)) {
				models.push({
					name: `${ user.name } ${ user.surname }`,
					fetchedAt: users.fetchedAt,
				});
			}
		}

		if (singleUsers.some(user => (user.id === +userId && user.reducer.dataState === DataState.PRESENT))) {
			const user = singleUsers.find(user => user.id === +userId);
			if (isNotNull(user) && user.reducer.dataState === DataState.PRESENT) {
				models.push({
					name: `${ user.reducer.data.name } ${ user.reducer.data.surname }`,
					fetchedAt: user.reducer.fetchedAt,
				});
			}
		}

		return getFresherModel(models, userId);
	},
);

/* Organization */

export const getOrganizationName = createSelector(
	[
		organizationsSelector,
		singleOrganizationsSelector,
		(_, organizationId: string) => organizationId, // Don't cast there to number, because string => NaN
	],
	(organizations, singleOrganizations, organizationId) => {
		const models: ModelInfo[] = [];
		if (organizations.dataState === DataState.PRESENT && organizations.data.some(organization => organization.id === organizationId)) {
			const organization = organizations.data.find(organization => organization.id === organizationId);
			if (isNotNull(organization)) {
				models.push({
					name: organization.name,
					fetchedAt: organizations.fetchedAt,
				});
			}
		}

		if (singleOrganizations.some(organization => (organization.id === organizationId && organization.reducer.dataState === DataState.PRESENT))) {
			const organization = singleOrganizations.find(organization => organization.id === organizationId);
			if (isNotNull(organization) && organization.reducer.dataState === DataState.PRESENT) {
				models.push({
					name: organization.reducer.data.name,
					fetchedAt: organization.reducer.fetchedAt,
				});
			}
		}

		return getFresherModel(models, organizationId);
	},
);

/* Evaluation */

export const getEvaluationName = createSelector(
	[
		evaluationsSelector,
		singleEvaluationsSelector,
		surveysSelector,
		(_, evaluationId: string) => evaluationId, // Don't cast there to number, because string => NaN
	],
	(evaluations, singleEvaluations, surveys, evaluationId) => {
		const models: ModelInfo[] = [];
		if (evaluations.dataState === DataState.PRESENT && evaluations.data.some(evaluation => evaluation.id === +evaluationId)) {
			const evaluation = evaluations.data.find(evaluation => evaluation.id === +evaluationId);
			if (isNotNull(evaluation)) {
				models.push({
					name: evaluation.name,
					fetchedAt: evaluations.fetchedAt,
				});
			}
		}

		if (singleEvaluations.some(evaluation => (evaluation.id === +evaluationId && evaluation.reducer.dataState === DataState.PRESENT))) {
			const evaluation = singleEvaluations.find(evaluation => evaluation.id === +evaluationId);
			if (isNotNull(evaluation) && evaluation.reducer.dataState === DataState.PRESENT) {
				models.push({
					name: evaluation.reducer.data.name,
					fetchedAt: evaluation.reducer.fetchedAt,
				});
			}
		}

		if (surveys.some(survey => survey.reducer.dataState === DataState.PRESENT && survey.reducer.data.evaluation?.id === +evaluationId)) {
			const survey = surveys.find(survey => survey.reducer.dataState === DataState.PRESENT && survey.reducer.data.evaluation?.id === +evaluationId);
			if (isNotNull(survey) && survey.reducer.dataState === DataState.PRESENT) {
				models.push({
					name: survey.reducer.data.evaluation.name,
					fetchedAt: survey.reducer.fetchedAt,
				});
			}
		}

		return getFresherModel(models, evaluationId);
	},
);

export const getSurveyParticipantName = createSelector(
	[
		singleEvaluationsSelector,
		surveysSelector,
		(_, surveyId: string) => surveyId,
	],
	(singleEvaluations, surveys, surveyId) => {
		const models: ModelInfo[] = [];
		singleEvaluations.forEach(evaluation => {
			if (evaluation.reducer.dataState !== DataState.PRESENT) return;

			const participant = evaluation.reducer.data.participants.find(participant => participant.survey?.id === +surveyId);
			if (isNull(participant)) return;
			models.push({
				name: `Ankieta ${ participant.name }`,
				fetchedAt: evaluation.reducer.fetchedAt,
			});
		});

		if (surveys.some(survey => survey.reducer.dataState === DataState.PRESENT && survey.id === +surveyId)) {
			const survey = surveys.find(survey => survey.reducer.dataState === DataState.PRESENT && survey.id === +surveyId);
			if (isNotNull(survey) && survey.reducer.dataState === DataState.PRESENT) {
				models.push({
					name: `Ankieta ${ survey.reducer.data.participant.name }`,
					fetchedAt: survey.reducer.fetchedAt,
				});
			}
		}

		return getFresherModel(models, surveyId);
	},
);
// Breadcrumbs selector plop - don't remove

type ModelInfo = {
	name: string
	fetchedAt: moment.Moment
}

const getFresherModel = (models: ModelInfo[], deputy: Nullable<string | number>): string => {
	const sortedModels = models.sort((a, b) => b.fetchedAt.diff(a.fetchedAt));

	const [ firstModel ] = sortedModels;
	return firstModel?.name ?? (deputy ?? "").toString();
};
