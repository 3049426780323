/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { createAction } from "typesafe-actions";
import { CreateExternalParticipantPayload, CreateParticipantPayload, DeleteExternalParticipantPayload, DeleteParticipantPayload, ExternalImportParticipantsPayload, ExternalSendReminderToParticipantsPayload, ImportParticipantsPayload, SendReminderToParticipantsPayload, UpdateExternalParticipantPayload, UpdateParticipantPayload } from "src/app/types/api/participant.types";

export const uiCreateParticipant = createAction("UI_PARTICIPANT__CREATE_PARTICIPANT")<CreateParticipantPayload>();
export const uiUpdateParticipant = createAction("UI_PARTICIPANT__UPDATE_PARTICIPANT")<UpdateParticipantPayload>();
export const uiDeleteParticipant = createAction("UI_PARTICIPANT__DELETE_PARTICIPANT")<DeleteParticipantPayload>();

export const uiSendReminderToSubject = createAction("UI_PARTICIPANT__SEND_REMINDER_TO_SUBJECT")<SendReminderToParticipantsPayload>();
export const uiSendReminderToParticipants = createAction("UI_PARTICIPANT__SEND_REMINDER_TO_PARTICIPANTS")<SendReminderToParticipantsPayload>();
export const uiImportParticipants = createAction("UI_PARTICIPANTS__IMPORT_PARTICIPANTS")<ImportParticipantsPayload>();

export const uiCreateExternalParticipant = createAction("UI_PARTICIPANT__CREATE_EXTERNAL_PARTICIPANT")<CreateExternalParticipantPayload>();
export const uiUpdateExternalParticipant = createAction("UI_PARTICIPANT__UPDATE_EXTERNAL_PARTICIPANT")<UpdateExternalParticipantPayload>();
export const uiDeleteExternalParticipant = createAction("UI_PARTICIPANT__DELETE_EXTERNAL_PARTICIPANT")<DeleteExternalParticipantPayload>();

export const uiExternalSendReminderToSubject = createAction("UI_PARTICIPANT__EXTERNAL_SEND_REMINDER_TO_SUBJECT")<ExternalSendReminderToParticipantsPayload>();
export const uiExternalSendReminderToParticipants = createAction("UI_PARTICIPANT__EXTERNAL_SEND_REMINDER_TO_PARTICIPANTS")<ExternalSendReminderToParticipantsPayload>();
export const uiExternalImportParticipants = createAction("UI_PARTICIPANTS__EXTERNAL_IMPORT_PARTICIPANTS")<ExternalImportParticipantsPayload>();

