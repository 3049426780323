import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import { MUISortOptions } from "mui-datatables";
import { FetchTestPaginationRequestPayload } from "src/app/types/api/pagination.types";
import { isNull } from "src/app/utils/typeguards";
import PaginationContainer from "src/app/containers/Pagination/Pagination.container";
import PaginationStrategy from "src/app/hoc/caching/PaginationStrategy.hoc";
import { uiChangeTestPaginationMeta, uiFetchTestPagination, uiResetTestPagination } from "src/app/store/features/pagination/pagination.actions";
import { APP_HEADER_HEIGHT, CONTENT_PADDING } from "src/app/components/Utils/Table.component";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

const sortMapper = (sort: Partial<MUISortOptions>): FetchTestPaginationRequestPayload["sort"] => {
	if (isNull(sort?.name) || isNull(sort?.direction)) return null;

	if (sort.direction !== "asc" && sort.direction !== "desc") return null;

	const directionPrefix = sort.direction === "desc" ? "-" : "";

	switch (sort.name) {
		case "Title of test".toUpperCase():
			return `${ directionPrefix }name`;
	}

	return null;
};

const OWNER_USER_COLUMN_INDEX = 0;

const filterMapper = (filters: string[][]): FetchTestPaginationRequestPayload["filters"] => ({
	user_id: (filters[ OWNER_USER_COLUMN_INDEX ] ?? []),
});

function PaginationView(props: Props) {

	const {
		tests,
		fetchPaginatedTest,
		changePaginationMeta,
		resetPagination,
		bodyHeight,
	} = props;

	return (
		<PaginationStrategy
			request={ fetchPaginatedTest }
			onChangePaginationMeta={ changePaginationMeta }
			sortMapper={ sortMapper }
			filterMapper={ filterMapper }
			state={ tests }
			cellHeight={ 53 }
			wrapperHeight={ bodyHeight - APP_HEADER_HEIGHT - CONTENT_PADDING }
			unmount={ resetPagination }
			usePersist
		>
			{
				(tests, tableConfiguration, isLoading) =>
					<PaginationContainer
						tests={ tests }
						tableConfiguration={ tableConfiguration }
						isLoading={ isLoading }
					/>
			}
		</PaginationStrategy>
	);
}

const mapStateToProps = (state: RootState) => ({
	tests: state.pagination.paginatedTest,
	bodyHeight: state.ui.layout.bodySize.height,
});

const mapDispatchToProps = {
	fetchPaginatedTest: uiFetchTestPagination,
	changePaginationMeta: uiChangeTestPaginationMeta,
	resetPagination: uiResetTestPagination,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaginationView);
