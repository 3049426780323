import { isString } from "src/app/utils/typeguards";

export const parseFile = (file: File): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => isString(reader.result) && resolve(reader.result);
		reader.onerror = error => reject(error);
	});

export type ParseFileWithIdPayload = {
	result: string
	id: string
}

export const parseFileWithId = (file: File, id: string): Promise<ParseFileWithIdPayload> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => isString(reader.result) ? resolve({ result: reader.result, id }) : reject("Unexpected result from output stream");
		reader.onerror = error => reject(error);
	});
