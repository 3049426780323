import { createAction } from "typesafe-actions";
import { createNetworkAction } from "src/app/utils/redux";
import { FailurePayloadWithId, SuccessPayload, SuccessPayloadWithId } from "src/app/types/api/api.types";
import { FetchTestPaginationRequestPayload } from "src/app/types/api/pagination.types";
import { FetchPaginatedDataBasicRequest, PaginationStateReducerMeta } from "src/app/types/redux.types";

// Actions
export const fetchTestPaginationAsync = createNetworkAction<FetchTestPaginationRequestPayload, SuccessPayloadWithId<any[], FetchPaginatedDataBasicRequest>, FailurePayloadWithId>("TEST__FETCH_PAGINATION_TEST");
export const deleteTestPaginationAsync = createNetworkAction<number, SuccessPayload<{ id: number }>>("TEST__DELETE_PAGINATION_TEST");

// UI Actions
export const uiFetchTestPagination = createAction("UI_TEST__FETCH_TEST_PAGINATION")<FetchTestPaginationRequestPayload>();
export const uiChangeTestPaginationActualPageIndex = createAction("UI_TEST__CHANGE_TEST_PAGINATION_ACTUAL_INDEX")<number>();
export const uiChangeTestPaginationMeta = createAction("UI_TEST__CHANGE_TEST_PAGINATION_META")<Partial<PaginationStateReducerMeta<FetchTestPaginationRequestPayload["sort"], FetchTestPaginationRequestPayload["filters"]>>>();
export const uiResetTestPagination = createAction("UI_TEST__RESET_TEST_PAGINATION")<undefined>();
