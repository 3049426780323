import { RootEpic } from "src/app/store/root.epic";
import { isActionOf } from "typesafe-actions";
import { applicationStart, empty, fetchBackendVersionAsync, scopedPush, sendErrorAsync } from "src/app/store/features/misc/misc.actions";
import { filter, map, mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import { uiAuthMe } from "src/app/store/features/ui/user/ui.user.actions";
import { DataState } from "src/app/types/redux.types";
import { apiAsync } from "src/app/store/features/api/api.actions";
import { removeLoadingRecord } from "src/app/store/features/ui/loading/ui.loading.actions";
import { authLoadingRecord } from "src/app/store/features/ui/loading/ui.loading.reducer";
import { isNotNull, isNull, isString } from "src/app/utils/typeguards";
import { push } from "redux-first-history";
import { To } from "history";

export const applicationStartEpic: RootEpic = (action$, state$) =>
	action$.pipe(
		filter(isActionOf(applicationStart)),
		mergeMap(_ => {
			if (state$.value.user.loggedUser.dataState === DataState.PRESENT) {
				return of(uiAuthMe());
			} else {
				return of(removeLoadingRecord(authLoadingRecord));
			}
		}),
	);

export const fetchBackendVersionEpic: RootEpic = action$ =>
	action$.pipe(
		filter(action => isActionOf(applicationStart, action) || isActionOf(fetchBackendVersionAsync.request, action)),
		map(_ =>
			apiAsync.request({
				url: "/info/version",
				method: "GET",
				withScope: false,
				onSuccess: fetchBackendVersionAsync.success,
				onFailure: fetchBackendVersionAsync.failure,
			}),
		),
	);

export const sendErrorEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(sendErrorAsync.request)),
		map(action =>
			apiAsync.request({
				url: "/error/frontend-log",
				method: "POST",
				data: action.payload,
				withScope: false,
				onSuccess: sendErrorAsync.success,
				onFailure: sendErrorAsync.failure,
			}),
		),
	);

export const scopedPushEpic: RootEpic = (action$, state$) =>
	action$.pipe(
		filter(isActionOf(scopedPush)),
		map(action => {
			if (isNull(state$.value.user.userScope)) return empty();
			const urlPrefix = `/organization/${ state$.value.user.userScope.organization.id }`;
			return push(_getPushPayload(urlPrefix, action.payload));
		}),
	);

const _getPushPayload = (urlPrefix: string, to: To): To => {
	if (isString(to)) {
		return `${ urlPrefix }${ to }`;
	} else {
		return {
			...to,
			pathname: isNotNull(to.pathname) ? `${ urlPrefix }${ to.pathname }` : undefined,
		};
	}
};
