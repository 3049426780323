/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2023.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

type Props = {
	label: ReactNode
	value: ReactNode
};

function LabelValue(props: Props) {

	const {
		label,
		value,
	} = props;

	return (
		<div>
			<div className="text-xs sm:text-sm font-bold">
				{ label }
			</div>
			<div className="break-words text-gray-500">
				{ value }
			</div>
		</div>
	);
}

export default (LabelValue);
