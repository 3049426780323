import { useEffect } from "react";
import { RootState } from "src/app/store/root.reducer";
import { fetchUsersAsync } from "src/app/store/features/user/user.actions";
import { Nullable, SelectOption } from "src/app/types/util.types";
import { SimpleUser } from "src/app/types/api/user.types";
import { FormItem } from "src/app/types/ui/form.types";
import Select, { components, GroupBase, MenuPlacement, OptionProps, ValueContainerProps } from "react-select";
import classNames from "classnames";
import { isArray, isEmptyString, isNotNull } from "src/app/utils/typeguards";
import { PublicBaseSelectProps } from "react-select/base";
import { DataState, ErrorState, LoadingState } from "src/app/types/redux.types";
import { connect } from "react-redux";
import { GridLoader } from "react-spinners";
import { Label, LabelProps } from "flowbite-react";
import Avatar from "src/app/components/Utils/Avatar.component";
import { getSelectClassNames, getSelectStyles } from "src/app/utils/ui";

type ComponentProps = {
	className?: string
	label?: string | ReactNode
	labelProps?: LabelProps
	formItem: FormItem<Nullable<SimpleUser>>
	onChange: (user: Nullable<SimpleUser>) => void
	displayErrorMessage?: boolean
	isSearchable?: boolean
	isClearable?: boolean
	portalEl?: HTMLElement
	menuPlacement?: MenuPlacement
	optionsHeight?: number
	inputHeight?: number
};

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps
	& ComponentProps;

const _mapUserToOption = (user: SimpleUser): SelectOption<SimpleUser> => ({
	value: user,
	label: `${ user.name } ${ user.surname }`,
});

function UserSelect(props: Props) {

	const {
		className,
		label,
		labelProps,
		formItem,
		onChange,
		displayErrorMessage = true,
		isSearchable = true,
		isClearable = true,
		portalEl,
		menuPlacement = "auto",
		optionsHeight = 240,
		inputHeight = 42,
		users,
		fetchUsers,
	} = props;

	useEffect(() => {
		if (users.loadingState === LoadingState.NOT_LOADING) {
			fetchUsers();
		}
	}, []);

	const _getSelectOptions = (): SelectOption<SimpleUser>[] => {
		if (users.errorState === ErrorState.PRESENT) return [];

		if (users.dataState === DataState.PRESENT && users.loadingState === LoadingState.NOT_LOADING) {
			return users.data.map(_mapUserToOption);
		}

		return [];
	};

	const _getNoOptionsMessage = () => {
		const loadingResponse = (
			<>
				<GridLoader size={ 4 } color="#fed054"/>
				Loading Users
			</>
		);
		if (users.loadingState === LoadingState.LOADING) {
			return loadingResponse;
		}

		if (users.errorState === ErrorState.PRESENT) return "Loading users error";

		if (users.dataState === DataState.PRESENT) return "No users";

		return loadingResponse;
	};

	const selectProps: Partial<PublicBaseSelectProps<SelectOption<SimpleUser>, false, GroupBase<SelectOption<SimpleUser>>>> = {
		styles: getSelectStyles<SimpleUser, false>(optionsHeight, inputHeight),
		classNames: getSelectClassNames<SimpleUser, false>(formItem, isClearable),
		isClearable: isClearable,
		isSearchable: isSearchable,
		placeholder: "Wybierz opcje...",
		value: isNotNull(formItem.value) ? _mapUserToOption(formItem.value) : null,
		onChange: value => onChange(value?.value ?? null),
		components: {
			Option: OptionComponent,
			ValueContainer: ValueContainerComponent,
		},
		menuPortalTarget: portalEl,
		menuPlacement: menuPlacement,
		isMulti: false,
		options: _getSelectOptions(),
		noOptionsMessage: () => (
			<div className="flex items-center gap-2">
				{ _getNoOptionsMessage() }
			</div>
		),
	};

	return (
		<div className={ classNames(className, "flex flex-col gap-y-0.5") }>
			{
				isNotNull(label) &&
                <Label { ...labelProps }>
					{ label }
                </Label>
			}
			<Select { ...selectProps }/>
			{
				(isNotNull(formItem.error) && displayErrorMessage) &&
                <div className="text-sm text-red-600 dark:text-red-500 font-medium">
					{ formItem.error }
                </div>
			}
		</div>
	);
}

const OptionComponent = (props: OptionProps<SelectOption<SimpleUser>, false>) => {

	const {
		data: {
			value: user,
		},
		isFocused,
	} = props;

	return (
		<components.Option { ...props }>
			<div className="flex items-center gap-2">
				<Avatar
					alt={ `${ user.name }-${ user.surname }-avatar` }
					img={ user.image?.icon }
					placeholderInitials={ `${ user.name[ 0 ] }${ user.surname[ 0 ] }` }
					size="sm"
				/>
				<span className={ classNames({ "dark:text-white": !isFocused }, { "dark:text-black": isFocused }) }>
					{ `${ user.name } ${ user.surname }` }
				</span>
			</div>
		</components.Option>
	);
};

const ValueContainerComponent = (props: ValueContainerProps<SelectOption<SimpleUser>, false>) => {

	const {
		getValue,
		selectProps: {
			inputValue,
		},
	} = props;

	const selectedValues = getValue();

	if (selectedValues.length > 0) {
		const user = selectedValues[ 0 ].value;
		return (
			<components.ValueContainer
				{ ...props }
				className={ classNames(props.className, "flex") }
			>
				{
					isEmptyString(inputValue) &&
                    <div className="flex items-center gap-2">
                        <Avatar
                            alt={ `${ user.name }-${ user.surname }-avatar` }
                            img={ user.image?.icon }
                            placeholderInitials={ `${ user.name[ 0 ] }${ user.surname[ 0 ] }` }
                            size="sm"
                        />
                        <span className="dark:text-white">{ `${ user.name } ${ user.surname }` }</span>
                    </div>
				}

				{ /* children[ 1 ] = Search input */ }
				{
					isArray(props.children)
						?
						props.children[ 1 ]
						:
						props.children
				}
			</components.ValueContainer>
		);
	} else {
		return (
			<components.ValueContainer { ...props }>
				{ props.children }
			</components.ValueContainer>
		);
	}
};

const mapStateToProps = (state: RootState) => ({
	users: state.user.users,
});

const mapDispatchToProps = {
	fetchUsers: fetchUsersAsync.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSelect);
