/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2023.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { FormItem } from "src/app/types/ui/form.types";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import { Nullable } from "src/app/types/util.types";
import { Label, LabelProps, TextInput } from "flowbite-react";
import { isNotNull } from "src/app/utils/typeguards";
import classNames from "classnames";

type Props = {
	className?: string
	datepickerProps?: Partial<ReactDatePickerProps>
	label?: string | ReactNode
	labelProps?: LabelProps
	formItem: FormItem<Nullable<Date>>
	onChange: (date: Date) => void
	onBlur: () => void
};

function DatePicker(props: Props) {

	const {
		className,
		datepickerProps = {},
		label,
		labelProps,
		formItem,
		onChange,
		onBlur,
	} = props;

	return (
		<div
			className={
				classNames(
					"flex flex-col",
					className,
				)
			}
		>
			{
				isNotNull(label) &&
                <Label { ...labelProps }>
					{ label }
                </Label>
			}
			<ReactDatePicker
				className={
					classNames(
						"w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500 rounded-lg p-2.5 text-sm",
						{ "mt-0.5": isNotNull(label) },
						{ "mb-0.5 !text-black shadow-none bg-red-50 dark:!bg-red-100 border-red-500 dark:border-red-400": isNotNull(formItem.error) }
					)
				}
				locale="pl"
				selected={ formItem.value }
				onChange={ onChange }
				showPopperArrow={ false }
				timeInputLabel="Czas:"
				calendarClassName="bg-white dark:bg-gray-700 border-none rounded-lg p-4"
				weekDayClassName={ () => "text-center h-6 leading-6 text-sm font-semibold text-gray-500 dark:text-gray-400" }
				dateFormat="dd/MM/yyyy HH:mm"
				showTimeInput
				disabled={ formItem.disabled }
				customTimeInput={
					<TimeInput
						customDate={ formItem.value }
						onChangeCustom={ (time, date) => {
							const newDate = isNotNull(date) ? new Date(date) : new Date();
							const [ hh, mm ] = time.split(":");
							newDate.setHours(+hh || 0, +mm || 0, 0);
							onChange(newDate);
						} }
					/>
				}
				shouldCloseOnSelect={ false }
				onCalendarClose={ onBlur }
				{ ...datepickerProps }
			/>
			{
				isNotNull(formItem.error) &&
                <div className="text-sm text-red-600 dark:text-red-500 font-medium">
					{ formItem.error }
                </div>
			}
		</div>
	);
}

type TimeInputProps = {
	customDate: Nullable<Date>
	onChangeCustom: (time: string, date: Date) => void
}

function TimeInput(props: TimeInputProps) {

	const {
		customDate,
		onChangeCustom,
	} = props;

	const _getPlaceholderDate = () => {
		const date = new Date();
		date.setHours(0, 0, 0);
		return date;
	};

	/*step=1 if we want to show seconds*/
	return (
		<TextInput
			className="dark:[&_input]:!bg-transparent"
			type="time"
			step={ 60 }
			value={ (customDate ?? _getPlaceholderDate()).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) }
			onChange={ e => onChangeCustom(e.target.value, customDate ?? _getPlaceholderDate()) }
		/>
	);
}

export default (DatePicker);
