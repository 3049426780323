import { RootEpic } from "src/app/store/root.epic";
import { uiFetchExternalSurvey, uiSaveExternalSurvey, uiSaveSurvey, uiSubmitExternalSurvey } from "src/app/store/features/ui/survey/ui.survey.actions";
import { isActionOf } from "typesafe-actions";
import { debounceTime, filter, map, mergeMap, switchMap, take } from "rxjs/operators";
import { concat, merge, of } from "rxjs";
import { fetchExternalSurveyAsync, saveExternalSurveyAsync, saveSurveyAsync, submitExternalSurveyAsync } from "src/app/store/features/survey/survey.actions";
import { empty, scopedPush } from "src/app/store/features/misc/misc.actions";
import { surveyActions } from "src/app/store/features/form/form.actions";
import { mapSurveyToForm } from "src/app/utils/survey";
import { addLoadingRecord, removeLoadingRecord } from "src/app/store/features/ui/loading/ui.loading.actions";
import { LoadableType } from "src/app/types/ui/loading.types";
import { SurveyStep } from "src/app/types/ui/survey.types";
import { ToastType } from "src/app/types/ui/message.types";
import { displayToast } from "src/app/store/features/message/message.actions";

export const uiFetchExternalSurveyEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiFetchExternalSurvey)),
		switchMap(action =>
			concat(
				of(fetchExternalSurveyAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(fetchExternalSurveyAsync.success, action) || isActionOf(fetchExternalSurveyAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(fetchExternalSurveyAsync.success, responseAction)) {
							return of(
								surveyActions.setForm({
									form: mapSurveyToForm(responseAction.payload.data),
								}),
							);
						} else {
							return of(empty());
						}
					}),
				),
			),
		),
	);

export const uiSaveExternalSurveyEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiSaveExternalSurvey)),
		debounceTime(250),
		map(action => saveExternalSurveyAsync.request(action.payload)),
	);

export const uiSubmitExternalSurveyEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiSubmitExternalSurvey)),
		switchMap(action =>
			concat(
				of(addLoadingRecord({ loadableId: action.payload.id, loadableType: LoadableType.SUBMIT_SURVEY })),
				of(submitExternalSurveyAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(submitExternalSurveyAsync.success, action) || isActionOf(submitExternalSurveyAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(submitExternalSurveyAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord({ loadableId: action.payload.id, loadableType: LoadableType.SUBMIT_SURVEY })),
								of(surveyActions.handleChange({
									prop: "step",
									value: SurveyStep.THANKS,
								})),
							);
						} else {
							return of(removeLoadingRecord({ loadableId: action.payload.id, loadableType: LoadableType.SUBMIT_SURVEY }));
						}
					}),
				),
			),
		),
	);

export const uiSubmitSurveyEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiSaveSurvey)),
		switchMap(action =>
			concat(
				of(addLoadingRecord({ loadableType: LoadableType.SAVE_SURVEY, loadableId: action.payload.surveyId })),
				of(saveSurveyAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(saveSurveyAsync.success, action) || isActionOf(saveSurveyAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(saveSurveyAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord({ loadableType: LoadableType.SAVE_SURVEY, loadableId: action.payload.surveyId })),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie zapisano odpowiedzi formularza!" })),
								of(scopedPush(`/evaluations/${ action.payload.evaluationId }`)),
							);
						} else {
							return of(removeLoadingRecord({ loadableType: LoadableType.SAVE_SURVEY, loadableId: action.payload.surveyId }));
						}
					}),
				),
			),
		),
	);
