import { createAction } from "typesafe-actions";
import { ConfirmTwoFactorAuthenticationPayload, CreateUserPayload, FetchUsersRequestPayload, ForgotPasswordPayload, LoginPayload, ResetPasswordPayload, UpdateUserPasswordPayload, UpdateUserPayload, UpdateUserPermissionsPayload, UpdateUserStatusPayload } from "src/app/types/api/user.types";
import { UserScope } from "src/app/types/ui/user.types";

// Auth
export const uiLogin = createAction("UI_USER__LOGIN")<LoginPayload>();
export const uiAuthMe = createAction("UI_USER__AUTH_ME")<undefined>();
export const uiScopeAuthMe = createAction("UI_USER__SCOPE_AUTH_ME")<undefined>();
export const uiForgotPassword = createAction("UI_USER__FORGOT_PASSWORD")<ForgotPasswordPayload>();
export const uiResetPassword = createAction("UI_USER__RESET_PASSWORD")<ResetPasswordPayload>();

// Scopes
export const uiFetchUserScopes = createAction("UI_USER__FETCH_USER_SCOPES")<undefined>();
export const uiSetUserOrganizationScope = createAction("UI_USER__SET_USER_ORGANIZATION_SCOPE")<UserScope>();

// User
export const uiCreateUser = createAction("UI_USER__CREATE_USER")<CreateUserPayload>();

export const uiUpdateUser = createAction("UI_USER__UPDATE_USER")<UpdateUserPayload>();
export const uiUpdateUserStatus = createAction("UI_USER__UPDATE_USER_STATUS")<UpdateUserStatusPayload>();
export const uiUpdateUserPassword = createAction("UI_USER__UPDATE_USER_PASSWORD")<UpdateUserPasswordPayload>();
export const uiUpdateUserPermissions = createAction("UI_USER__UPDATE_USER_PERMISSIONS")<UpdateUserPermissionsPayload>();

export const uiDeleteUser = createAction("UI_USER__DELETE_USER")<number>();

// 2FA
export const uiEnableTwoFactorAuthentication = createAction("UI_USER__ENABLE_TWO_FACTOR_AUTHENTICATION")<number>();
export const uiConfirmTwoFactorAuthentication = createAction("UI_USER__CONFIRM_TWO_FACTOR_AUTHENTICATION")<ConfirmTwoFactorAuthenticationPayload>();
export const uiDisableTwoFactorAuthentication = createAction("UI_USER__DISABLE_TWO_FACTOR_AUTHENTICATION")<number>();

// Pagination
export const uiFetchPaginatedUsers = createAction("UI_USER__FETCH_PAGINATED_USERS")<FetchUsersRequestPayload>();
export const uiChangePaginatedUsersActualPageIndex = createAction("UI_USER__CHANGE_PAGINATED_USERS_ACTUAL_PAGE_INDEX")<number>();
export const uiResetPaginatedUsers = createAction("UI_USER__RESET_PAGINATED_USERS")<undefined>();
