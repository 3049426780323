/* File where we type function with return type X is Y - our custom type guards */
import { Nullable } from "src/app/types/util.types";
import { QuestionAnswerType, SurveyQuestion, SurveySliderQuestion, SurveyTextQuestion } from "src/app/types/api/survey.types";
import { Form } from "src/app/types/ui/form.types";
import { QuestionForm, SliderQuestionForm, TextQuestionForm } from "src/app/types/ui/survey.types";

export const isNotNull = <T>(object: Nullable<T>): object is T => object != null;

export const isNull = <T>(object: Nullable<T>): object is null | undefined => object == null;

export const isString = (object: any): object is string => typeof object === "string";

export const isEmptyString = (object: any): object is "" => typeof object === "string" && object.trim() === "";

export const isNumber = (object: any): object is number => typeof object === "number";

export const isObject = (object: any): object is object => typeof object === "object";

export const isArray = (object: any): object is any[] => Array.isArray(object);

// Surveys
export const isTextSurveyQuestion = (question: SurveyQuestion): question is SurveyTextQuestion => question.answerType === QuestionAnswerType.TEXT;
export const isSliderSurveyQuestion = (question: SurveyQuestion): question is SurveySliderQuestion => question.answerType === QuestionAnswerType.SLIDER;

// Survey Form
export const isTextSurveyFormQuestion = (question: Form<QuestionForm>): question is Form<TextQuestionForm> => question.answerType.value === QuestionAnswerType.TEXT;
export const isSliderSurveyFormQuestion = (question: Form<QuestionForm>): question is Form<SliderQuestionForm> => question.answerType.value === QuestionAnswerType.SLIDER;
