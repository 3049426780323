import { ChangeEvaluationManagerRequestPayload, Evaluation } from "src/app/types/api/evaluation.types";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import { SimpleUser } from "src/app/types/api/user.types";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateNullableField } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import { useEffect } from "react";
import { Button, Modal } from "flowbite-react";
import UserSelect from "src/app/components/Form/UserSelect.component";

type ComponentProps = {
	isOpen: boolean
	handleClose: () => void
	evaluation: Evaluation
	onChangeCoordinatorUser: (payload: ChangeEvaluationManagerRequestPayload) => void
};

type Props =
	ReturnType<typeof mapStateToProps>
	& ComponentProps;

type ChangeEvaluationCoordinatorUserForm = {
	coordinatorUser: SimpleUser
}

const validator: FormValidator<ChangeEvaluationCoordinatorUserForm> = {
	coordinatorUser: (coordinatorUser, optional) => validateNullableField(coordinatorUser, "Osoba prowadząca jest wymagana", optional),
};

function EditEvaluationCoordinatorUserModal(props: Props) {

	const {
		isOpen,
		handleClose,
		evaluation,
		onChangeCoordinatorUser,
		isUpdating,
	} = props;

	const _handleSubmit = (values: ChangeEvaluationCoordinatorUserForm) => {
		onChangeCoordinatorUser({
			id: evaluation.id,
			coordinatorUserId: values.coordinatorUser.id,
		});
		handleClose();
	};

	const _getInitialState = () => ({
		coordinatorUser: createFormField(evaluation.coordinatorUser),
	});

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
	} = useForm(
		_getInitialState(),
		validator,
		_handleSubmit,
	);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ evaluation ]);

	useEffect(() => {
		if (!isOpen && !isUpdating) {
			setForm(_getInitialState());
		}
	}, [ isOpen, isUpdating ]);

	return (
		<Modal
			show={ isOpen || isUpdating }
			onClose={ handleClose }
			size="2xl"
			root={ document.body }
			key={ (isOpen || isUpdating) ? "open" : "hidden" } // AutoFocus on input work with this
		>
			<Modal.Header>
				{ `Edytuj PM'a badania: ${ evaluation.name }` }
			</Modal.Header>
			<form onSubmit={ handleSubmit }>
				<Modal.Body className="overflow-visible">
					<UserSelect
						formItem={ form.coordinatorUser }
						onChange={ user => {
							handleChange("coordinatorUser", user);
							handleBlur("coordinatorUser");
						} }
					/>
				</Modal.Body>
				<Modal.Footer className="flex justify-end border-none pt-0">
					<Button
						onClick={ handleClose }
						color="gray"
					>
						<span>Anuluj</span>
					</Button>
					<Button
						type="submit"
						isProcessing={ isUpdating }
					>
						<span>Zmień</span>
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
}

const mapStateToProps = (state: RootState, props: ComponentProps) => ({
	isUpdating: didLoadingRecordExist(state, { loadableId: props.evaluation.id, loadableType: LoadableType.CHANGE_EVALUATION_COORDINATOR_USER }),
});

export default connect(mapStateToProps)(EditEvaluationCoordinatorUserModal);
