export enum DataTableAction {
	PAGE_CHANGE = "changePage",
	SORT = "sort",
	FILTER_CHANGE = "filterChange",
	RESET_FILTERS = "resetFilters",
	SEARCH = "search",
	CHANGE_ROWS_PER_PAGE = "changeRowsPerPage",
	PROPS_UPDATE = "propsUpdate",
}

export enum TableURLParamsKey {
	PAGE_INDEX = "PageIndex",
	PAGE_SIZE = "PageSize",
	SEARCH = "Search",
	SORT_NAME = "SortName",
	SORT_DIRECTION = "SortDirection",
	FILTER = "Filter",
	FILTER_SEPARATOR = ";",
}
