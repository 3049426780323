import { AbilitiesObject, AllAbilities } from "src/app/types/api/abilities.types";
import { isArray, isNotNull, isNull } from "src/app/utils/typeguards";
import { Nullable } from "src/app/types/util.types";
import { LoggedUser, SelfAbility, UserAbility } from "src/app/types/api/user.types";

import { OrganizationAbility } from "src/app/types/api/organization.types";
import { EvaluationAbility } from "src/app/types/api/evaluation.types";

import { ParticipantAbility } from "src/app/types/api/participant.types";

// Abilities check import plop - don't remove

function checkSelfAbility(o: LoggedUser, searchedAbility: SelfAbility) {
	if (isNull(o) || !isArray(o.meta?.selfAbilities)) return false;

	return isNotNull(o.meta.selfAbilities.find(ability => ability === searchedAbility));
}

function checkAbility<T extends AllAbilities>(o: Nullable<AbilitiesObject<T>>, searchedAbility: T) {
	if (isNull(o) || !isArray(o.meta?.abilities)) return false;

	return isNotNull(o.meta.abilities.find(ability => ability === searchedAbility));
}

// Self
export const isAppAdministration = (loggedUser: LoggedUser) => checkSelfAbility(loggedUser, SelfAbility.APP_ADMINISTRATION);
// export const canAccessAdminPanel = (loggedUser: LoggedUser) => checkSelfAbility(loggedUser, SelfAbility.ADMIN_PANEL_ACCESS);
export const canLoggedUserCreateUser = (loggedUser: LoggedUser) => checkSelfAbility(loggedUser, SelfAbility.CREATE_USER);
export const canLoggedUserListUsers = (loggedUser: LoggedUser) => checkSelfAbility(loggedUser, SelfAbility.LIST_USERS);
export const canLoggedUserUpdateSelf = (loggedUser: LoggedUser) => checkSelfAbility(loggedUser, SelfAbility.UPDATE_SELF);
export const canLoggedUserCreateOrganization = (loggedUser: LoggedUser) => checkSelfAbility(loggedUser, SelfAbility.CREATE_ORGANIZATION);
export const canLoggedUserListOrganizations = (loggedUser: LoggedUser) => checkSelfAbility(loggedUser, SelfAbility.LIST_ORGANIZATIONS);
export const canLoggedUserCreateEvaluation = (loggedUser: LoggedUser) => checkSelfAbility(loggedUser, SelfAbility.CREATE_EVALUATION);
export const canLoggedUserListEvaluations = (loggedUser: LoggedUser) => checkSelfAbility(loggedUser, SelfAbility.LIST_EVALUATIONS);

// User
export const canViewUser = (user: AbilitiesObject<UserAbility>) => checkAbility(user, UserAbility.VIEW);
export const canUpdateUser = (user: AbilitiesObject<UserAbility>) => checkAbility(user, UserAbility.UPDATE);
export const canUpdateUser2Fa = (user: AbilitiesObject<UserAbility>) => checkAbility(user, UserAbility.UPDATE_2FA);
export const canUpdateUserRole = (user: AbilitiesObject<UserAbility>) => checkAbility(user, UserAbility.UPDATE_ROLE);
export const canUpdateUserEmail = (user: AbilitiesObject<UserAbility>) => checkAbility(user, UserAbility.UPDATE_EMAIL);
export const canUpdateUserStatus = (user: AbilitiesObject<UserAbility>) => checkAbility(user, UserAbility.UPDATE_STATUS);
export const canUpdateUserCompany = (user: AbilitiesObject<UserAbility>) => checkAbility(user, UserAbility.UPDATE_COMPANY);
export const canUpdateUserPassword = (user: AbilitiesObject<UserAbility>) => checkAbility(user, UserAbility.UPDATE_PASSWORD);
export const canUpdateUserPermissions = (user: AbilitiesObject<UserAbility>) => checkAbility(user, UserAbility.UPDATE_PERMISSIONS);
export const canDeleteUser = (user: AbilitiesObject<UserAbility>) => checkAbility(user, UserAbility.DELETE);

// Organization
export const canViewOrganization = (organization: AbilitiesObject<OrganizationAbility>) => checkAbility(organization, OrganizationAbility.VIEW);
export const canUpdateOrganization = (organization: AbilitiesObject<OrganizationAbility>) => checkAbility(organization, OrganizationAbility.UPDATE);
export const canDeleteOrganization = (organization: AbilitiesObject<OrganizationAbility>) => checkAbility(organization, OrganizationAbility.DELETE);

// Evaluation
export const canViewEvaluation = (evaluation: AbilitiesObject<EvaluationAbility>) => checkAbility(evaluation, EvaluationAbility.VIEW);
export const canUpdateEvaluation = (evaluation: AbilitiesObject<EvaluationAbility>) => checkAbility(evaluation, EvaluationAbility.UPDATE);
export const canDeleteEvaluation = (evaluation: AbilitiesObject<EvaluationAbility>) => checkAbility(evaluation, EvaluationAbility.DELETE);
export const canAddOrDeleteEvaluationParticipant = (evaluation: AbilitiesObject<EvaluationAbility>) => checkAbility(evaluation, EvaluationAbility.ADD_OR_DELETE_PARTICIPANT);
export const canFinishEvaluation = (evaluation: AbilitiesObject<EvaluationAbility>) => checkAbility(evaluation, EvaluationAbility.FINISH);
export const canGenerateEvaluationReport = (evaluation: AbilitiesObject<EvaluationAbility>) => checkAbility(evaluation, EvaluationAbility.GENERATE_REPORT);
export const canPublishEvaluationReport = (evaluation: AbilitiesObject<EvaluationAbility>) => checkAbility(evaluation, EvaluationAbility.PUBLISH_REPORT);
export const canMassRemindEvaluationParticipants = (evaluation: AbilitiesObject<EvaluationAbility>) => checkAbility(evaluation, EvaluationAbility.MASS_REMINDER);

// Participant
export const canViewParticipant = (participant: AbilitiesObject<ParticipantAbility>) => checkAbility(participant, ParticipantAbility.VIEW);
export const canUpdateParticipant = (participant: AbilitiesObject<ParticipantAbility>) => checkAbility(participant, ParticipantAbility.UPDATE);
export const canDeleteParticipant = (participant: AbilitiesObject<ParticipantAbility>) => checkAbility(participant, ParticipantAbility.DELETE);

// Abilities check plop - don't remove




