import { User } from "src/app/types/api/user.types";
import UserSelect from "src/app/components/Form/UserSelect.component";
import { FormValidator } from "src/app/types/ui/form.types";
import useForm from "src/app/utils/hooks/useForm";
import { createFormField, validateNullableField } from "src/app/utils/forms";
import { Nullable } from "src/app/types/util.types";
import { isNull } from "src/app/utils/typeguards";
import { Button } from "flowbite-react";

type Props = {
	impersonateUser: Nullable<User>
	onChooseUser: (user: User) => void
	onReset: () => void
};

type ImpersonateUserForm = {
	user: Nullable<User>
}

const validator: FormValidator<ImpersonateUserForm> = {
	user: (user, optional) => validateNullableField(user, "Użytkownik jest wymagany", optional),
};

function ImpersonateUserContainer(props: Props) {

	const {
		impersonateUser,
		onChooseUser,
		onReset,
	} = props;

	const _handleSubmit = (values: ImpersonateUserForm) => {
		if (isNull(values.user)) return;

		onChooseUser(values.user);
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
	} = useForm({
		user: createFormField(impersonateUser),
	}, validator, _handleSubmit);

	return (
		<form className="flex flex-col gap-2" onSubmit={ handleSubmit }>
			<UserSelect
				formItem={ form.user }
				onChange={ user => {
					handleChange("user", user);
					handleBlur("user");
				} }
			/>
			<div className="flex gap-2">
				<Button color="primary" type="submit">
					<span>Impersonate user</span>
				</Button>
				<Button
					color="gray"
					onClick={ e => {
						e.preventDefault();
						onReset();
					} }
				>
					<span>Reset</span>
				</Button>
			</div>
		</form>
	);
}

export default (ImpersonateUserContainer);
