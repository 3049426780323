import { RouteObject } from "react-router/dist/lib/context";
import OrganizationListView from "src/app/views/Organization/OrganizationList.view";
import OrganizationProfileView from "src/app/views/Organization/OrganizationProfile.view";
import OrganizationEditView from "src/app/views/Organization/OrganizationEdit.view";
import OrganizationCreateView from "src/app/views/Organization/OrganizationCreate.view";

const organizationRoutes: RouteObject = {
    path: "organizations",
    children: [
        {
            index: true,
            element: <OrganizationListView/>,
        }, {
            path: "create",
            element: <OrganizationCreateView/>,
        }, {
            path: ":organizationId",
            element: <OrganizationProfileView/>,
        }, {
            path: ":organizationId/edit",
            element: <OrganizationEditView/>,
        },
    ],
};

export default organizationRoutes;
