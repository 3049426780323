import { Avatar as FlowbiteAvatar, AvatarProps } from "flowbite-react";
import { IconType } from "react-icons";
import classNames from "classnames";
import { isNotNull, isNull } from "src/app/utils/typeguards";

type Props =
	Omit<AvatarProps, "placeholder">
	& {
		placeholder?: IconType
	};

function Avatar(props: Props) {

	const {
		className,
		img,
		placeholder,
		...rest
	} = props;

	return (
		<FlowbiteAvatar
			className={
				classNames(
					className,
					"[&>div>img]:object-cover",
					{ "[&>div>svg]:p-1": (isNull(img) && isNotNull(placeholder)) },
				)
			}
			img={ img ?? placeholder }
			{ ...rest }
		/>
	);
}

export default (Avatar);
