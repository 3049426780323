import { ErrorCodeName } from "src/app/types/api/api.types";
import { Button } from "flowbite-react";
import { errorCodeNameDictionary } from "src/app/utils/constants/dictionaries";

type Props = {
	codeName: ErrorCodeName
	message?: string
	buttonText: string
	onButtonClick: () => void
	isLoading?: boolean
};

function ErrorWrapper(props: Props) {

	const {
		codeName,
		message,
		buttonText,
		onButtonClick,
		isLoading,
	} = props;

	return (
		<div className="w-full h-full flex flex-col items-center justify-center">
			<h1 className="mb-6 text-2xl font-bold dark:text-white md:text-5xl">
				{ errorCodeNameDictionary[ codeName ] }
			</h1>
			<p className="mb-6 w-4/5 text-center text-lg text-gray-500 dark:text-gray-300">
				{ message }
			</p>
			<Button
				onClick={ onButtonClick }
				isProcessing={ isLoading }
			>
				<span>{ buttonText }</span>
			</Button>
		</div>
	);
}

export default (ErrorWrapper);
