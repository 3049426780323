/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { Organization } from "src/app/types/api/organization.types";
import { Evaluation } from "src/app/types/api/evaluation.types";
import { SimpleSurvey, Survey } from "src/app/types/api/survey.types";
import { Nullable } from "src/app/types/util.types";

export enum ParticipantAbility {
	VIEW = "participant.view",
	UPDATE = "participant.update",
	DELETE = "participant.delete",
}

export enum ParticipantType {
	SUBJECT = "SUBJECT",
	SUPERVISOR = "SUPERVISOR",
	SUBORDINATE = "SUBORDINATE",
	COLLEAGUE = "COLLEAGUE",
}

export enum ParticipantSurveyStatus {
	NOT_SENT = "NOT_SENT",
	NOT_OPENED = "NOT_OPENED",
	OPENED = "OPENED",
	IN_PROGRESS = "IN_PROGRESS",
	COMPLETED = "COMPLETED",
}

export enum SurveyLanguage {
	POLISH = "POLISH",
	ENGLISH = "ENGLISH",
}

export type SimpleParticipant = { // ParticipantViewModel::simple
	id: number

	organizationId: string
	evaluationId: number

	name: string
	email: string
	participantType: ParticipantType
	participantSurveyStatus: ParticipantSurveyStatus
	surveyLanguage: SurveyLanguage
	statusChangedAt: string

	createdAt: string
	updatedAt: string

	meta: {
		abilities: ParticipantAbility[]
	}
}

export type Participant = // ParticipantViewModel::standard
	SimpleParticipant
	& {
		survey: Nullable<SimpleSurvey>
	}

export type DetailedParticipant = // ParticipantViewModel::detailed
	Participant
	& {
		organization: Organization
		evaluation: Evaluation
		survey: Nullable<Survey>
	}

export type CreateParticipantPayload = {
	evaluationId: number
	name: string
	email: string
	participantType: ParticipantType
	surveyLanguage: SurveyLanguage
}

export type CreateExternalParticipantPayload =
	CreateParticipantPayload
	& {
		email: string
		token: string
	};

export type UpdateParticipantPayload = {
	id: number
	evaluationId: number
	name?: string
	email?: string
	participantType?: ParticipantType
	surveyLanguage?: SurveyLanguage
}

export type UpdateExternalParticipantPayload =
	UpdateParticipantPayload
	& {
		email: string
		token: string
	};

export type DeleteParticipantPayload = {
	id: number
	evaluationId: number
}

export type DeleteExternalParticipantPayload =
	DeleteParticipantPayload
	& {
		email: string
		token: string
	}

export type SendReminderToParticipantsPayload = {
	id: number
	participantIds: {
		id: number
	}[]
}

export type ExternalSendReminderToParticipantsPayload =
	SendReminderToParticipantsPayload
	& {
		email: string
		token: string
	}

export type ImportParticipantsPayload = {
	id: number
	file: File
}

export type ExternalImportParticipantsPayload =
	ImportParticipantsPayload
	& {
		email: string
		token: string
	}
