import { QuestionForm, SliderQuestionForm } from "src/app/types/ui/survey.types";
import { Form } from "src/app/types/ui/form.types";
import { Alert, RangeSlider } from "flowbite-react";
import { isNotNull, isSliderSurveyFormQuestion } from "src/app/utils/typeguards";
import { isFormValid, validateForm } from "src/app/utils/forms";
import { englishQuestionValidator, questionValidator } from "src/app/utils/constants/survey";
import classNames from "classnames";
import { SurveyLanguage } from "src/app/types/api/participant.types";
import { MdErrorOutline } from "react-icons/md";

type Props = {
	question: Form<SliderQuestionForm>
	form: Form<{ questions: Form<QuestionForm>[], language: SurveyLanguage }>
	handleChange: (prop: "questions", value: any) => void
};

function SliderSurveyQuestion(props: Props) {

	const {
		question,
		form,
		handleChange,
	} = props;

	const _handleChangeSliderQuestion = (value: string) => {
		handleChange(
			"questions",
			form.questions.value.map(formQuestion => {
				if (formQuestion.id.value !== question.id.value) return formQuestion;

				if (!isSliderSurveyFormQuestion(formQuestion)) return formQuestion;

				if (formQuestion.answerNumberValue.disabled) return formQuestion;

				if (isNotNull(formQuestion.answerNumberValue.error)) {
					const newForm = validateForm(formQuestion, form.language.value === SurveyLanguage.POLISH ? questionValidator : englishQuestionValidator);
					if (isFormValid(newForm)) {
						return {
							...formQuestion,
							answerNumberValue: {
								...formQuestion.answerNumberValue,
								value,
								touched: true,
								error: null,
							},
						};
					}
				}

				return {
					...formQuestion,
					answerNumberValue: {
						...formQuestion.answerNumberValue,
						value,
						touched: true,
					},
				};
			}),
		);
	};

	return (
		<div id={ `question-${ question.id.value }` } className="w-[768px] border border-gray-200 pt-20 pb-16 px-11 bg-white shadow-sm flex flex-col gap-8">
			<p className="font-semibold text-lg">{ question.title.value }</p>
			<div className="relative flex flex-col gap-2">
				<RangeSlider
					className={
						classNames(
							"[&_input]:h-6",
							{ "[&_input]:cursor-default": question.answerNumberValue.disabled },
						)
					}
					min={ 0 }
					max={ 100 }
					sizing="md"
					disabled={ question.answerNumberValue.disabled }
					value={ question.answerNumberValue.value }
					onChange={ e => _handleChangeSliderQuestion(e.target.value) }
					draggable
					onDragStart={ e => {
						e.preventDefault();
						// @ts-ignore
						e.stopImmediatePropagation();
					} }
				/>
			</div>
			<div className="flex items-center justify-between">
				<span className="text-center font-semibold text-gray-500">{ form.language.value === SurveyLanguage.POLISH ? <>Zdecydowanie się <br/>nie zgadzam</> : <>Strongly <br/>disagree</> }</span>
				<span className="text-center font-semibold text-gray-500">{ form.language.value === SurveyLanguage.POLISH ? <>Zdecydowanie się <br/>zgadzam</> : <>Strongly <br/>agree</> }</span>
			</div>
			{
				isNotNull(question.answerNumberValue.error) &&
                <Alert color="failure" icon={ MdErrorOutline }>
					{ question.answerNumberValue.error }
                </Alert>
			}
		</div>
	);
}

export default (SliderSurveyQuestion);
