import { debounceTime, filter, map, mergeMap, switchMap, withLatestFrom } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { deleteTestPaginationAsync, fetchTestPaginationAsync, uiFetchTestPagination } from "src/app/store/features/pagination/pagination.actions";
import { RootEpic } from "src/app/store/root.epic";
import { isNotNull } from "src/app/utils/typeguards";
import { apiAsync } from "src/app/store/features/api/api.actions";
import { FailurePayload, SuccessPayload } from "src/app/types/api/api.types";
import { DataState, LoadingState } from "src/app/types/redux.types";
import { concat, merge, of } from "rxjs";
import { empty } from "src/app/store/features/misc/misc.actions";
import { FetchTestPaginationRequestPayload } from "src/app/types/api/pagination.types";
import { getBoundaryPageIndexes, getMaxPageIndex, isDifferentPaginationOptions } from "src/app/utils/helpers";

export const fetchTestPaginationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchTestPaginationAsync.request)),
		map(action => {
			const pageSize = action.payload.pageSize;
			const pageIndex = action.payload.pageIndex;

			const sortParam = isNotNull(action.payload.sort) ? `&sort=${ action.payload.sort }` : "";
			// const ownerUserIdParams = action.payload.filters.user_id.reduce((prev, next) => `${ prev }&filter[ownerUserId][]=${ next }`, "");
			const search = isNotNull(action.payload.search) ? `&search=${ action.payload.search }` : "";

			return apiAsync.request({
				// url: `/users?pageSize=${ pageSize }&pageIndex=${ pageIndex }${ search }${ sortParam }${ ownerUserIdParams }`,
				url: `/users?pageSize=${ pageSize }&pageIndex=${ pageIndex }${ search }${ sortParam }`,
				method: "GET",
				withScope: false,
				onSuccess: (payload: SuccessPayload<any[]>) => fetchTestPaginationAsync.success({ ...payload, id: action.payload }),
				onFailure: (payload: FailurePayload) => fetchTestPaginationAsync.failure({ ...payload, id: action.payload.pageIndex }),
			});
		}),
	);

export const uiFetchTestPaginationEpic: RootEpic = (action$, state$) =>
	action$.pipe(
		filter(isActionOf(uiFetchTestPagination)),
		debounceTime(300),
		switchMap(action => {
			// Check if page exist or already loading ->
			// if no = proceed
			// if yes = fetch boundary pages
			const paginatedTestReducer = state$.value.pagination.paginatedTest;

			const isRequestingPageLoadedOrLoading = paginatedTestReducer.pages.some(page =>
				(
					page.pageIndex === action.payload.pageIndex &&
					(
						page.data.dataState === DataState.PRESENT ||
						(
							page.data.dataState === DataState.NOT_PRESENT &&
							page.data.loadingState === LoadingState.LOADING
						)
					)
				),
			);

			if (
				isRequestingPageLoadedOrLoading &&
				!isDifferentPaginationOptions(
					{ actualPageSize: paginatedTestReducer.meta.actualPageSize, newPageSize: action.payload.pageSize },
					{ actualSearch: paginatedTestReducer.meta.actualSearch, newSearch: action.payload.search },
					{ actualSort: paginatedTestReducer.meta.actualSort, newSort: action.payload.sort },
					{ actualFilters: paginatedTestReducer.meta.actualFilters, newFilters: action.payload.filters },
				)
			) {
				const maxPageIndex = getMaxPageIndex(paginatedTestReducer.meta.totalCount, action.payload.pageSize);
				const fetchBoundaryPagesRequests =
					getBoundaryPageIndexes(paginatedTestReducer.pages, action.payload.pageIndex, { maxPageIndex })
						.map(pageIndex =>
							of(fetchTestPaginationAsync.request({
								...action.payload,
								pageIndex,
								isBoundaryPage: true,
							})),
						);
				return merge(
					...fetchBoundaryPagesRequests,
				);
			}

			return concat(
				of(fetchTestPaginationAsync.request(action.payload)),
				action$.pipe(
					filter(responseAction =>
						(
							isActionOf(fetchTestPaginationAsync.success, responseAction) &&
							!isDifferentPaginationOptions(
								{ actualPageSize: action.payload.pageSize, newPageSize: responseAction.payload.id.pageSize },
								{ actualSearch: action.payload.search, newSearch: responseAction.payload.id.search },
								{ actualSort: action.payload.sort, newSort: responseAction.payload.id.sort },
								{ actualFilters: action.payload.filters, newFilters: responseAction.payload.id.filters },
							)
						),
					),
					withLatestFrom(state$),
					mergeMap(([ responseAction, state ]) => {
						if (isActionOf(fetchTestPaginationAsync.success, responseAction)) {
							// if fetched page is actual page from reducer meta (with isDiffPagOptions)
							// calculate boundaries of fetched page
							// check if that boundaries are DataState.PRESENT and LoadingState.NOT_LOADING
							// fetch unfetched pages
							const paginatedTestReducer = state.pagination.paginatedTest;
							if (
								!isDifferentPaginationOptions(
									{ actualPageSize: paginatedTestReducer.meta.actualPageSize, newPageSize: responseAction.payload.id.pageSize },
									{ actualSearch: paginatedTestReducer.meta.actualSearch, newSearch: responseAction.payload.id.search },
									{ actualSort: paginatedTestReducer.meta.actualSort, newSort: responseAction.payload.id.sort },
									{ actualFilters: paginatedTestReducer.meta.actualFilters, newFilters: responseAction.payload.id.filters },
								) &&
								paginatedTestReducer.meta.actualPageIndex === responseAction.payload.id.pageIndex
							) {
								const maxPageIndex = getMaxPageIndex(responseAction.payload.meta?.totalCount ?? responseAction.payload.data.length, responseAction.payload.id.pageSize);
								const fetchBoundaryPagesRequests =
									getBoundaryPageIndexes(paginatedTestReducer.pages, responseAction.payload.id.pageIndex, { maxPageIndex })
										.map(pageIndex =>
											of(fetchTestPaginationAsync.request({
												...action.payload,
												pageIndex,
												isBoundaryPage: true,
											})),
										);

								return merge(
									...fetchBoundaryPagesRequests,
								);
							}
						}

						return of(empty());
					}),
				),
			);
		}),
	);

export const uiHandleDeleteTestPaginationEpic: RootEpic = (action$, state$) =>
	action$.pipe(
		filter(isActionOf(deleteTestPaginationAsync.success)),
		mergeMap(action => {
			const paginatedCasesReducer = state$.value.pagination.paginatedTest;
			if (
				!paginatedCasesReducer.pages.some(page =>
					page.data.dataState === DataState.PRESENT &&
					page.data.data.some(singleCase =>
						singleCase.id === action.payload.data.id,
					))
			) {
				return of(empty());
			}

			const actualPageIndex = paginatedCasesReducer.meta.actualPageIndex;
			const maxPageIndex = Math.floor(paginatedCasesReducer.meta.totalCount / paginatedCasesReducer.meta.actualPageSize);

			const fetchPageRequestPayload: FetchTestPaginationRequestPayload = {
				pageIndex: actualPageIndex,
				pageSize: paginatedCasesReducer.meta.actualPageSize,
				sort: paginatedCasesReducer.meta.actualSort,
				isBoundaryPage: false,
				filters: paginatedCasesReducer.meta.actualFilters,
				search: paginatedCasesReducer.meta.actualSearch,
			};

			const fetchBoundaryPageRequests =
				getBoundaryPageIndexes(paginatedCasesReducer.pages, actualPageIndex, { maxPageIndex })
					.filter(pageIndex => pageIndex > actualPageIndex)
					.map(pageIndex =>
						of(fetchTestPaginationAsync.request({
							...fetchPageRequestPayload,
							pageIndex,
							isBoundaryPage: true,
						})),
					);

			return merge(
				of(fetchTestPaginationAsync.request({
					...fetchPageRequestPayload,
					pageIndex: actualPageIndex,
					isBoundaryPage: false,
				})),
				...fetchBoundaryPageRequests,
			);
		}),
	);
