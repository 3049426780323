import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import ImpersonateUserContainer from "src/app/containers/User/ImpersonateUser.container";
import { setImpersonateUserId } from "src/app/store/features/misc/misc.actions";
import LoadModel from "src/app/hoc/caching/LoadModel.hoc";
import { fetchUserByIdAsync } from "src/app/store/features/user/user.actions";
import { getUserById } from "src/app/store/features/user/user.selectors";
import { useEffect } from "react";
import { isNotNull } from "src/app/utils/typeguards";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function ImpersonateUserView(props: Props) {

	const {
		impersonateUserId,
		setImpersonateUser,
		fetchUserById,
		getUser,
	} = props;

	useEffect(() => {
		isNotNull(impersonateUserId) && fetchUserById(impersonateUserId);
	}, []);

	return (
		<LoadModel
			modelId={ impersonateUserId }
			getModel={ getUser }
			strategy="forceFresh"
		>
			{
				user =>
					<ImpersonateUserContainer
						impersonateUser={ user }
						onChooseUser={ user => setImpersonateUser(user.id) }
						onReset={ () => setImpersonateUser(null) }
					/>
			}
		</LoadModel>
	);
}

const mapStateToProps = (state: RootState) => ({
	impersonateUserId: state.misc.impersonateUserId,
	getUser: (userId: number) => getUserById(state, userId),
});

const mapDispatchToProps = {
	setImpersonateUser: setImpersonateUserId,
	fetchUserById: fetchUserByIdAsync.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImpersonateUserView);
