import { PropsWithChildren, useEffect } from "react";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { applicationStart } from "src/app/store/features/misc/misc.actions";
import { isAuthorized } from "src/app/store/features/user/user.selectors";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function App(props: PropsWithChildren<Props>) {

	const {
		applicationStart,
		children,
	} = props;

	useEffect(() => {
		applicationStart();
	}, []);

	return (
		<>{ children }</>
	);
}

const mapStateToProps = (state: RootState) => ({
	isAuthorized: isAuthorized(state),
});

const mapDispatchToProps = {
	applicationStart: applicationStart,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
