import { ErrorCodeName } from "src/app/types/api/api.types";
import ErrorWrapper from "src/app/components/Message/ErrorWrapper.component";
import { useNavigate } from "react-router-dom";
import { getDefaultView } from "src/app/utils/constants/constants";
import { useContext } from "react";
import { UserScopeContext } from "src/app/hoc/providers/UserScope.provider";

function NotFoundView() {

	const navigate = useNavigate();
	const userScope = useContext(UserScopeContext);

	return (
		<ErrorWrapper
			codeName={ ErrorCodeName.GENERIC_NOT_FOUND }
			message="Page not found"
			buttonText="Go back"
			onButtonClick={
				() =>
					navigate(
						getDefaultView(userScope.organization.id),
						{ replace: true },
					)
			}
		/>
	);
}

export default (NotFoundView);
