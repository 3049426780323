import { combineEpics, Epic } from "redux-observable";
import { RootAction } from "src/app/store/root.actions";
import { RootState } from "src/app/store/root.reducer";
import { Services } from "src/app/store/services";

import * as apiEpics from "src/app/store/features/api/api.epics";
import * as messageEpics from "src/app/store/features/message/message.epics";
import * as miscEpics from "src/app/store/features/misc/misc.epics";
import * as userEpics from "src/app/store/features/user/user.epics";
import * as wsEpics from "src/app/store/features/ws/ws.epics";
import * as testEpics from "src/app/store/features/pagination/pagination.epics";
import * as organizationEpics from "src/app/store/features/organization/organization.epics";
import * as evaluationEpics from "src/app/store/features/evaluation/evaluation.epics";
import * as participantEpics from "src/app/store/features/participant/participant.epics";
import * as surveyEpics from "src/app/store/features/survey/survey.epics";
// Epics imports - don't remove
import * as uiLayoutEpics from "src/app/store/features/ui/layout/ui.layout.epics";
import * as uiUserEpics from "src/app/store/features/ui/user/ui.user.epics";
import * as uiOrganizationEpics from "src/app/store/features/ui/organization/ui.organization.epics";
import * as uiEvaluationEpics from "src/app/store/features/ui/evaluation/ui.evaluation.epics";
import * as uiParticipantEpics from "src/app/store/features/ui/participant/ui.participant.epics";
import * as uiSurveyEpics from "src/app/store/features/ui/survey/ui.survey.epics";
// Ui epics imports - don't remove

export type RootEpic = Epic<RootAction, RootAction, RootState, Services>;

export default combineEpics(
	...Object.values(apiEpics),
	...Object.values(messageEpics),
	...Object.values(miscEpics),
	...Object.values(userEpics),
	...Object.values(wsEpics),
	...Object.values(testEpics),
	...Object.values(organizationEpics),
	...Object.values(evaluationEpics),
	...Object.values(participantEpics),
	...Object.values(surveyEpics),
	// Epics object - don't remove

	...Object.values(uiLayoutEpics),
	...Object.values(uiUserEpics),
	...Object.values(uiOrganizationEpics),
	...Object.values(uiEvaluationEpics),
	...Object.values(uiParticipantEpics),
	...Object.values(uiSurveyEpics),
	// Ui epics object - don't remove
);
