import { StateReducer } from "src/app/types/redux.types";
import { useState } from "react";
import { isNotNull } from "src/app/utils/typeguards";
import { Nullable } from "src/app/types/util.types";
import ForceFreshStrategy from "src/app/hoc/caching/ForceFreshStrategy.hoc";
import CachedThenFreshStrategy from "src/app/hoc/caching/CachedThenFreshStrategy.hoc";

type Props<T, R extends Nullable<string> | Nullable<number>> = {
	modelId: Nullable<R>
	getModel: (modelId: R) => StateReducer<T>
	strategy: "forceFresh" | "cachedThenFresh"
	children: (model: Nullable<T>) => JSX.Element
};

function LoadModel<T, R extends Nullable<string> | Nullable<number>>(props: Props<T, R>) {

	const {
		modelId,
		getModel,
		strategy,
		children,
	} = props;

	const [ initialModelId ] = useState(modelId);

	if (isNotNull(initialModelId)) {
		switch (strategy) {
			case "forceFresh":
				return (
					<ForceFreshStrategy
						request={ () => undefined }
						state={ getModel(initialModelId) }
					>
						{ model => children(model) }
					</ForceFreshStrategy>
				);
			case "cachedThenFresh":
				return (
					<CachedThenFreshStrategy
						request={ () => undefined }
						state={ getModel(initialModelId) }
					>
						{ model => children(model) }
					</CachedThenFreshStrategy>
				);
		}
	}

	return children(null);
}

export default (LoadModel);
