import { QuestionForm } from "src/app/types/ui/survey.types";
import { isNotNull, isSliderSurveyFormQuestion, isTextSurveyFormQuestion } from "src/app/utils/typeguards";
import TextSurveyQuestion from "src/app/components/Survey/Questions/TextSurveyQuestion.component";
import SliderSurveyQuestion from "src/app/components/Survey/Questions/SliderSurveyQuestion.component";
import useUserScopeNavigate from "src/app/utils/hooks/useUserScopeNavigate";
import { Link, useParams } from "react-router-dom";
import { HiArrowLeft, HiCheck } from "react-icons/hi";
import { Button } from "flowbite-react";
import BreadcrumbPortal from "src/app/components/Utils/BreadcrumbPortal.component";
import { DetailedSurvey, QuestionAnswerType, SaveSurveyQuestion } from "src/app/types/api/survey.types";
import { Form, FormValidator } from "src/app/types/ui/form.types";
import { isFormValid, validateForm } from "src/app/utils/forms";
import { questionValidator } from "src/app/utils/constants/survey";
import useForm from "src/app/utils/hooks/useForm";
import { mapSurveyToAdminForm } from "src/app/utils/survey";
import { FormEvent } from "react";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import { SurveyLanguage } from "src/app/types/api/participant.types";

type ComponentProps = {
	survey: DetailedSurvey
	onSave: (questions: SaveSurveyQuestion[]) => void
};

type Props =
	ReturnType<typeof mapStateToProps>
	& ComponentProps;

export type AdminSurveyForm = {
	questions: Form<QuestionForm>[]
	language: SurveyLanguage
}

const validator: FormValidator<AdminSurveyForm> = {
	questions: questions => {
		if (questions.every(question => {
			const newForm = validateForm(question, questionValidator);
			return isFormValid(newForm);
		})) {
			return null;
		}
		return "Na jedno lub więcej pytań nie udzielono odpowiedzi";
	},
	language: () => null,
};

function EvaluationParticipantSurveyContainer(props: Props) {

	const {
		survey,
		onSave,
		isSaving,
	} = props;

	const { evaluationId = "0" } = useParams();
	const { getLink, forceNavigate } = useUserScopeNavigate();

	const _handleSubmit = (values: AdminSurveyForm) => {
		onSave(
			values.questions
				  .filter(isTextSurveyFormQuestion)
				  .map<SaveSurveyQuestion>(question => ({
						  id: question.id.value,
						  answerType: QuestionAnswerType.TEXT,
						  answerTextValue: question.answerTextValue.value,
					  }),
				  ),
		);
	};

	const {
		form,
		handleChange,
		handleSubmit,
	} = useForm(
		mapSurveyToAdminForm(survey.content.questions),
		validator,
		_handleSubmit,
	);

	const _handlePreSubmit = (e?: FormEvent<HTMLFormElement>) => {
		e?.preventDefault();
		if (
			form.questions.value.every(question => {
				const newForm = validateForm(question, questionValidator);
				return isFormValid(newForm);
			})
		) {
			return handleSubmit(e);
		} else {
			const questionWithError = form.questions.value.find(question => {
				const newForm = validateForm(question, questionValidator);
				return !isFormValid(newForm);
			});
			const element = document.getElementById(`question-${ questionWithError?.id?.value }`);
			if (isNotNull(element)) element.scrollIntoView({ behavior: "smooth" });
		}

		handleChange("questions", form.questions.value.map(question => validateForm(question, questionValidator)));
	};

	return (
		<form onSubmit={ _handlePreSubmit } className="grid grid-cols-12">
			<BreadcrumbPortal>
				<div className="flex justify-between gap-2 items-center">
					<Link
						to={ getLink("/evaluations") }
						className="self-end flex items-center gap-2 cursor-pointer"
						onClick={ e => {
							e.preventDefault();
							forceNavigate(`/evaluations/${ evaluationId }`);
						} }
					>
						<HiArrowLeft className="w-5 h-5"/>
						<span className="text-sm font-medium">Wróć do badania</span>
					</Link>
					<Button
						type="submit"
						isProcessing={ isSaving }
						onClick={ () => _handlePreSubmit() }
					>
						<span><HiCheck className="mr-2 w-4 h-4"/></span>
						<span>Zapisz</span>
					</Button>
				</div>
			</BreadcrumbPortal>
			<div className="col-span-6 col-start-4 flex flex-col gap-4">
				{
					form.questions.value.map(question => {
						if (isTextSurveyFormQuestion(question)) {
							return (
								<TextSurveyQuestion
									key={ question.id.value }
									question={ question }
									form={ form }
									handleChange={ handleChange }
								/>
							);
						} else if (isSliderSurveyFormQuestion(question)) {
							return (
								<SliderSurveyQuestion
									key={ question.id.value }
									question={ question }
									form={ form }
									handleChange={ handleChange }
								/>
							);
						} else {
							return null;
						}
					})
				}
			</div>
		</form>
	);
}

const mapStateToProps = (state: RootState, props: ComponentProps) => ({
	isSaving: didLoadingRecordExist(state, { loadableType: LoadableType.SAVE_SURVEY, loadableId: props.survey.id }),
});

export default connect(mapStateToProps)(EvaluationParticipantSurveyContainer);
