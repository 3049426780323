/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { createAction } from "typesafe-actions";
import { CreateOrganizationPayload, UpdateOrganizationPayload } from "src/app/types/api/organization.types";

export const uiCreateOrganization = createAction("UI_ORGANIZATION__CREATE_ORGANIZATION")<CreateOrganizationPayload>();
export const uiUpdateOrganization = createAction("UI_ORGANIZATION__UPDATE_ORGANIZATION")<UpdateOrganizationPayload>();
export const uiDeleteOrganization = createAction("UI_ORGANIZATION__DELETE_ORGANIZATION")<string>();