import { getEnvValue, mapEnumToSelectOptions } from "src/app/utils/helpers";
import { LoggedUser } from "src/app/types/api/user.types";
import { SidebarItem } from "src/app/types/util.types";
import { RiSurveyFill } from "react-icons/ri";
import { EvaluationLanguage, EvaluationScheduledStartType } from "src/app/types/api/evaluation.types";
import { evaluationLanguageDictionary, evaluationScheduledStartTypeDictionary, participantTypeDictionary, surveyLanguageDictionary } from "src/app/utils/constants/dictionaries";
import { ParticipantType, SurveyLanguage } from "src/app/types/api/participant.types";
import { canLoggedUserListUsers } from "src/app/utils/abilities";
import { HiUserCircle } from "react-icons/hi";

export const appVersion = getEnvValue("REACT_APP_VERSION");
export const baseUrl = getEnvValue("REACT_APP_API_URL");
export const defaultView = getEnvValue("REACT_APP_DEFAULT_VIEW");

export const getDefaultView = (organizationId: string) => `/organization/${ organizationId }${ defaultView }`;

// Soketi
export const websocketHost = getEnvValue("REACT_APP_WEBSOCKETS_HOST");
export const websocketPort = +getEnvValue("REACT_APP_WEBSOCKETS_PORT");
export const isWebsocketSecure = getEnvValue("REACT_APP_WEBSOCKETS_SECURE") === "true";

export const DEFAULT_PAGINATION_PAGE_INDEX = 0;
export const DEFAULT_PAGINATION_PAGE_SIZE = 10;

// Sidebar
export const sidebarItems: (loggedUser: LoggedUser, organizationId: string) => SidebarItem[] = (loggedUser, organizationId) => {
	const urlPrefix = `/organization/${ organizationId }`;
	return [
		{
			icon: RiSurveyFill,
			title: "Badania",
			path: `${ urlPrefix }/evaluations`,
		}, {
			icon: HiUserCircle,
			title: "Użytkownicy",
			path: `${ urlPrefix }/users`,
			hidden: !canLoggedUserListUsers(loggedUser),
		},
	];
};

// Select options
export const evaluationLanguageOptions = mapEnumToSelectOptions<EvaluationLanguage>(EvaluationLanguage, evaluationLanguageDictionary);
export const evaluationScheduledStartTypeOptions = mapEnumToSelectOptions<EvaluationScheduledStartType>(EvaluationScheduledStartType, evaluationScheduledStartTypeDictionary);

export const participantTypeOptions = mapEnumToSelectOptions<ParticipantType>(ParticipantType, participantTypeDictionary);
export const participantLanguageOptions = mapEnumToSelectOptions<SurveyLanguage>(SurveyLanguage, surveyLanguageDictionary);
