import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import CachedThenFreshStrategy from "src/app/hoc/caching/CachedThenFreshStrategy.hoc";
import { fetchOrganizationsAsync } from "src/app/store/features/organization/organization.actions";
import OrganizationListContainer from "src/app/containers/Organization/OrganizationList.container";
import { uiDeleteOrganization } from "src/app/store/features/ui/organization/ui.organization.actions";

type Props =
    ReturnType<typeof mapStateToProps>
    & typeof mapDispatchToProps;

function OrganizationListView(props: Props) {
    const {
        organizations,
        fetchOrganizations,
        deleteOrganization,
    } = props;

    return (
        <CachedThenFreshStrategy
            request={ fetchOrganizations }
            state={ organizations }
        >
            {
                organizations =>
                    <OrganizationListContainer
                        organizations={ organizations }
                    />
            }
        </CachedThenFreshStrategy>
    );
}

const mapStateToProps = (state: RootState) => ({
    organizations: state.organization.organizations,
});

const mapDispatchToProps = {
    fetchOrganizations: fetchOrganizationsAsync.request,
    deleteOrganization: uiDeleteOrganization,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationListView);