import { combineReducers } from "redux";
import { createFormReducer } from "src/app/utils/redux";
import { loginFormInitialState, loginFormValidator } from "src/app/utils/constants/login.form";
import { loginFormActions, surveyActions } from "src/app/store/features/form/form.actions";
import { surveyInitialState, surveyValidator } from "src/app/utils/constants/survey";

const reducer = combineReducers({
	login: createFormReducer(loginFormInitialState, loginFormActions, loginFormValidator),
	survey: createFormReducer(surveyInitialState, surveyActions, surveyValidator),
});

export default reducer;
