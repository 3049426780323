import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import { BodySize } from "src/app/types/ui/layout.types";
import { setBodySize, setBreadcrumbHeight, setIsDarkTheme, toggleSidebar } from "src/app/store/features/ui/layout/ui.layout.actions";
import { forgetSession } from "src/app/store/features/user/user.actions";

const reducer = combineReducers({
	bodySize: createReducer({ width: 0, height: 0 } as BodySize)
		.handleAction([ setBodySize ], (_, action) => action.payload),
	breadcrumbHeight: createReducer(61)
		.handleAction([ setBreadcrumbHeight ], (_, action) => action.payload),
	isSidebarOpen: createReducer(true)
		.handleAction([ toggleSidebar ], (_, action) => action.payload)
		.handleAction([ forgetSession ], () => true),
	isDarkTheme: createReducer(false)
		.handleAction([ setIsDarkTheme ], (_, action) => action.payload),
});

export default reducer;
