/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { createNetworkAction } from "src/app/utils/redux";
import { FailurePayloadWithId, SuccessPayload, SuccessPayloadWithId } from "src/app/types/api/api.types";
import { Organization, DetailedOrganization } from "src/app/types/api/organization.types";
import { CreateOrganizationPayload, UpdateOrganizationPayload } from "src/app/types/api/organization.types";

export const fetchOrganizationsAsync = createNetworkAction<undefined, SuccessPayload<Organization[]>>("ORGANIZATION__FETCH_ORGANIZATIONS");
export const fetchOrganizationByIdAsync = createNetworkAction<string, SuccessPayloadWithId<DetailedOrganization>, FailurePayloadWithId>("ORGANIZATION__FETCH_ORGANIZATION_BY_ID");
export const createOrganizationAsync = createNetworkAction<CreateOrganizationPayload, SuccessPayload<DetailedOrganization>>("ORGANIZATION__CREATE_ORGANIZATION");
export const updateOrganizationAsync = createNetworkAction<UpdateOrganizationPayload, SuccessPayload<DetailedOrganization>>("ORGANIZATION__UPDATE_ORGANIZATION");
export const deleteOrganizationByIdAsync = createNetworkAction<string, SuccessPayload<{ id: string }>>("ORGANIZATION__DELETE_ORGANIZATION");
