import { ReactComponent as CsvIcon } from "src/assets/icons/file/csv.svg";
import { ReactComponent as DocIcon } from "src/assets/icons/file/doc.svg";
import { ReactComponent as FileIcon } from "src/assets/icons/file/file.svg";
import { ReactComponent as JpgIcon } from "src/assets/icons/file/jpg.svg";
import { ReactComponent as PngIcon } from "src/assets/icons/file/png.svg";
import { ReactComponent as PptIcon } from "src/assets/icons/file/ppt.svg";
import { ReactComponent as TxtIcon } from "src/assets/icons/file/txt.svg";
import { ReactComponent as XlsIcon } from "src/assets/icons/file/xls.svg";
import { ReactComponent as ZipIcon } from "src/assets/icons/file/zip.svg";
import { ReactComponent as PdfIcon } from "src/assets/icons/file/pdf.svg";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { Nullable } from "src/app/types/util.types";
import classNames from "classnames";
import { MoonLoader } from "react-spinners";
import { Link } from "react-router-dom";
import { HiXCircle } from "react-icons/hi";

type Props = {
	path: Nullable<string>
	isUploading?: boolean
	withoutDelete?: boolean
	link?: string
	onDelete?: () => void
}

function FilePreview(props: Props) {

	const {
		path,
		onDelete,
		link,
		withoutDelete = false,
		isUploading = false,
	} = props;

	const _getFileIcon = () => {
		const iconClassName = "w-8 h-8 text-gray-400";
		if (isNull(path)) return <FileIcon className={ iconClassName }/>;

		if (path.endsWith("csv")) {
			return <CsvIcon className={ iconClassName }/>;
		} else if (path.endsWith("doc") || path.endsWith("docx")) {
			return <DocIcon className={ iconClassName }/>;
		} else if (path.endsWith("jpg") || path.endsWith("jpeg")) {
			return <JpgIcon className={ iconClassName }/>;
		} else if (path.endsWith("png")) {
			return <PngIcon className={ iconClassName }/>;
		} else if (path.endsWith("ppt") || path.endsWith("pptx")) {
			return <PptIcon className={ iconClassName }/>;
		} else if (path.endsWith("txt")) {
			return <TxtIcon className={ iconClassName }/>;
		} else if (path.endsWith("xls") || path.endsWith("xlsx")) {
			return <XlsIcon className={ iconClassName }/>;
		} else if (path.endsWith("zip") || path.endsWith("rar")) {
			return <ZipIcon className={ iconClassName }/>;
		} else if (path.endsWith("pdf")) {
			return <PdfIcon className={ iconClassName }/>;
		} else {
			return <FileIcon className={ iconClassName }/>;
		}
	};

	return (
		<div
			className={
				classNames(
					"bg-transparent border border-gray-200 dark:border-gray-700 pt-1 pl-3 pb-1 flex gap-2 items-center rounded-3xl relative w-min",
					{ "before:absolute before:content-[''] before:bg-white dark:before:bg-gray-600 before:opacity-50 before:w-full before:h-full before:rounded-3xl before:left-0 before:right-0 z-10": isUploading },
					{ "pr-3": withoutDelete },
					{ "pr-1": !withoutDelete },
				)
			}
		>
			<div className="flex-grow">
				{ _getFileIcon() }
			</div>
			{
				isNotNull(link)
					?
					<Link className="truncate text-xs dark:text-white" target="_blank" to={ { pathname: link } }>
						{ path }
					</Link>
					:
					<span className="truncate max-w-lg text-xs dark:text-white">{ path }</span>
			}
			{
				(!withoutDelete && onDelete) &&
                <HiXCircle
                    className="w-6 h-6 text-red-600 cursor-pointer hover:text-red-500 transition duration-50 ease-in-out"
                    onClick={ () => !isUploading && onDelete() }
                />
			}
			{
				isUploading &&
                <div className="flex absolute top-1/2 left-1/2 z-50 -translate-x-1/2 -translate-y-1/2">
                    <MoonLoader size={ 15 } color="#fed054"/>
                </div>
			}
		</div>
	);
}

export default (FilePreview);
