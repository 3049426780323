/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { RootEpic } from "src/app/store/root.epic";
import { isActionOf } from "typesafe-actions";
import { uiCreateExternalParticipant, uiCreateParticipant, uiDeleteExternalParticipant, uiDeleteParticipant, uiExternalImportParticipants, uiExternalSendReminderToParticipants, uiExternalSendReminderToSubject, uiImportParticipants, uiSendReminderToParticipants, uiSendReminderToSubject, uiUpdateExternalParticipant, uiUpdateParticipant } from "src/app/store/features/ui/participant/ui.participant.actions";
import { filter, mergeMap, switchMap, take } from "rxjs/operators";
import { concat, merge, of } from "rxjs";
import { addLoadingRecord, removeLoadingRecord } from "src/app/store/features/ui/loading/ui.loading.actions";
import { LoadableType, LoadingRecord } from "src/app/types/ui/loading.types";
import { createExternalParticipantAsync, createParticipantAsync, deleteExternalParticipantByIdAsync, deleteParticipantByIdAsync, externalImportParticipantsAsync, externalSendReminderToParticipantsAsync, importParticipantsAsync, sendReminderToParticipantsAsync, updateExternalParticipantAsync, updateParticipantAsync } from "src/app/store/features/participant/participant.actions";
import { displayToast } from "src/app/store/features/message/message.actions";
import { ToastType } from "src/app/types/ui/message.types";
import { ParticipantType } from "src/app/types/api/participant.types";

export const uiCreateParticipantEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiCreateParticipant)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableType: LoadableType.CREATE_PARTICIPANT };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(createParticipantAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(createParticipantAsync.success, action) || isActionOf(createParticipantAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(createParticipantAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie dodano oceniającego!" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiUpdateParticipantEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiUpdateParticipant)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload.id, loadableType: LoadableType.UPDATE_PARTICIPANT };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(updateParticipantAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(updateParticipantAsync.success, action) || isActionOf(updateParticipantAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(updateParticipantAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								responseAction.payload.data.participantType === ParticipantType.SUBJECT ?
									of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie zaktualizowano osobe badana!" }))
									:
									of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie zaktualizowano oceniającego!" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiDeleteParticipantEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiDeleteParticipant)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload.id, loadableType: LoadableType.DELETE_PARTICIPANT };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(deleteParticipantByIdAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(deleteParticipantByIdAsync.success, action) || isActionOf(deleteParticipantByIdAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(deleteParticipantByIdAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie usunięto oceniającego!" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiSendReminderToSubjectEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiSendReminderToSubject)),
		switchMap(action =>
			concat(
				of(addLoadingRecord({ loadableType: LoadableType.SEND_REMINDER_TO_SUBJECT, loadableId: action.payload.id })),
				of(sendReminderToParticipantsAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(sendReminderToParticipantsAsync.success, action) || isActionOf(sendReminderToParticipantsAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(sendReminderToParticipantsAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord({ loadableType: LoadableType.SEND_REMINDER_TO_SUBJECT, loadableId: action.payload.id })),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie wysłano przypomnienie do osoby badanej!" })),
							);
						} else {
							return of(removeLoadingRecord({ loadableType: LoadableType.SEND_REMINDER_TO_SUBJECT, loadableId: action.payload.id }));
						}
					}),
				),
			),
		),
	);

export const uiSendReminderToParticipantsEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiSendReminderToParticipants)),
		switchMap(action =>
			concat(
				of(addLoadingRecord({ loadableType: LoadableType.SEND_REMINDER_TO_PARTICIPANTS, loadableId: action.payload.id })),
				of(sendReminderToParticipantsAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(sendReminderToParticipantsAsync.success, action) || isActionOf(sendReminderToParticipantsAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(sendReminderToParticipantsAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord({ loadableType: LoadableType.SEND_REMINDER_TO_PARTICIPANTS, loadableId: action.payload.id })),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie wysłano przypomnienia do uczestników badania!" })),
							);
						} else {
							return of(removeLoadingRecord({ loadableType: LoadableType.SEND_REMINDER_TO_PARTICIPANTS, loadableId: action.payload.id }));
						}
					}),
				),
			),
		),
	);

export const uiImportParticipantsEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiImportParticipants)),
		switchMap(action =>
			concat(
				of(addLoadingRecord({ loadableType: LoadableType.IMPORT_PARTICIPANTS, loadableId: action.payload.id })),
				of(importParticipantsAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(importParticipantsAsync.success, action) || isActionOf(importParticipantsAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(importParticipantsAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord({ loadableType: LoadableType.IMPORT_PARTICIPANTS, loadableId: action.payload.id })),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie zaimportowano uczestników badania!" })),
							);
						} else {
							return of(removeLoadingRecord({ loadableType: LoadableType.IMPORT_PARTICIPANTS, loadableId: action.payload.id }));
						}
					}),
				),
			),
		),
	);

export const uiCreateExternalParticipantEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiCreateExternalParticipant)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableType: LoadableType.CREATE_PARTICIPANT };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(createExternalParticipantAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(createExternalParticipantAsync.success, action) || isActionOf(createExternalParticipantAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(createExternalParticipantAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie dodano oceniającego!" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiUpdateExternalParticipantEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiUpdateExternalParticipant)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload.id, loadableType: LoadableType.UPDATE_PARTICIPANT };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(updateExternalParticipantAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(updateExternalParticipantAsync.success, action) || isActionOf(updateExternalParticipantAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(updateExternalParticipantAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								responseAction.payload.data.participantType === ParticipantType.SUBJECT ?
									of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie zaktualizowano osobe badana!" }))
									:
									of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie zaktualizowano oceniającego!" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiDeleteExternalParticipantEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiDeleteExternalParticipant)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload.id, loadableType: LoadableType.DELETE_PARTICIPANT };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(deleteExternalParticipantByIdAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(deleteExternalParticipantByIdAsync.success, action) || isActionOf(deleteExternalParticipantByIdAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(deleteExternalParticipantByIdAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie usunięto oceniającego!" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiExternalSendReminderToSubjectEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiExternalSendReminderToSubject)),
		switchMap(action =>
			concat(
				of(addLoadingRecord({ loadableType: LoadableType.SEND_REMINDER_TO_SUBJECT, loadableId: action.payload.id })),
				of(externalSendReminderToParticipantsAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(externalSendReminderToParticipantsAsync.success, action) || isActionOf(externalSendReminderToParticipantsAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(externalSendReminderToParticipantsAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord({ loadableType: LoadableType.SEND_REMINDER_TO_SUBJECT, loadableId: action.payload.id })),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie wysłano przypomnienie do osoby badanej!" })),
							);
						} else {
							return of(removeLoadingRecord({ loadableType: LoadableType.SEND_REMINDER_TO_SUBJECT, loadableId: action.payload.id }));
						}
					}),
				),
			),
		),
	);

export const uiExternalSendReminderToParticipantsEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiExternalSendReminderToParticipants)),
		switchMap(action =>
			concat(
				of(addLoadingRecord({ loadableType: LoadableType.SEND_REMINDER_TO_PARTICIPANTS, loadableId: action.payload.id })),
				of(externalSendReminderToParticipantsAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(externalSendReminderToParticipantsAsync.success, action) || isActionOf(externalSendReminderToParticipantsAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(externalSendReminderToParticipantsAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord({ loadableType: LoadableType.SEND_REMINDER_TO_PARTICIPANTS, loadableId: action.payload.id })),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie wysłano przypomnienia do uczestników badania!" })),
							);
						} else {
							return of(removeLoadingRecord({ loadableType: LoadableType.SEND_REMINDER_TO_PARTICIPANTS, loadableId: action.payload.id }));
						}
					}),
				),
			),
		),
	);

export const uiExternalImportParticipantsEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiExternalImportParticipants)),
		switchMap(action =>
			concat(
				of(addLoadingRecord({ loadableType: LoadableType.IMPORT_PARTICIPANTS, loadableId: action.payload.id })),
				of(externalImportParticipantsAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(externalImportParticipantsAsync.success, action) || isActionOf(externalImportParticipantsAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(externalImportParticipantsAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord({ loadableType: LoadableType.IMPORT_PARTICIPANTS, loadableId: action.payload.id })),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie zaimportowano uczestników badania!" })),
							);
						} else {
							return of(removeLoadingRecord({ loadableType: LoadableType.IMPORT_PARTICIPANTS, loadableId: action.payload.id }));
						}
					}),
				),
			),
		),
	);
