/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { filter, map } from "rxjs/operators";
import { apiAsync } from "src/app/store/features/api/api.actions";
import { isActionOf } from "typesafe-actions";
import { RootEpic } from "src/app/store/root.epic";
import { createExternalParticipantAsync, createParticipantAsync, deleteExternalParticipantByIdAsync, deleteParticipantByIdAsync, externalImportParticipantsAsync, externalSendReminderToParticipantsAsync, importParticipantsAsync, sendReminderToParticipantsAsync, updateExternalParticipantAsync, updateParticipantAsync } from "src/app/store/features/participant/participant.actions";
import { SuccessPayload } from "src/app/types/api/api.types";

export const createParticipantEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(createParticipantAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/evaluations/${ action.payload.evaluationId }/participants`,
				method: "POST",
				data: action.payload,
				withScope: true,
				onSuccess: createParticipantAsync.success,
				onFailure: createParticipantAsync.failure,
			}),
		),
	);

export const updateParticipantEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(updateParticipantAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/evaluations/${ action.payload.evaluationId }/participants/${ action.payload.id }`,
				method: "PUT",
				data: action.payload,
				withScope: true,
				onSuccess: updateParticipantAsync.success,
				onFailure: updateParticipantAsync.failure,
			}),
		),
	);

export const deleteParticipantByIdEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(deleteParticipantByIdAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/evaluations/${ action.payload.evaluationId }/participants/${ action.payload.id }`,
				method: "DELETE",
				withScope: true,
				onSuccess: (payload: SuccessPayload<{ id: number }>) => deleteParticipantByIdAsync.success({ ...payload, id: action.payload.evaluationId }),
				onFailure: deleteParticipantByIdAsync.failure,
			}),
		),
	);

export const sendReminderToParticipantsEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(sendReminderToParticipantsAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/evaluations/${ action.payload.id }/reminder`,
				method: "POST",
				data: action.payload,
				withScope: true,
				onSuccess: sendReminderToParticipantsAsync.success,
				onFailure: sendReminderToParticipantsAsync.failure,
			}),
		),
	);

export const importParticipantsEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(importParticipantsAsync.request)),
		map(action => {
			const formData = new FormData();
			formData.append("file", action.payload.file);
			return apiAsync.request({
				url: `/evaluations/${ action.payload.id }/import-participants`,
				method: "POST",
				data: formData,
				withScope: true,
				onSuccess: importParticipantsAsync.success,
				onFailure: importParticipantsAsync.failure,
			});
		}),
	);

export const createExternalParticipantEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(createExternalParticipantAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/external/evaluations/${ action.payload.evaluationId }/participants`,
				method: "POST",
				data: action.payload,
				withScope: false,
				headers: {
					ExternalEmail: action.payload.email,
					ExternalToken: action.payload.token,
				},
				onSuccess: createExternalParticipantAsync.success,
				onFailure: createExternalParticipantAsync.failure,
			}),
		),
	);

export const updateExternalParticipantEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(updateExternalParticipantAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/external/evaluations/${ action.payload.evaluationId }/participants/${ action.payload.id }`,
				method: "PUT",
				data: action.payload,
				withScope: false,
				headers: {
					ExternalEmail: action.payload.email,
					ExternalToken: action.payload.token,
				},
				onSuccess: updateExternalParticipantAsync.success,
				onFailure: updateExternalParticipantAsync.failure,
			}),
		),
	);

export const deleteExternalParticipantByIdEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(deleteExternalParticipantByIdAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/external/evaluations/${ action.payload.evaluationId }/participants/${ action.payload.id }`,
				method: "DELETE",
				withScope: false,
				headers: {
					ExternalEmail: action.payload.email,
					ExternalToken: action.payload.token,
				},
				onSuccess: (payload: SuccessPayload<{ id: number }>) => deleteExternalParticipantByIdAsync.success({ ...payload, id: action.payload.evaluationId }),
				onFailure: deleteExternalParticipantByIdAsync.failure,
			}),
		),
	);

export const externalSendReminderToParticipantsEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(externalSendReminderToParticipantsAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/external/evaluations/${ action.payload.id }/reminder`,
				method: "POST",
				data: action.payload,
				withScope: false,
				headers: {
					ExternalEmail: action.payload.email,
					ExternalToken: action.payload.token,
				},
				onSuccess: externalSendReminderToParticipantsAsync.success,
				onFailure: externalSendReminderToParticipantsAsync.failure,
			}),
		),
	);

export const externalImportParticipantsEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(externalImportParticipantsAsync.request)),
		map(action => {
			const formData = new FormData();
			formData.append("file", action.payload.file);
			return apiAsync.request({
				url: `/external/evaluations/${ action.payload.id }/import-participants`,
				method: "POST",
				data: formData,
				withScope: false,
				headers: {
					ExternalEmail: action.payload.email,
					ExternalToken: action.payload.token,
				},
				onSuccess: externalImportParticipantsAsync.success,
				onFailure: externalImportParticipantsAsync.failure,
			});
		}),
	);

