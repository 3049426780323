import { DetailedOrganization } from "src/app/types/api/organization.types";

type Props = {
    organization: DetailedOrganization
};

function OrganizationProfileContainer(props: Props) {
    const {
        organization,
    } = props;

    return (
        <div>My ID: { organization.id }</div>
    );
}

export default (OrganizationProfileContainer);