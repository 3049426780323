import { RootEpic } from "src/app/store/root.epic";
import { isActionOf } from "typesafe-actions";
import { fetchExternalSurveyAsync, fetchSurveyAsync, saveExternalSurveyAsync, saveSurveyAsync, submitExternalSurveyAsync, submitSurveyAsync } from "src/app/store/features/survey/survey.actions";
import { apiAsync } from "src/app/store/features/api/api.actions";
import { filter, map } from "rxjs/operators";
import { FailurePayload, SuccessPayload } from "src/app/types/api/api.types";
import { DetailedSurvey, ExternalSurvey } from "src/app/types/api/survey.types";

export const fetchExternalSurveyEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchExternalSurveyAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/external/surveys/${ action.payload.surveyId }`,
				method: "GET",
				withScope: false,
				headers: {
					ExternalEmail: action.payload.email,
					ExternalToken: action.payload.token,
				},
				withoutNotification: true,
				onSuccess: (payload: SuccessPayload<ExternalSurvey>) => fetchExternalSurveyAsync.success({ ...payload, id: action.payload.surveyId }),
				onFailure: (payload: FailurePayload) => fetchExternalSurveyAsync.failure({ ...payload, id: action.payload.surveyId }),
			}),
		),
	);

export const saveExternalSurveyEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(saveExternalSurveyAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/external/surveys/${ action.payload.id }/save`,
				method: "POST",
				withScope: false,
				data: action.payload,
				headers: {
					ExternalEmail: action.payload.email,
					ExternalToken: action.payload.token,
				},
				onSuccess: saveExternalSurveyAsync.success,
				onFailure: saveExternalSurveyAsync.failure,
			}),
		),
	);

export const submitExternalSurveyEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(submitExternalSurveyAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/external/surveys/${ action.payload.id }/submit`,
				method: "POST",
				withScope: false,
				data: action.payload,
				headers: {
					ExternalEmail: action.payload.email,
					ExternalToken: action.payload.token,
				},
				onSuccess: submitExternalSurveyAsync.success,
				onFailure: submitExternalSurveyAsync.failure,
			}),
		),
	);

export const fetchSurveyEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchSurveyAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/surveys/${ action.payload }`,
				method: "GET",
				withScope: true,
				onSuccess: (payload: SuccessPayload<DetailedSurvey>) => fetchSurveyAsync.success({ id: action.payload, ...payload }),
				onFailure: (payload: FailurePayload) => fetchSurveyAsync.failure({ id: action.payload, ...payload }),
			}),
		),
	);

export const saveSurveyEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(saveSurveyAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/surveys/${ action.payload.surveyId }/save`,
				method: "POST",
				withScope: true,
				data: action.payload,
				onSuccess: saveSurveyAsync.success,
				onFailure: saveSurveyAsync.failure,
			}),
		),
	);

export const submitSurveyEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(submitSurveyAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/surveys/${ action.payload.id }/submit`,
				method: "POST",
				withScope: true,
				data: action.payload,
				onSuccess: submitSurveyAsync.success,
				onFailure: submitSurveyAsync.failure,
			}),
		),
	);
