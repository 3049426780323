import { RouteObject } from "react-router/dist/lib/context";
import EvaluationListView from "src/app/views/Evaluation/EvaluationList.view";
import EvaluationProfileView from "src/app/views/Evaluation/EvaluationProfile.view";
import EvaluationCreateView from "src/app/views/Evaluation/EvaluationCreate.view";
import EvaluationParticipantSurveyView from "src/app/views/Evaluation/EvaluationParticipantSurvey.view";

const evaluationRoutes: RouteObject = {
	path: "evaluations",
	children: [
		{
			index: true,
			element: <EvaluationListView/>,
		}, {
			path: "create",
			element: <EvaluationCreateView/>,
		}, {
			path: ":evaluationId",
			element: <EvaluationProfileView/>,
		}, {
			path: ":evaluationId/surveys/:surveyId",
			element: <EvaluationParticipantSurveyView/>,
		},
	],
};

export default evaluationRoutes;
