import TextArea from "src/app/components/Form/TextArea.component";
import { QuestionForm, TextQuestionForm } from "src/app/types/ui/survey.types";
import { isNotNull, isTextSurveyFormQuestion } from "src/app/utils/typeguards";
import { Form } from "src/app/types/ui/form.types";
import { validateForm } from "src/app/utils/forms";
import { Alert } from "flowbite-react";
import { MdErrorOutline } from "react-icons/md";
import { englishQuestionValidator, questionValidator } from "src/app/utils/constants/survey";
import { SurveyLanguage } from "src/app/types/api/participant.types";

type Props = {
	question: Form<TextQuestionForm>
	form: Form<{ questions: Form<QuestionForm>[], language: SurveyLanguage }>
	handleChange: (prop: "questions", value: any) => void
};

function TextSurveyQuestion(props: Props) {

	const {
		question,
		form,
		handleChange,
	} = props;

	const _handleChange = (value: string) => {
		handleChange(
			"questions",
			form.questions.value.map(formQuestion => {
				if (formQuestion.id.value !== question.id.value) return formQuestion;

				if (!isTextSurveyFormQuestion(formQuestion)) return formQuestion;

				return {
					...formQuestion,
					answerTextValue: {
						...formQuestion.answerTextValue,
						value,
						touched: true,
					},
				};
			}),
		);
	};

	const _handleBlur = () => {
		handleChange(
			"questions",
			form.questions.value.map(formQuestion => {
				if (formQuestion.id.value !== question.id.value) return formQuestion;

				if (!isTextSurveyFormQuestion(formQuestion)) return formQuestion;

				if (!formQuestion.answerTextValue.touched) return formQuestion;

				return validateForm(formQuestion, form.language.value === SurveyLanguage.POLISH ? questionValidator : englishQuestionValidator);
			}),
		);
	};

	return (
		<div id={ `question-${ question.id.value }` } className="w-[768px] border border-gray-200 pt-20 pb-16 px-11 bg-white shadow-sm flex flex-col gap-8">
			<div className="flex flex-col items-center">
				<p className="font-semibold text-sm text-primary-800">{ form.language.value === SurveyLanguage.POLISH ? "Pytanie otwarte" : "Open question" }</p>
				<p className="font-semibold text-lg">{ question.title.value }</p>
			</div>
			<div className="flex flex-col gap-2">
				<TextArea
					formItem={ question.answerTextValue }
					label=""
					name={ `question-answer-${ question.id.value }` }
					inputProps={ {
						onChange: e => _handleChange(e.target.value),
						onBlur: _handleBlur,
						placeholder: form.language.value === SurveyLanguage.POLISH ? "Odpowiedz na pytanie..." : "Answer to the question",
						rows: 5,
						className: "min-h-[42px] max-h-60",
					} }
					displayErrorMessage={ false }
				/>
				{
					isNotNull(question.answerTextValue.error) &&
                    <Alert
                        color="failure"
                        icon={ MdErrorOutline }
                    >
						{ question.answerTextValue.error }
                    </Alert>
				}
			</div>
		</div>
	);
}

export default (TextSurveyQuestion);
