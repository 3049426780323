import { DetailedEvaluation, EvaluationLanguage, EvaluationScheduledStartType, UpdateEvaluationPayload } from "src/app/types/api/evaluation.types";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import { Nullable } from "src/app/types/util.types";
import { FormValidator } from "src/app/types/ui/form.types";
import { SimpleUser } from "src/app/types/api/user.types";
import { createFormField, validateEmail, validateField, validateFutureDate, validateNullableField, validatePhone } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import { isEmptyString, isNotNull, isNull } from "src/app/utils/typeguards";
import { DateTime } from "luxon";
import { formatPhoneToE164 } from "src/app/utils/helpers";
import { Button, Card, Modal } from "flowbite-react";
import Input from "src/app/components/Form/Input.component";
import Select from "src/app/components/Form/Select.component";
import { evaluationLanguageOptions, evaluationScheduledStartTypeOptions } from "src/app/utils/constants/constants";
import DatePicker from "src/app/components/Form/DatePicker.component";
import TextArea from "src/app/components/Form/TextArea.component";
import UserSelect from "src/app/components/Form/UserSelect.component";
import PhoneInput from "src/app/components/Form/PhoneInput.component";
import { useEffect } from "react";

type ComponentProps = {
	isOpen: boolean
	handleClose: () => void
	evaluation: DetailedEvaluation
	onEdit: (payload: UpdateEvaluationPayload) => void
};

type Props =
	ReturnType<typeof mapStateToProps>
	& ComponentProps;

type EditEvaluationForm = {
	coordinatorUser: SimpleUser
	name: string
	companyName: string
	evaluationLanguage: EvaluationLanguage
	evaluationScheduledStartType: EvaluationScheduledStartType
	evaluationScheduledStart: Nullable<Date>

	note: string

	representativeName: string
	representativePosition: string
	representativeEmail: string
	representativePhone: string
	representativeFormattedPhone: string
}

const validator: FormValidator<EditEvaluationForm> = {
	coordinatorUser: (coordinatorUser, optional) => validateNullableField(coordinatorUser, "Osoba prowadząca jest wymagana", optional),

	name: (name, optional) => validateField(name, "Nazwa jest wymagana", optional),
	companyName: (companyName, optional) => validateField(companyName, "Nazwa firmy jest wymagana", optional),
	evaluationLanguage: (evaluationLanguage, optional) => validateNullableField(evaluationLanguage, "Język badania jest wymagany", optional),
	evaluationScheduledStartType: () => null,
	evaluationScheduledStart: (evaluationScheduledStart, optional, form) => {
		if (form.evaluationScheduledStartType.value !== EvaluationScheduledStartType.SCHEDULED_AT) return null;

		return validateFutureDate(evaluationScheduledStart, "Data startu jest wymagana", "Data jest niepoprawna", "Data jest przeszła", optional);
	},

	note: (note, optional) => validateField(note, "Notatka jest wymagana", optional),

	representativeName: (representativeName, optional) => validateField(representativeName, "Imię i nazwisko są wymagane", optional),
	representativePosition: (representativePosition, optional) => validateField(representativePosition, "Stanowisko jest wymagane", optional),
	representativeEmail: validateEmail,
	representativePhone: validatePhone,
	representativeFormattedPhone: () => null,
};

function EditEvaluationModal(props: Props) {

	const {
		isOpen,
		handleClose,
		evaluation,
		onEdit,
		isUpdating,
	} = props;

	const _handleSubmit = (values: EditEvaluationForm) => {
		onEdit({
			id: evaluation.id,
			coordinatorUserId: values.coordinatorUser.id,
			name: isEmptyString(values.name) ? null : values.name,
			companyName: values.companyName,
			evaluationLanguage: values.evaluationLanguage,
			evaluationScheduledStart: (values.evaluationScheduledStartType === EvaluationScheduledStartType.SCHEDULED_AT && isNotNull(values.evaluationScheduledStart)) ? values.evaluationScheduledStart.toISOString() : null,
			representativeName: values.representativeName,
			representativePosition: values.representativePosition,
			representativeEmail: values.representativeEmail,
			representativePhone: values.representativeFormattedPhone,
			note: values.note,
		});
		handleClose();
	};

	const _getInitialState = () => ({
		coordinatorUser: createFormField(evaluation.coordinatorUser),
		name: createFormField(evaluation.name, { optional: true }),
		companyName: createFormField(evaluation.companyName),
		evaluationLanguage: createFormField(evaluation.evaluationLanguage),
		evaluationScheduledStartType: createFormField(isNull(evaluation.evaluationScheduledStart) ? EvaluationScheduledStartType.MANUAL : EvaluationScheduledStartType.SCHEDULED_AT),
		evaluationScheduledStart: createFormField(isNull(evaluation.evaluationScheduledStart) ? null : DateTime.fromISO(evaluation.evaluationScheduledStart).toJSDate(), { disabled: isNull(evaluation.evaluationScheduledStart) }),

		note: createFormField(evaluation.note ?? "", { optional: true }),

		representativeName: createFormField(evaluation.representativeName),
		representativePosition: createFormField(evaluation.representativePosition ?? "", { optional: true }),
		representativeEmail: createFormField(evaluation.representativeEmail),
		representativePhone: createFormField(formatPhoneToE164(evaluation.representativePhone), { optional: true }),
		representativeFormattedPhone: createFormField(evaluation.representativePhone),
	});

	const {
		form,
		handleChange,
		handleBlur,
		toggleDisable,
		handleSubmit,
		setForm,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ evaluation ]);

	useEffect(() => {
		if (!isOpen && !isUpdating) {
			setForm(_getInitialState());
		}
	}, [ isOpen, isUpdating ]);

	return (
		<Modal
			show={ isOpen || isUpdating }
			onClose={ handleClose }
			size="7xl"
			root={ document.body }
			key={ (isOpen || isUpdating) ? "open" : "hidden" } // AutoFocus on input work with this
		>
			<Modal.Header>
				{ `Edytuj badanie: ${ evaluation.name }` }
			</Modal.Header>
			<form onSubmit={ handleSubmit }>
				<Modal.Body className="overflow-visible">
					<div className="grid grid-cols-12 gap-4">
						<div className="col-span-7">
							<Card>
								<Input
									formItem={ form.name }
									label="Nazwa badania (opcjonalnie)"
									name="name"
									inputProps={ {
										type: "text",
										onChange: (e) => handleChange("name", e.target.value),
										onBlur: () => handleBlur("name"),
										helperText: <>Pozostaw puste aby automatycznie wygenerować nazwę</>,
									} }
								/>
								<Input
									formItem={ form.companyName }
									label="Nazwa firmy"
									name="companyName"
									inputProps={ {
										type: "text",
										onChange: (e) => handleChange("companyName", e.target.value),
										onBlur: () => handleBlur("companyName"),
									} }
								/>
								<Select
									label="Język badania"
									options={ evaluationLanguageOptions }
									formItem={ form.evaluationLanguage }
									isClearable={ false }
									isSearchable={ false }
									onChange={ option => {
										handleChange("evaluationLanguage", option?.value ?? null);
										handleBlur("evaluationLanguage");
									} }
								/>
								<div className="flex gap-4 justify-between">
									<Select
										className="flex-1"
										label="Start badania"
										options={ evaluationScheduledStartTypeOptions }
										formItem={ form.evaluationScheduledStartType }
										isClearable={ false }
										isSearchable={ false }
										onChange={ option => {
											handleChange("evaluationScheduledStartType", option?.value ?? null);
											handleBlur("evaluationScheduledStartType");

											if (option?.value !== EvaluationScheduledStartType.SCHEDULED_AT) handleChange("evaluationScheduledStart", null);

											toggleDisable("evaluationScheduledStart", option?.value !== EvaluationScheduledStartType.SCHEDULED_AT);
										} }
									/>
									<DatePicker
										className="flex-1"
										label="Data startu badania"
										formItem={ form.evaluationScheduledStart }
										datepickerProps={ {
											minDate: DateTime.now().toJSDate(),
										} }
										onChange={ date => handleChange("evaluationScheduledStart", date) }
										onBlur={ () => handleBlur("evaluationScheduledStart") }
									/>
								</div>
								<TextArea
									formItem={ form.note }
									label="Notatka"
									name="note"
									inputProps={ {
										onChange: (e) => handleChange("note", e.target.value),
										onBlur: () => handleBlur("note"),
										rows: 5,
										className: "min-h-[42px] max-h-60",
									} }
								/>
							</Card>
						</div>
						<div className="col-span-5 flex flex-col gap-4">
							<Card>
								<h2 className="font-bold mb-6">
									Project Manager
								</h2>
								<UserSelect
									formItem={ form.coordinatorUser }
									onChange={ user => {
										handleChange("coordinatorUser", user);
										handleBlur("coordinatorUser");
									} }
								/>
							</Card>
							<Card>
								<h2 className="font-bold mb-6">
									Reprezentant firmy klienta
								</h2>
								<div className="flex gap-4 justify-between">
									<Input
										className="flex-1"
										formItem={ form.representativeName }
										label="Imię i nazwisko reprezentanta"
										name="representativeName"
										inputProps={ {
											type: "text",
											onChange: (e) => handleChange("representativeName", e.target.value),
											onBlur: () => handleBlur("representativeName"),
										} }
									/>
									<Input
										className="flex-1"
										formItem={ form.representativePosition }
										label="Stanowisko (opcjonalnie)"
										name="representativePosition"
										inputProps={ {
											type: "text",
											onChange: (e) => handleChange("representativePosition", e.target.value),
											onBlur: () => handleBlur("representativePosition"),
										} }
									/>
								</div>
								<div className="flex gap-4 justify-between">
									<Input
										className="flex-1"
										formItem={ form.representativeEmail }
										label="Adres e-mail"
										name="representativeEmail"
										inputProps={ {
											type: "email",
											onChange: (e) => handleChange("representativeEmail", e.target.value),
											onBlur: () => handleBlur("representativeEmail"),
										} }
									/>
									<PhoneInput
										className="flex-1"
										label="Telefon kontaktowy"
										name="phone"
										phoneInputProps={ {
											country: "pl",
											onEnterKeyPress: () => handleSubmit(),
										} }
										formItem={ form.representativePhone }
										onChange={ (phone, formattedPhone) => {
											handleChange("representativePhone", phone);
											handleChange("representativeFormattedPhone", formattedPhone);
										} }
										onBlur={ () => handleBlur("representativePhone") }
									/>
								</div>
							</Card>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className="flex justify-end border-none pt-0">
					<Button
						onClick={ handleClose }
						color="gray"
					>
						<span>Anuluj</span>
					</Button>
					<Button
						type="submit"
						isProcessing={ isUpdating }
					>
						<span>Zapisz</span>
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
}

const mapStateToProps = (state: RootState, props: ComponentProps) => ({
	isUpdating: didLoadingRecordExist(state, { loadableId: props.evaluation.id, loadableType: LoadableType.UPDATE_EVALUATION }),
});

export default connect(mapStateToProps)(EditEvaluationModal);
