import { ParticipantSurveyStatus as Status } from "src/app/types/api/participant.types";
import { participantSurveyStatusDictionary } from "src/app/utils/constants/dictionaries";
import { Badge, FlowbiteColors } from "flowbite-react";
import { EnumDictionary } from "src/app/types/util.types";
import classNames from "classnames";

type Props = {
	surveyStatus: Status
};

type DictionaryValue = {
	color: keyof FlowbiteColors
	className: string
}

const statusStyleDictionary: EnumDictionary<Status, DictionaryValue> = {
	[ Status.NOT_SENT ]: {
		color: "failure",
		className: "before:bg-red-500",
	},
	[ Status.NOT_OPENED ]: {
		color: "yellow",
		className: "before:bg-yellow-500",
	},
	[ Status.OPENED ]: {
		color: "blue",
		className: "before:bg-blue-500",
	},
	[ Status.IN_PROGRESS ]: {
		color: "orange",
		className: "before:bg-orange-500",
	},
	[ Status.COMPLETED ]: {
		color: "success",
		className: "before:bg-green-500",
	},
};

function ParticipantSurveyStatus(props: Props) {

	const {
		surveyStatus,
	} = props;

	return (
		<Badge
			size="pill-sm"
			color={ statusStyleDictionary[ surveyStatus ].color }
		>
			<div
				className={
					classNames(
						"relative before:absolute before:content-[''] before:w-3.5 before:h-3.5 before:inset-0 before:rounded-full pl-5",
						statusStyleDictionary[ surveyStatus ].className,
					)
				}
			>
				{ participantSurveyStatusDictionary[ surveyStatus ] }
			</div>
		</Badge>
	);
}

export default (ParticipantSurveyStatus);
