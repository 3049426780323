import { Form } from "src/app/types/ui/form.types";
import { SliderQuestionForm, SurveyForm, SurveyStep, TextQuestionForm } from "src/app/types/ui/survey.types";
import { ExternalSurvey, QuestionAnswerType, SurveyQuestion } from "src/app/types/api/survey.types";
import { createFormField } from "src/app/utils/forms";
import { isNotNull, isNull, isSliderSurveyQuestion, isTextSurveyQuestion } from "src/app/utils/typeguards";
import { AdminSurveyForm } from "src/app/containers/Evaluation/EvaluationParticipantSurvey.container";
import { ParticipantSurveyStatus, SurveyLanguage } from "src/app/types/api/participant.types";

export const mapSurveyToForm = (survey: ExternalSurvey): Form<SurveyForm> => {
	const surveyQuestions = survey.content.questions;
	const language = survey.content.language;
	const status = survey.status;

	const hasSavedQuestions = surveyQuestions.some(question => {
		if (isTextSurveyQuestion(question)) {
			return isNotNull(question.answerTextValue);
		} else if (isSliderSurveyQuestion(question)) {
			return isNotNull(question.answerNumberValue);
		} else {
			return false;
		}
	});
	let step: SurveyStep;
	let questionIndex = surveyQuestions.reduce((prev, next) => Math.min(prev, next.index), 0);
	if (status === ParticipantSurveyStatus.COMPLETED) {
		step = SurveyStep.ALREADY_ANSWERED;
	} else if (hasSavedQuestions) {
		step = SurveyStep.QUESTIONS;
		questionIndex = surveyQuestions.reduce((prev, next) => {
			if (!prev.isPreviousFilled) return prev;
			if (isTextSurveyQuestion(next)) {
				if (isNotNull(next.answerTextValue)) {
					return {
						...prev,
						index: next.index,
					};
				} else {
					return {
						index: next.index,
						isPreviousFilled: false,
					};
				}
			} else if (isSliderSurveyQuestion(next)) {
				if (isNotNull(next.answerNumberValue)) {
					return {
						...prev,
						index: next.index,
					};
				} else {
					return {
						index: next.index,
						isPreviousFilled: false,
					};
				}
			} else {
				return {
					...prev,
					isPreviousFilled: false,
				};
			}
		}, {
			index: questionIndex,
			isPreviousFilled: true,
		}).index;
	} else {
		step = SurveyStep.SURVEY_DESCRIPTION;
	}
	return {
		step: createFormField(step),
		questionIndex: createFormField(questionIndex),
		questions: createFormField(surveyQuestions.map(question => {
			if (isTextSurveyQuestion(question)) {
				const questionForm: Form<TextQuestionForm> = {
					id: createFormField(question.id),
					index: createFormField(question.index),
					title: createFormField(question.title),
					answerType: createFormField(QuestionAnswerType.TEXT),
					answerTextValue: createFormField(question.answerTextValue ?? "", { touched: isNotNull(question.answerTextValue) }),
				};
				return questionForm;
			} else {
				const questionForm: Form<SliderQuestionForm> = {
					id: createFormField(question.id),
					index: createFormField(question.index),
					title: createFormField(question.title),
					answerType: createFormField(QuestionAnswerType.SLIDER),
					answerNumberValue: createFormField((question.answerNumberValue ?? 50).toString(), { touched: isNotNull(question.answerNumberValue) }),
				};
				return questionForm;
			}
		})),
		language: createFormField(language),
	};
};

export const mapSurveyToAdminForm = (surveyQuestions: SurveyQuestion[]): Form<AdminSurveyForm> =>
	({
		questions: createFormField(surveyQuestions.map(question => {
			if (isTextSurveyQuestion(question)) {
				const questionForm: Form<TextQuestionForm> = {
					id: createFormField(question.id),
					index: createFormField(question.index),
					title: createFormField(question.title),
					answerType: createFormField(QuestionAnswerType.TEXT),
					answerTextValue: createFormField(question.answerTextValue ?? "", { touched: isNotNull(question.answerTextValue), disabled: isNull(question.answerTextValue) }),
				};
				return questionForm;
			} else {
				const questionForm: Form<SliderQuestionForm> = {
					id: createFormField(question.id),
					index: createFormField(question.index),
					title: createFormField(question.title),
					answerType: createFormField(QuestionAnswerType.SLIDER),
					answerNumberValue: createFormField((question.answerNumberValue ?? 50).toString(), { touched: isNotNull(question.answerNumberValue), disabled: true }),
				};
				return questionForm;
			}
		})),
		language: createFormField(SurveyLanguage.POLISH),
	});
