import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { getEvaluationName, getOrganizationName, getSurveyParticipantName, getUserFullName } from "src/app/store/features/misc/misc.selectors";
import { isEmptyString, isNotNull, isNull } from "src/app/utils/typeguards";
import { Link, matchRoutes, useLocation, useParams } from "react-router-dom";
import { UseParamsPayload } from "src/app/types/util.types";
import { RouteObject } from "react-router/dist/lib/context";
import { appRoutes } from "src/app/utils/routes/app.routes";
import { useCallback } from "react";
import { Breadcrumb } from "flowbite-react";
import classNames from "classnames";
import { HiChevronRight } from "react-icons/hi";
// Breadcrumbs import plop - don't remove

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		className?: string
	};

const organizationLocationRegex = /^\/organization\/:scopeOrganizationId/;

const translateBreadcrumb = (breadcrumbEl: string): string => {
	const translations = [
		{
			nativeName: "create",
			translation: "tworzenie",
		}, {
			nativeName: "edit",
			translation: "edycja",
		}, {
			nativeName: "evaluations",
			translation: "badania",
		}, {
			nativeName: "users",
			translation: "użytkownicy",
		},
	];
	return translations.find(translation => translation.nativeName === breadcrumbEl)?.translation ?? breadcrumbEl;
};

export const BREADCRUMB_ID = "breadcrumb";
export const BREADCRUMB_PORTAL_ID = "breadcrumb-portal";

function Breadcrumbs(props: Props) {
	const location = useLocation();
	const params: UseParamsPayload = useParams();

	const getBreadcrumbRoutes = useCallback((routes: RouteObject[], withSpacers = true): string[] =>
			routes.map(route => {
				if (isNotNull(route.index) && route.index) { // Map index route
					return withSpacers ? [ "/" ] : [ "" ];
				} else if (isNotNull(route.path) && (isNull(route.children) || route.children.length === 0)) { // Map route without childrens
					return [ route.path ];
				} else if (isNotNull(route.children) && route.children.length !== 0) { // Map route with childrens, call self
					const nestedRoutes = getBreadcrumbRoutes(route.children, false);
					return nestedRoutes.map(nestedRoute =>
						`${ (route.path ?? "") }${ (isNull(route.path) || isEmptyString(nestedRoute)) ? "" : "/" }${ nestedRoute }`,
					);
				}
				return [];
			})
				  .reduce((prev, next) => [ ...prev, ...next ]) // string[][] => string[]
				  .filter((breadcrumbName, i, arr) => arr.indexOf(breadcrumbName) === i), // remove duplicates
		[]);

	const matchedRoutes = matchRoutes(getBreadcrumbRoutes(appRoutes).map(route => ({ path: route })), location);

	const url = (
		(isNotNull(matchedRoutes) && isNotNull(matchedRoutes[ 0 ].route.path))
			?
			matchedRoutes[ 0 ].route.path
			:
			location.pathname
	)
		.replace(organizationLocationRegex, "")
		.replace("/evaluations/:evaluationId/surveys/:surveyId", "/evaluations/:evaluationId/:surveyId")
		.split("/")
		.slice(1); // Prepare url for mapping (delete first /)

	const _getUrlOffset = () => {
		const url = (isNotNull(matchedRoutes) && isNotNull(matchedRoutes[ 0 ].route.path))
			?
			matchedRoutes[ 0 ].route.path
			:
			location.pathname;
		const ORGANIZATION_LOCATION_INDEX_OFFSET = 2;
		if (organizationLocationRegex.test(url)) return ORGANIZATION_LOCATION_INDEX_OFFSET;

		return 0;
	};
	const {
		className,
		getUserFullName,
		getOrganizationName,
		getEvaluationName,
		getSurveyName,
		// Breadcrumbs props plop - don't remove
	} = props;

	if (url.length === 0) return null;

	return (
		<div
			id={ BREADCRUMB_ID }
			className={
				classNames(
					className,
					"flex flex-col",
				)
			}
		>
			<Breadcrumb>
				{
					url.map((breadcrumbEl, index, array) => {
						const breadcrumbName =
							translateBreadcrumb(
								array[ index ]
									.replace(":userId", getUserFullName(params.userId ?? ""))
									.replace(":organizationId", getOrganizationName(params.organizationId ?? ""))
									.replace(":evaluationId", getEvaluationName(params.evaluationId ?? ""))
									.replace(":surveyId", getSurveyName(params.surveyId ?? "")),
								// Breadcrumbs replace plop - don't remove
							);

						const urlIndexOffset = _getUrlOffset();

						const link = location.pathname
											 .split("/")
											 .slice(1)
											 .slice(0, (index + urlIndexOffset + 1))
											 .join("/");

						if (array.length - 1 === index) {
							return (
								<li className="group flex items-center" key={ link }>
									<HiChevronRight className="mx-1 h-6 w-6 text-gray-400 group-first:hidden md:mx-2"/>
									<span className="flex items-center text-sm font-medium text-gray-500 dark:text-gray-400">
										{ `${ breadcrumbName[ 0 ]?.toUpperCase() }${ breadcrumbName.slice(1) }` }
									</span>
								</li>
							);
						}

						return (
							<li className="group flex items-center" key={ link }>
								<HiChevronRight className="mx-1 h-6 w-6 text-gray-400 group-first:hidden md:mx-2"/>
								<Link to={ link } className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
									{ `${ breadcrumbName[ 0 ]?.toUpperCase() }${ breadcrumbName.slice(1) }` }
								</Link>
							</li>
						);
					})
				}
			</Breadcrumb>
			<div id={ BREADCRUMB_PORTAL_ID }/>
		</div>
	);
}

const mapStateToProps = (state: RootState) => ({
	getUserFullName: (userId: string) => getUserFullName(state, userId),
	getOrganizationName: (organizationId: string) => getOrganizationName(state, organizationId),
	getEvaluationName: (evaluationId: string) => getEvaluationName(state, evaluationId),
	getSurveyName: (surveyId: string) => getSurveyParticipantName(state, surveyId),
	// Breadcrumbs mapStateToProps plop - don't remove
});

export default connect(mapStateToProps)(Breadcrumbs);
