import { PropsWithChildren } from "react";
import { ToastContainer, Zoom } from "react-toastify";

function PopupsWrapper(props: PropsWithChildren<{}>) {
	return (
		<>
			{ props.children }
			<ToastContainer
				position="bottom-left"
				autoClose={ 5000 }
				hideProgressBar={ true }
				newestOnTop={ false }
				closeOnClick={ true }
				draggable={ false }
				pauseOnFocusLoss
				pauseOnHover
				transition={ Zoom }
			/>
		</>
	);
}

export default (PopupsWrapper);
