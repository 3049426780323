import { createAction } from "typesafe-actions";
import { BodySize } from "src/app/types/ui/layout.types";

export const setBodySize = createAction("UI_LAYOUT__SET_BODY_SIZE")<BodySize>();
export const throttleBodySize = createAction("UI_LAYOUT__THROTTLE_BODY_SIZE")<BodySize>();

export const setIsDarkTheme = createAction("UI_LAYOUT__SET_APP_THEME")<boolean>();

export const toggleSidebar = createAction("UI_LAYOUT__TOGGLE_SIDEBAR")<boolean>();

export const startListeningBreadcrumbHeight = createAction("UI_LAYOUT__START_LISTENING_BREADCRUMB_HEIGHT")<undefined>();
export const setBreadcrumbHeight = createAction("UI_LAYOUT__SET_BREADCRUMB_HEIGHT")<number>();
export const throttleBreadcrumbHeight = createAction("UI_LAYOUT__THROTTLE_BREADCRUMB_HEIGHT")<number>();
