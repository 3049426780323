import { createSelector } from "reselect";
import { RootState } from "src/app/store/root.reducer";
import { LoadableType, LoadingRecord } from "src/app/types/ui/loading.types";

const loadingReducerSelector = (state: RootState) => state.ui.loading;

export const getFilteredLoadingRecords = createSelector(
	[
		loadingReducerSelector,
		(_, loadableType: LoadableType) => loadableType
	],
	(loadingRecords, loadableType) => loadingRecords.filter(record => record.loadableType === loadableType)
);

export const didLoadingRecordExist = createSelector(
	[
		loadingReducerSelector,
		(_, record: LoadingRecord) => record
	],
	(loadingRecords, searchingRecord): boolean => {
		if (searchingRecord.loadableId == null) {
			return Boolean(loadingRecords.find(record => record.loadableType === searchingRecord.loadableType));
		} else {
			return Boolean(loadingRecords.find(record =>
				record.loadableId === searchingRecord.loadableId &&
				record.loadableType === searchingRecord.loadableType
			));
		}
	}
);
