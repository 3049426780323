import { Navigate, useLocation } from "react-router-dom";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { getDefaultView } from "src/app/utils/constants/constants";
import { getLoggedUser } from "src/app/store/features/user/user.selectors";
import { DataState } from "src/app/types/redux.types";

type Props = ReturnType<typeof mapStateToProps>;

function DefaultRoute(props: Props) {
	const {
		loggedUser,
		userScope,
	} = props;

	const location = useLocation();

	if (loggedUser.dataState === DataState.PRESENT) {
		const organizationId = userScope?.organization?.id ?? loggedUser.data.organizationId;
		return <Navigate to={ { pathname: getDefaultView(organizationId), search: location.search } }/>;
	} else {
		return <Navigate to={ { pathname: "/login", search: location.search } }/>;
	}
}

const mapStateToProps = (state: RootState) => ({
	loggedUser: getLoggedUser(state),
	userScope: state.user.userScope,
});

export default connect(mapStateToProps)(DefaultRoute);
