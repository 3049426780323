import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import { getEvaluationById } from "src/app/store/features/evaluation/evaluation.selectors";
import { fetchEvaluationByIdAsync } from "src/app/store/features/evaluation/evaluation.actions";
import EvaluationProfileContainer from "src/app/containers/Evaluation/EvaluationProfile.container";
import { useParams } from "react-router-dom";
import { uiFinishEvaluation, uiGenerateEvaluationReport, uiPublishEvaluationReport, uiShareEvaluationToRepresentative, uiStartEvaluation, uiUndoShareEvaluationToRepresentative, uiUpdateEvaluation, uiUpdateEvaluationQuestions } from "src/app/store/features/ui/evaluation/ui.evaluation.actions";
import { uiCreateParticipant, uiDeleteParticipant, uiImportParticipants, uiSendReminderToParticipants, uiSendReminderToSubject, uiUpdateParticipant } from "src/app/store/features/ui/participant/ui.participant.actions";
import CachedThenFreshStrategy from "src/app/hoc/caching/CachedThenFreshStrategy.hoc";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function EvaluationProfileView(props: Props) {
	const {
		getEvaluation,
		fetchEvaluationById,
		updateEvaluation,
		updateEvaluationQuestions,
		startEvaluation,
		finishEvaluation,
		generateEvaluationReport,
		publishEvaluationReport,
		createParticipant,
		updateParticipant,
		shareEvaluationToRepresentative,
		undoShareEvaluationToRepresentative,
		sendReminder,
		sendReminderToSubject,
		importParticipants,
		deleteParticipant,
	} = props;

	const { evaluationId = "0" } = useParams();

	return (
		<CachedThenFreshStrategy
			request={ () => fetchEvaluationById(+evaluationId) }
			state={ getEvaluation(+evaluationId) }
		>
			{
				evaluation =>
					<EvaluationProfileContainer
						evaluation={ evaluation }
						onUpdateEvaluation={ updateEvaluation }
						onUpdateEvaluationQuestions={ updateEvaluationQuestions }
						onStartEvaluation={ () => startEvaluation(+evaluationId) }
						onFinishEvaluation={ () => finishEvaluation(+evaluationId) }
						onGenerateEvaluationReport={ () => generateEvaluationReport(+evaluationId) }
						onPublishEvaluationReport={ () => publishEvaluationReport(+evaluationId) }
						onCreateParticipant={ createParticipant }
						onUpdateParticipant={ updateParticipant }
						onSendReminder={ sendReminder }
						onSendReminderToSubject={ sendReminderToSubject }
						onShareEvaluationToRepresentative={ shareEvaluationToRepresentative }
						onUndoShareEvaluationToRepresentative={ undoShareEvaluationToRepresentative }
						onImportParticipants={ (file: File) => importParticipants({ id: +evaluationId, file }) }
						onDeleteParticipant={ (participantId) => deleteParticipant({ evaluationId: +evaluationId, id: participantId }) }
					/>
			}
		</CachedThenFreshStrategy>
	);
}

const mapStateToProps = (state: RootState) => ({
	getEvaluation: (id: number) => getEvaluationById(state, id),
});

const mapDispatchToProps = {
	fetchEvaluationById: fetchEvaluationByIdAsync.request,
	updateEvaluation: uiUpdateEvaluation,
	updateEvaluationQuestions: uiUpdateEvaluationQuestions,
	startEvaluation: uiStartEvaluation,
	finishEvaluation: uiFinishEvaluation,
	generateEvaluationReport: uiGenerateEvaluationReport,
	publishEvaluationReport: uiPublishEvaluationReport,
	createParticipant: uiCreateParticipant,
	updateParticipant: uiUpdateParticipant,
	shareEvaluationToRepresentative: uiShareEvaluationToRepresentative,
	undoShareEvaluationToRepresentative: uiUndoShareEvaluationToRepresentative,
	sendReminder: uiSendReminderToParticipants,
	sendReminderToSubject: uiSendReminderToSubject,
	importParticipants: uiImportParticipants,
	deleteParticipant: uiDeleteParticipant,
};

export default connect(mapStateToProps, mapDispatchToProps)(EvaluationProfileView);
