import { applyMiddleware, createStore } from "redux";
import { createBrowserHistory } from "history";
import { composeWithDevTools } from "@redux-devtools/extension";
import rootReducer, { RootState } from "src/app/store/root.reducer";
import { createEpicMiddleware } from "redux-observable";
import { RootAction } from "src/app/store/root.actions";
import services, { Services } from "src/app/store/services";
import rootEpic from "src/app/store/root.epic";
import { createReduxHistoryContext } from "redux-first-history";

const {
	createReduxHistory,
	routerMiddleware,
	routerReducer,
} = createReduxHistoryContext({
	history: createBrowserHistory(),
});

export const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState, Services>(
	{ dependencies: services },
);

function configureStore(initialState?: any) {
	const enhancers = applyMiddleware(routerMiddleware, epicMiddleware);
	const middleware = process.env.NODE_ENV !== "production" ? composeWithDevTools(enhancers) : enhancers;

	const store = createStore(rootReducer(routerReducer), initialState, middleware);

	epicMiddleware.run(rootEpic);

	window.store = store;
	return store;
}

const store = configureStore();

export const history = createReduxHistory(store);
export default store;
