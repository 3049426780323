import { createSelector } from "reselect";
import { RootState } from "src/app/store/root.reducer";
import { DataState, StateReducer } from "src/app/types/redux.types";
import { DetailedUser, UserTwoFactorAuthentication } from "src/app/types/api/user.types";
import { initialStateReducer } from "src/app/utils/redux";
import { isNotNull } from "src/app/utils/typeguards";

export const getLoggedUser = createSelector(
	[ (state: RootState) => state.user.loggedUser ],
	(loggedUser) => loggedUser,
);

export const isAuthorized = createSelector(
	[ getLoggedUser ],
	(loggedUser) => loggedUser.dataState === DataState.PRESENT,
);

const singleUsersSelector = (state: RootState) => state.user.singleUsers;
const singleUsersTwoFactorAuthenticationSelector = (state: RootState) => state.user.singleUsersTwoFactorAuthentication;

export const getUserById = createSelector(
	[
		singleUsersSelector,
		(_, userId: number) => userId,
	],
	(singleUsers, userId): StateReducer<DetailedUser> => {
		const user = singleUsers.find(user => user.id === userId);
		if (isNotNull(user)) {
			return user.reducer;
		} else {
			return initialStateReducer as StateReducer<DetailedUser>;
		}
	},
);

export const getUserTwoFactorAuthenticationById = createSelector(
	[
		singleUsersTwoFactorAuthenticationSelector,
		(_, userId: number) => userId,
	],
	(singleUsersTwoFA, userId): StateReducer<UserTwoFactorAuthentication> => {
		const twoFactorAuthentication = singleUsersTwoFA.find(twoFA => twoFA.id === userId);
		if (isNotNull(twoFactorAuthentication)) {
			return twoFactorAuthentication.reducer;
		} else {
			return initialStateReducer as StateReducer<UserTwoFactorAuthentication>;
		}
	},
);
