import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import Echo from "laravel-echo";
import axios from "axios";
import { baseUrl, isWebsocketSecure, websocketHost, websocketPort } from "src/app/utils/constants/constants";

(window as any).Pusher = require("pusher-js");

const authorizer = function (channel: { name: string }) {
	return {
		authorize: function (socketId: number, callback: (...args: any[]) => void) {
			axios.post(`${ baseUrl }/broadcasting/auth`, {
				socket_id: socketId,
				channel_name: channel.name,
			}, {
				withCredentials: true,
			})
				 .then(function (response) {
					 console.log(`Channel [${ channel.name }] auth success: `, response);
					 callback(null, response.data);
				 })
				 .catch(function (error) {
					 console.log(`Channel [${ channel.name }] auth failure: `, error);
					 callback(new Error(`Error calling channel auth endpoint: ${ error }`), {
						 auth: "",
					 });
				 });
		},
	};
};

const laravelEcho = new Echo({
	broadcaster: "pusher",
	key: "app-key",
	wsHost: websocketHost,
	wssHost: websocketHost,
	wsPort: websocketPort,
	wssPort: websocketPort,
	forceTLS: isWebsocketSecure,
	encrypted: true,
	enableStats: false,
	enabledTransports: isWebsocketSecure ? [ "ws", "wss" ] : [ "ws" ],
	authorizer: authorizer,
});

const reducer = combineReducers({
	laravelEchoClient: createReducer(laravelEcho),
});

export default reducer;
