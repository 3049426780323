import { Form } from "src/app/types/ui/form.types";
import { QuestionAnswerType } from "src/app/types/api/survey.types";
import { SurveyLanguage } from "src/app/types/api/participant.types";

export enum SurveyStep {
	SURVEY_DESCRIPTION = "SURVEY_DESCRIPTION",
	QUESTIONS = "QUESTIONS",
	THANKS = "THANKS",
	ALREADY_ANSWERED = "ALREADY_ANSWERED"
}

export type SurveyForm = {
	step: SurveyStep
	questionIndex: number
	questions: Form<QuestionForm>[]
	language: SurveyLanguage
}

export type QuestionForm =
	{
		id: number
		index: number
		title: string
	}
	& ({
		   answerType: QuestionAnswerType.TEXT
		   answerTextValue: string
	   } | {
		   answerType: QuestionAnswerType.SLIDER
		   answerNumberValue: string
	   });

export type TextQuestionForm =
	QuestionForm
	& {
		answerType: QuestionAnswerType.TEXT
		answerTextValue: string
	};

export type SliderQuestionForm =
	QuestionForm
	& {
		answerType: QuestionAnswerType.SLIDER
		answerNumberValue: string
	}
