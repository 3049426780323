import { CreateParticipantPayload, ParticipantType, SurveyLanguage } from "src/app/types/api/participant.types";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateEmail, validateField } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import { useEffect } from "react";
import { Button, Modal } from "flowbite-react";
import Input from "src/app/components/Form/Input.component";
import { participantLanguageOptions, participantTypeOptions } from "src/app/utils/constants/constants";
import Select from "src/app/components/Form/Select.component";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		isOpen: boolean
		handleClose: () => void
		evaluationId: number
		onCreate: (payload: CreateParticipantPayload) => void
	};

type CreateParticipantForm = {
	name: string
	email: string
	participantType: ParticipantType
	surveyLanguage: SurveyLanguage
}

const validator: FormValidator<CreateParticipantForm> = {
	name: (name, optional) => validateField(name, "Nazwa jest wymagana", optional),
	email: validateEmail,
	participantType: (participantType, optional) => validateField(participantType, "Typ jest wymagany", optional),
	surveyLanguage: (surveyLanguage, optional) => validateField(surveyLanguage, "Język jest wymagany", optional),
};

function CreateParticipantModal(props: Props) {

	const {
		isOpen,
		handleClose,
		evaluationId,
		onCreate,
		isCreating,
	} = props;

	const _handleSubmit = (values: CreateParticipantForm) => {
		onCreate({
			evaluationId,
			name: values.name,
			email: values.email,
			participantType: values.participantType,
			surveyLanguage: values.surveyLanguage,
		});
		handleClose();
	};

	const _getInitialState = () => ({
		name: createFormField(""),
		email: createFormField(""),
		participantType: createFormField(ParticipantType.SUPERVISOR),
		surveyLanguage: createFormField(SurveyLanguage.POLISH),
	});

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		if (!isOpen && !isCreating) {
			setForm(_getInitialState());
		}
	}, [ isOpen, isCreating ]);

	return (
		<Modal
			show={ isOpen || isCreating }
			onClose={ handleClose }
			size="2xl"
			root={ document.body }
			key={ (isOpen || isCreating) ? "open" : "hidden" } // AutoFocus on input work with this
		>
			<Modal.Header>
				Dodaj nową osobę oceniajaca
			</Modal.Header>
			<form onSubmit={ handleSubmit }>
				<Modal.Body className="overflow-visible">
					<div className="flex flex-col gap-2">
						<Input
							formItem={ form.name }
							label="Nazwa"
							name="name"
							inputProps={ {
								type: "text",
								onChange: (e) => handleChange("name", e.target.value),
								onBlur: () => handleBlur("name"),
							} }
						/>
						<Input
							formItem={ form.email }
							label="Adres e-mail"
							name="email"
							inputProps={ {
								type: "email",
								onChange: (e) => handleChange("email", e.target.value),
								onBlur: () => handleBlur("email"),
							} }
						/>
						<Select
							label="Rola osoby"
							options={ participantTypeOptions.filter(option => option.value !== ParticipantType.SUBJECT) }
							formItem={ form.participantType }
							isClearable={ false }
							isSearchable={ false }
							onChange={ option => {
								handleChange("participantType", option?.value ?? null);
								handleBlur("participantType");
							} }
						/>
						<Select
							label="Język ankiety"
							options={ participantLanguageOptions }
							formItem={ form.surveyLanguage }
							isClearable={ false }
							isSearchable={ false }
							onChange={ option => {
								handleChange("surveyLanguage", option?.value ?? null);
								handleBlur("surveyLanguage");
							} }
						/>
					</div>
				</Modal.Body>
				<Modal.Footer className="flex justify-end border-none pt-0">
					<Button
						onClick={ handleClose }
						color="gray"
					>
						<span>Anuluj</span>
					</Button>
					<Button
						type="submit"
						isProcessing={ isCreating }
					>
						<span>Zapisz</span>
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
}

const mapStateToProps = (state: RootState) => ({
	isCreating: didLoadingRecordExist(state, { loadableType: LoadableType.CREATE_PARTICIPANT }),
});

export default connect(mapStateToProps)(CreateParticipantModal);
