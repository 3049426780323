import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import { ArrayStateReducer } from "src/app/types/redux.types";
import { DetailedSurvey, ExternalSurvey } from "src/app/types/api/survey.types";
import { forgetSession, logoutAsync } from "src/app/store/features/user/user.actions";
import { handleBasicActionsForArray } from "src/app/utils/redux";
import { fetchExternalSurveyAsync, fetchSurveyAsync } from "src/app/store/features/survey/survey.actions";

const reducer = combineReducers({
	externalSurveys: createReducer([] as ArrayStateReducer<ExternalSurvey>)
		.handleAction([ forgetSession, logoutAsync.success ], () => [])
		.handleAction([
			fetchExternalSurveyAsync.request,
			fetchExternalSurveyAsync.success,
			fetchExternalSurveyAsync.failure,
		], handleBasicActionsForArray(fetchExternalSurveyAsync, requestPayload => requestPayload.surveyId)),
	surveys: createReducer([] as ArrayStateReducer<DetailedSurvey>)
		.handleAction([ forgetSession, logoutAsync.success ], () => [])
		.handleAction([
			fetchSurveyAsync.request,
			fetchSurveyAsync.success,
			fetchSurveyAsync.failure,
		], handleBasicActionsForArray(fetchSurveyAsync)),
});

export default reducer;
