import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import ForceFreshStrategy from "src/app/hoc/caching/ForceFreshStrategy.hoc";
import { getOrganizationById } from "src/app/store/features/organization/organization.selectors";
import { fetchOrganizationByIdAsync } from "src/app/store/features/organization/organization.actions";
import OrganizationProfileContainer from "src/app/containers/Organization/OrganizationProfile.container";
import { useParams } from "react-router-dom";

type Props =
    ReturnType<typeof mapStateToProps>
    & typeof mapDispatchToProps;

function OrganizationProfileView(props: Props) {
    const {
        getOrganization,
        fetchOrganizationById,
    } = props;

    const { organizationId = "0" } = useParams();

    return (
        <ForceFreshStrategy
            request={ () => fetchOrganizationById(organizationId) }
            state={ getOrganization(organizationId) }
        >
            {
                organization =>
                    <OrganizationProfileContainer
                        organization={ organization }
                    />
            }
        </ForceFreshStrategy>
    );
}

const mapStateToProps = (state: RootState) => ({
    getOrganization: (id: string) => getOrganizationById(state, id),
});

const mapDispatchToProps = {
    fetchOrganizationById: fetchOrganizationByIdAsync.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationProfileView);