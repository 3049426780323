import { Image, Nullable } from "src/app/types/util.types";
import { FetchPaginatedDataBasicRequest } from "src/app/types/redux.types";
import { Organization, SimpleOrganization } from "src/app/types/api/organization.types";

export enum SelfAbility {
	APP_ADMINISTRATION = "admin.appAdministration",
	// ADMIN_PANEL_ACCESS = "admin.adminPanelAccess",

	SWITCH_ORGANIZATION = "scope.switchOrganizations",

	CREATE_USER = "user.create",
	LIST_USERS = "user.list",
	UPDATE_SELF = "user.updateSelf",

	CREATE_ORGANIZATION = "organization.create",
	LIST_ORGANIZATIONS = "organization.list",

	CREATE_EVALUATION = "evaluation.create",
	LIST_EVALUATIONS = "evaluation.list"
}

export enum UserAbility {
	VIEW = "user.view",
	UPDATE = "user.update",
	UPDATE_2FA = "user.update2FA",
	UPDATE_ROLE = "user.updateRole",
	UPDATE_EMAIL = "user.updateEmail",
	UPDATE_STATUS = "user.updateStatus",
	UPDATE_COMPANY = "user.updateCompany",
	UPDATE_PASSWORD = "user.updatePassword",
	UPDATE_PERMISSIONS = "user.updatePermissions",
	DELETE = "user.delete",
}

export type LoginPayload = {
	email: string
	password: string
	remember: boolean
	otp?: string
};

export type ForgotPasswordPayload = {
	email: string
}

export type ResetPasswordPayload = {
	email: string
	password: string
	token: string
}

export enum UserStatus {
	ACTIVE = "ACTIVE",
	ARCHIVED = "ARCHIVED",
	SUSPENDED = "SUSPENDED",
	INACTIVE = "INACTIVE",
	DELETED = "DELETED",
}

export enum AssignableUserStatus {
	ACTIVE = "ACTIVE",
	ARCHIVED = "ARCHIVED",
	SUSPENDED = "SUSPENDED",
}

export enum UserTwoFAStatus {
	ENABLED = "ENABLED",
	DISABLED = "DISABLED"
}

export enum UserRole {
	SUPER_ADMIN = "SUPER_ADMIN",
	TEAM_MEMBER = "TEAM_MEMBER",
	ORGANIZATION_ADMIN = "ORGANIZATION_ADMIN",
	ORGANIZATION_EMPLOYEE = "ORGANIZATION_EMPLOYEE",
}

export type LoggedUser =
	DetailedUser
	& {
		auth2Fa: Nullable<UserTwoFAStatus>
		meta: {
			abilities: UserAbility[]
			selfAbilities: SelfAbility[]
		}
	};

export type UserPermissionObject = {
	name: UserPermission
	assigned: boolean
	available: boolean
}

export enum UserPermission {
	APP_ADMINISTRATION = "app.admin",
	OTHER_MANAGER_PERFORM = "manager.perform",

	USER_CREATE = "user.create",
	USER_VIEW = "user.view",
	USER_LIST = "user.list",
	USER_UPDATE = "user.update",
	USER_MANAGE = "user.manage",
	USER_MANAGE_ADMIN = "user.manageAdmin",
	USER_DELETE = "user.delete",

	USER_SCOPE_ALL = "user.scope.all",
	USER_SCOPE_COMPANY = "user.scope.company",
	USER_PREVIEW_SCOPE_ALL = "user.scope.previewAll",
	USER_PREVIEW_SCOPE_COMPANY = "user.scope.previewCompany",

	COMPANY_CREATE = "company.create",
	COMPANY_VIEW = "company.view",
	COMPANY_LIST = "company.list",
	COMPANY_UPDATE = "company.update",
	COMPANY_DELETE = "company.delete",

	COMPANY_SCOPE_ALL = "company.scope.all",
	COMPANY_PREVIEW_SCOPE_ALL = "company.scope.previewAll",

	MODEL_CREATE = "model.create",
	MODEL_VIEW = "model.view",
	MODEL_LIST = "model.list",
	MODEL_UPDATE = "model.update",
	MODEL_DELETE = "model.delete",

	MODEL_SCOPE_ALL = "model.scope.all",
	MODEL_SCOPE_COMPANY = "model.scope.company",
	MODEL_PREVIEW_SCOPE_ALL = "model.scope.previewAll",
	MODEL_PREVIEW_SCOPE_COMPANY = "model.scope.previewCompany",
}

export enum PermissionGroup {
	USERS = "USERS",
	COMPANIES = "COMPANIES",
	MODELS = "MODELS",
	OTHERS = "OTHERS"
}

export type SimpleUser = { // UserViewModel::simpleResource
	id: number
	name: string
	surname: string
	status: UserStatus
	image: Nullable<Image>
	organizationId: string
	organization: Nullable<SimpleOrganization>
	roles: UserRole[]
	createdAt: string
	updatedAt: string
	meta: {
		abilities: UserAbility[]
	}
}

export type User = // UserViewModel::standardResource
	SimpleUser
	& {
		email: string
		isAdmin: boolean
	}

export type DetailedUser = // UserViewModel::detailedResource
	User
	& {
		emailVerifiedAt: Nullable<string>
		organization: Organization
		// permissions: UserPermissionObject[]
	}

export type UserOrganizationScope = {
	id: string
	name: string
	image: Nullable<Image>
	createdAt: string
	updatedAt: string
}

export type CreateUserPayload = {
	name: string
	surname: string
	email: string
	phone: string
	role: UserRole
	avatar?: Nullable<File>
}

export type UpdateUserPayload = {
	id: number
	name?: string
	surname?: string
	email?: string
	phone?: string
	role?: UserRole
	avatar?: Nullable<File>
}

export type UpdateUserStatusPayload = {
	id: number
	status: AssignableUserStatus
}

export type UpdateUserPasswordPayload = {
	id: number
	currentPassword: string
	newPassword: string
}

export type UpdateUserPermissionsPayload = {
	id: number
	permissions: UserPermission[]
}

export type ConfirmTwoFactorAuthenticationPayload = {
	id: number
	otp: string
};

export type EnableTwoFactorAuthenticationPayload = {
	secret: string
	qr: string
};

export type UserTwoFactorAuthentication = {
	secret: string
	qr: string
}

// Pagination
export interface FetchUsersRequestPayload extends FetchPaginatedDataBasicRequest {
	sort: Nullable<
		| "name"
		| "-name"
		| "updated_at"
		| "-updated_at"
		| "created_at"
		| "-created_at"
	>;
	filters: {
		user_id: string[]
		status: UserStatus[]
	};
}
