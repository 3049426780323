import { createSelector } from "reselect";
import { RootState } from "src/app/store/root.reducer";
import { StateReducer } from "src/app/types/redux.types";
import { DetailedOrganization } from "src/app/types/api/organization.types";
import { initialStateReducer } from "src/app/utils/redux";
import { isNotNull } from "src/app/utils/typeguards";

const singleOrganizationsSelector = (state: RootState) => state.organization.singleOrganization;

export const getOrganizationById = createSelector(
    [
        singleOrganizationsSelector,
        (_, organizationId: string) => organizationId,
    ],
    (singleOrganizations, organizationId): StateReducer<DetailedOrganization> => {
        const organization = singleOrganizations.find(organization => organization.id === organizationId);
        if (isNotNull(organization)) {
            return organization.reducer;
        } else {
            return initialStateReducer as StateReducer<DetailedOrganization>;
        }
    },
);
