import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { createContext, PropsWithChildren } from "react";
import { LoggedUser } from "src/app/types/api/user.types";
import { getLoggedUser } from "src/app/store/features/user/user.selectors";
import CachedThenFreshStrategy from "src/app/hoc/caching/CachedThenFreshStrategy.hoc";

type Props = ReturnType<typeof mapStateToProps>;

export const LoggedUserContext = createContext(null as unknown as LoggedUser);

function LoggedUserProvider(props: PropsWithChildren<Props>) {

	const {
		loggedUser,
		children,
	} = props;

	return (
		<CachedThenFreshStrategy
			request={ () => undefined }
			state={ loggedUser }
			withoutLoading
		>
			{
				loggedUser =>
					<LoggedUserContext.Provider value={ loggedUser }>
						{ children }
					</LoggedUserContext.Provider>
			}
		</CachedThenFreshStrategy>
	);
}

const mapStateToProps = (state: RootState) => ({
	loggedUser: getLoggedUser(state),
});

export default connect(mapStateToProps)(LoggedUserProvider);
