import { Nullable } from "src/app/types/util.types";
import { Form, FormItem, FormItemError, FormValidator } from "src/app/types/ui/form.types";
import { isEmptyString, isNotNull, isNull } from "src/app/utils/typeguards";
import moment from "moment";

export const createFormField = <T>(value: T, { disabled = false, optional = false, success = false, touched = false } = {}): FormItem<T> =>
	({
		value: value,
		initialValue: value,
		touched: touched,
		error: null,
		success: success,
		disabled: disabled,
		optional: optional,
	});

export const validateForm = <T>(form: Form<T>, validator: FormValidator<T>) => {
	for (const prop in form) {
		const item = form[ prop ];
		if (!item.disabled) {
			const error = validator[ prop ](item.value, item.optional, form);
			form[ prop ] = {
				...form[ prop ],
				error: error,
				success: error === null,
			};
		}
	}
	return { ...form };
};

export const isFormValid = <T>(form: Form<T>) => {
	for (const prop in form) {
		if (form[ prop ].error) {
			return false;
		}
	}
	return true;
};

export const getAllFormValues = <T>(form: Form<T>): T => {
	const values: T = {} as T;
	for (const prop in form) {
		values[ prop ] = form[ prop ].value;
	}
	return values;
};

const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
const phoneRegex = /^\+[1-9]\d{6,14}$/;

const PASSWORD_MIN_LENGTH = 8;
const PASSWORD_MAX_LENGTH = 150;

export const validateField = (
	value: string,
	error: string, // {fieldName} is required
	optional = false,
): FormItemError => {
	if (isEmptyString(value.trim()) && !optional) {
		return error;
	}
	return null;
};

export const validateNullableField = (
	value: Nullable<any>,
	error: string, // {fieldName} is required
	optional = false,
): FormItemError => {
	if (isNull(value) && !optional) {
		return error;
	}

	return null;
};

export const validateJSON = (
	value: string,
	errorRequired: string, // {fieldName} is required
	errorInvalidStructure: string, // {fieldName} has invalid structure
	optional = false,
): FormItemError => {
	if (isEmptyString(value.trim()) && !optional) {
		return errorRequired;
	}

	try {
		JSON.parse(value);
	} catch (e) {
		if (!isEmptyString(value.trim())) {
			return errorInvalidStructure;
		}
	}

	return null;
};

export const validateNumberField = (
	value: number | string,
	errorRequired: string, // {fieldName} is required
	errorInvalid: string, // {fieldName} is invalid
	errorGreater: string, // {fieldName} must be greater than or equal to {from}
	errorLesser: string, // {fieldName} must be less than or equal to {to}
	optional = false,
	from = 0,
	to?: number,
): FormItemError => {
	const numberValue = +value;
	if ((isNull(value) || isEmptyString(value)) && !optional) {
		return errorRequired;
	}
	if (isNaN(numberValue)) {
		return errorInvalid;
	}
	if (numberValue < from) {
		return errorGreater;
	}
	if (isNotNull(to) && numberValue > to) {
		return errorLesser;
	}
	return null;
};

export const validateDate = (
	value: Nullable<Date>,
	errorRequired: string, // {fieldName} is required
	errorInvalid: string, // {fieldName} is invalid
	optional = false,
): FormItemError => {
	if (isNull(value) && !optional) {
		return errorRequired;
	}

	if (!moment(value).isValid()) {
		return errorInvalid;
	}

	return null;
};

export const validateFutureDate = (
	value: Nullable<Date>,
	errorRequired: string, // {fieldName} is required
	errorInvalid: string, // {fieldName} is invalid
	errorPast: string, // {fieldName} is past
	optional = false,
): FormItemError => {
	if (isNull(value) && !optional) {
		return errorRequired;
	}

	if (!moment(value).isValid()) {
		return errorInvalid;
	}

	if (moment(value).isBefore(moment().add(1, "minute"), "minute")) {
		return errorPast;
	}

	return null;
};

export const validateArrayField = (
	array: any[],
	errorRequired: string, // {fieldName} is required
	errorMinimumElements: string, // {fieldName} need to have minimum of {length} elements
	optional = false,
	length = 0,
): FormItemError => {
	if (array.length === 0 && !optional) {
		return errorRequired;
	}
	if (array.length < length && !optional) {
		return errorMinimumElements;
	}
	return null;
};

export const validateEmail = (email: string, optional = false): FormItemError => {
	if (!isEmptyString(email.trim()) && !emailRegex.test(email)) {
		return "Wprowadź poprawny adres e-mail";
	}
	if (isEmptyString(email.trim()) && !optional) {
		return "Adres e-mail jest wymagany";
	}
	return null;
};

export const validatePhone = (phone: string, optional = false): FormItemError => {
	if (!isEmptyString(phone.trim()) && !phoneRegex.test(phone)) {
		return "Wprowadź poprawny numer telefonu";
	}
	if (isEmptyString(phone.trim()) && !optional) {
		return `Numer telefonu jest wymagany`;
	}

	const PHONE_MIN_LENGTH = 5;
	const PHONE_MAX_LENGTH = 20;

	if (!isEmptyString(phone) && phone.length < PHONE_MIN_LENGTH) {
		return `Numer telefonu musi składać się z przynajmniej ${ PHONE_MIN_LENGTH } znaków`;
	}
	if (!isEmptyString(phone) && phone.length > PHONE_MAX_LENGTH) {
		return `Numer telefonu może składać się z najwięcej ${ PHONE_MAX_LENGTH } znaków`;
	}

	return null;
};

export const validatePassword = (password: string, optional = false): FormItemError => {
	if (!isEmptyString(password) && password.length < PASSWORD_MIN_LENGTH) {
		return `Hasło musi się składać z przynajmniej ${ PASSWORD_MIN_LENGTH } znaków`;
	}
	if (!isEmptyString(password) && password.length > PASSWORD_MAX_LENGTH) {
		return `Hasło może składać się z najwięcej ${ PASSWORD_MAX_LENGTH } znaków`;
	}
	return validateField(password, "Hasło jest wymagane", optional);
};

export const validateStrongPassword = (password: string, optional = false): FormItemError => {
	const errors: string[] = [];
	if (!isEmptyString(password) && password.length < PASSWORD_MIN_LENGTH) {
		errors.push(`Hasło musi się składać z przynajmniej ${ PASSWORD_MIN_LENGTH } znaków`);
	}
	if (!isEmptyString(password) && password.trim().length < PASSWORD_MIN_LENGTH) {
		errors.push("Hasło nie może zaczynać/kończyć się białym znakiem");
	}
	if (!isEmptyString(password) && password.length > PASSWORD_MAX_LENGTH) {
		errors.push(`Hasło może składać się z najwięcej ${ PASSWORD_MAX_LENGTH } znaków`);
	}
	if (!isEmptyString(password.trim()) && !/[A-Z]/.test(password)) {
		errors.push("Hasło musi zawierać przynajmniej jedną wielką litere");
	}
	if (!isEmptyString(password.trim()) && !/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/.test(password)) {
		errors.push("Hasło musi zawierać przynajmniej jeden znak specjalny");
	}
	if (!isEmptyString(password.trim()) && !/\d/.test(password)) {
		errors.push("Hasło musi zawierać przynajmniej jedną cyfre");
	}

	if (errors.length > 1) {
		return errors;
	} else if (errors.length === 1) {
		return errors[ 0 ];
	}

	return validateField(password, "Hasło jest wymagane", optional);
};

export const comparePasswords = (password: string, passwordConfirmed: string): FormItemError => {
	if (password !== passwordConfirmed) {
		return "Hasła nie są takie same";
	}
	return null;
};

enum PhotoFormats {
	JPEG = "image/jpeg",
	PNG = "image/png"
}

export const validatePhoto = (
	file: Nullable<File>,
	errorRequired: string, // {fieldName} is required
	errorMaxSize: string, // {fieldName} can be up to {maxSizeInMegaBytes} MB
	optional = false,
	maxSizeInMegaBytes = 10,
): FormItemError => {
	if (isNull(file) && !optional) {
		return errorRequired;
	}

	if (isNotNull(file) && !Object.values<string>(PhotoFormats).includes(file.type)) {
		return "Niepoprawny format pliku";
	}

	if (isNotNull(file) && file.size > maxSizeInMegaBytes * 1024 * 1024) {
		return errorMaxSize;
	}

	return null;
};

export const compareStrings = (
	firstString: string,
	secondString: string,
	error: string, // Invalid {fieldName}
	ignoreCase = true,
): FormItemError => {
	if ((ignoreCase && firstString !== secondString) || (!ignoreCase && firstString.toLowerCase() !== secondString.toLowerCase())) {
		return error;
	}

	return null;
};
