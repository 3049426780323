/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { createContext, PropsWithChildren } from "react";
import { UserScope } from "src/app/types/ui/user.types";
import { isNull } from "src/app/utils/typeguards";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";

type Props = ReturnType<typeof mapStateToProps>

export const UserScopeContext = createContext<UserScope>(null!);

function UserScopeProvider(props: PropsWithChildren<Props>) {

	const {
		userScope,
		children,
	} = props;

	if (isNull(userScope)) return null;

	return (
		<UserScopeContext.Provider value={ userScope }>
			{ children }
		</UserScopeContext.Provider>
	);
}

const mapStateToProps = (state: RootState) => ({
	userScope: state.user.userScope,
});

export default connect(mapStateToProps)(UserScopeProvider);
