import { RootState } from "src/app/store/root.reducer";
import { createSelector } from "reselect";
import { StateReducer } from "src/app/types/redux.types";
import { isNotNull } from "src/app/utils/typeguards";
import { initialStateReducer } from "src/app/utils/redux";
import { DetailedSurvey, ExternalSurvey } from "src/app/types/api/survey.types";

const singleExternalSurveysSelector = (state: RootState) => state.survey.externalSurveys;
const singleSurveysSelector = (state: RootState) => state.survey.surveys;

export const getExternalSurveyById = createSelector(
	[
		singleExternalSurveysSelector,
		(_, externalSurveyId: number) => externalSurveyId,
	],
	(externalSingleSurveys, surveyId): StateReducer<ExternalSurvey> => {
		const survey = externalSingleSurveys.find(survey => survey.id === surveyId);
		if (isNotNull(survey)) {
			return survey.reducer;
		} else {
			return initialStateReducer as StateReducer<ExternalSurvey>;
		}
	},
);

export const getSurveyById = createSelector(
	[
		singleSurveysSelector,
		(_, surveyId: number) => surveyId,
	],
	(singleSurveys, surveyId): StateReducer<DetailedSurvey> => {
		const survey = singleSurveys.find(survey => survey.id === surveyId);
		if (isNotNull(survey)) {
			return survey.reducer;
		} else {
			return initialStateReducer as StateReducer<DetailedSurvey>;
		}
	},
);
