import { QuestionForm, SurveyForm, SurveyStep } from "src/app/types/ui/survey.types";
import { Form, FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateField } from "src/app/utils/forms";
import { QuestionAnswerType } from "src/app/types/api/survey.types";
import { SurveyLanguage } from "src/app/types/api/participant.types";

export const surveyInitialState: Form<SurveyForm> = {
	step: createFormField(SurveyStep.SURVEY_DESCRIPTION),
	questionIndex: createFormField(0),
	questions: createFormField([]),
	language: createFormField(SurveyLanguage.POLISH),
};

export const surveyValidator: FormValidator<SurveyForm> = {
	step: () => null,
	questionIndex: () => null,
	questions: () => null,
	language: () => null,
};

export const questionValidator: FormValidator<QuestionForm> = {
	id: () => null,
	index: () => null,
	title: () => null,
	answerType: () => null,
	answerNumberValue: (answerNumberValue, optional, form) => {
		if (form.answerType.value !== QuestionAnswerType.SLIDER) return null;

		if (+answerNumberValue === 50) return "Nie możesz zostawić odpowiedzi w pozycji początkowej";

		return null;
	},
	answerTextValue: (answerTextValue, optional, form) => {
		if (form.answerType.value !== QuestionAnswerType.TEXT) return null;

		return validateField(answerTextValue, "Udziel odpowiedzi na powyższe pytanie", optional);
	},
};

export const englishQuestionValidator: FormValidator<QuestionForm> = {
	id: () => null,
	index: () => null,
	title: () => null,
	answerType: () => null,
	answerNumberValue: (answerNumberValue, optional, form) => {
		if (form.answerType.value !== QuestionAnswerType.SLIDER) return null;

		if (+answerNumberValue === 50) return "You can't leave the answer in the initial position";

		return null;
	},
	answerTextValue: (answerTextValue, optional, form) => {
		if (form.answerType.value !== QuestionAnswerType.TEXT) return null;

		return validateField(answerTextValue, "Provide an answer to the above question", optional);
	},
};
