import { SnackbarMessageType, ToastType } from "src/app/types/ui/message.types";
import { connect } from "react-redux";
import { hideToast } from "src/app/store/features/message/message.actions";
import SnackbarComponent from "src/app/components/Message/Snackbar.component";
import { EnumDictionary } from "src/app/types/util.types";

type Props = {
	variant: ToastType
	content: string
	toastId: string | number
};

const toastTypeToSnackbarTypeDictionary: EnumDictionary<ToastType, SnackbarMessageType> = {
	[ ToastType.ERROR ]: SnackbarMessageType.ERROR,
	[ ToastType.INFO ]: SnackbarMessageType.INFO,
	[ ToastType.SUCCESS ]: SnackbarMessageType.SUCCESS,
	[ ToastType.WARNING ]: SnackbarMessageType.WARNING,
};

/* You can use hideToast on custom UI */
function NotificationContent(props: Props) {
	return (
		<SnackbarComponent
			className="shadow-md"
			type={ toastTypeToSnackbarTypeDictionary[ props.variant ] }
			message={ props.content }
			onDismiss={ () => undefined }
		/>
	);
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
	hideToast: hideToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationContent);
