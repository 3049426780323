import userRoutes from "src/app/utils/routes/private/user.routes";
import PaginationView from "src/app/views/Pagination/Pagination.view";
import organizationRoutes from "src/app/utils/routes/private/organization.routes";
import evaluationRoutes from "src/app/utils/routes/private/evaluation.routes";
// Routes import plop - don't remove

const privateRoutes = [
	userRoutes,
	{
		path: "cases",
		element: <PaginationView/>,
	},
	organizationRoutes,
	evaluationRoutes,
	// Routes add route plop - don't remove
];

export default privateRoutes;
