import { filter, map } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { displayToast, hideToast } from "src/app/store/features/message/message.actions";
import { RootEpic } from "src/app/store/root.epic";
import { empty } from "src/app/store/features/misc/misc.actions";

export const displayNotificationEpic: RootEpic = (action$, state$, api) =>
	action$.pipe(
		filter(isActionOf(displayToast)),
		map(action => {
			api.toast.show(action.payload, state$.value.message.notificationCounter);
			return empty();
		}),
	);

export const hideNotificationEpic: RootEpic = (action$, state$, api) =>
	action$.pipe(
		filter(isActionOf(hideToast)),
		map(action => {
			api.toast.hide(action.payload);
			return empty();
		}),
	);
