import { Params, UNSAFE_NavigationContext as NavigationContext, useLocation, useNavigate, useParams } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import { Nullable } from "src/app/types/util.types";
import { isNotNull } from "src/app/utils/typeguards";
import { Location } from "history";

const userNestedRoutes = [
	"/user/:userId",
	"/user/:userId/a",
	"/user/:userId/b",
	"/user/:userId/c",
];

const isIncludesRoutes = (pathname: string, nextPathname: string, routes: string[]) =>
	routes.includes(pathname) && routes.includes(nextPathname);

type ModelName =
	":userId"

const replaceIds = (routes: string[], modelName: ModelName, modelId: string) =>
	routes.map(route => route.replace(modelName, modelId));

const shouldBlockNavigation = (params: Readonly<Params>, location: string, nextLocation: string) => {
	const {
		userId = "",
	} = params;

	return (
		true
		/* Agent */
		// !isIncludesRoutes(location, nextLocation, replaceIds(userNestedRoutes, ":userId", userId))
	);
};

export function useFormPrompt(when: boolean, shouldCheckNestedRoutes = false): void {

	const params = useParams();

	const navigate = useNavigate();
	const location = useLocation();
	const [ lastLocation, setLastLocation ] = useState<Nullable<{ location: Location }>>(null);
	const [ confirmedNavigation, setConfirmedNavigation ] = useState(false);

	// handle blocking when user click on another route prompt will be shown
	const handleBlockedNavigation = useCallback(
		(nextLocation: { location: Location }) => {
			// in if condition we are checking next location and current location are equals or not
			setLastLocation(nextLocation);

			const queryParams = new URLSearchParams(nextLocation.location.search);
			if (queryParams.has("success")) {
				confirmNavigation();
				return false;
			}

			if (!shouldCheckNestedRoutes) {
				if (shouldBlockNavigation(params, location.pathname, nextLocation.location.pathname)) {
					if ((nextLocation.location.state as any)?.forcePrompt ?? false) {
						confirmNavigation();
					} else if (window.confirm("Wprowadzone zmiany mogą nie zostać zapisane. Czy napewno chcesz opuścić tą stronę?")) {
						confirmNavigation();
					}
				} else {
					confirmNavigation();
				}
				return false;
			} else {
				if (location.pathname === nextLocation.location.pathname) return true;
				if ((nextLocation.location.state as any)?.forcePrompt ?? false) {
					confirmNavigation();
					return false;
				}

				if (window.confirm("Wprowadzone zmiany mogą nie zostać zapisane. Czy napewno chcesz opuścić tą stronę?")) {
					confirmNavigation();
					return false;
				}
			}

			return true;
		},
		[ confirmedNavigation, location, shouldCheckNestedRoutes ],
	);

	const confirmNavigation = useCallback(() => {
		setConfirmedNavigation(true);
	}, []);

	useEffect(() => {
		if (confirmedNavigation && isNotNull(lastLocation)) {
			navigate(lastLocation.location.pathname);

			// Clean-up state on confirmed navigation
			setConfirmedNavigation(false);
		}
	}, [ confirmedNavigation, lastLocation ]);

	useBlocker(handleBlockedNavigation, when);
}

export function useBlocker(blocker: (nextLocation: { location: Location }) => boolean, when: boolean) {
	const { navigator } = useContext(NavigationContext);

	useEffect(() => {
		if (!when) return;

		const unblock = (navigator as any).block((tx: any) => {
			const autoUnblockingTx = {
				...tx,
				retry() {
					unblock();
					tx.retry();
				},
			};

			blocker(autoUnblockingTx);
		});

		return unblock;
	}, [ navigator, blocker, when ]);
}
