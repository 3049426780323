/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { filter, map } from "rxjs/operators";
import { apiAsync } from "src/app/store/features/api/api.actions";
import { isActionOf } from "typesafe-actions";
import { RootEpic } from "src/app/store/root.epic";
import { FailurePayload, SuccessPayload } from "src/app/types/api/api.types";
import { DetailedEvaluation, ExternalEvaluation } from "src/app/types/api/evaluation.types";
import { archiveEvaluationAsync, createEvaluationAsync, deleteEvaluationByIdAsync, fetchEvaluationByIdAsync, fetchEvaluationsAsync, fetchExternalEvaluationByIdAsync, finishEvaluationAsync, generateEvaluationReportAsync, publishEvaluationReportAsync, shareEvaluationToRepresentativeAsync, startEvaluationAsync, undoShareEvaluationToRepresentativeAsync, updateEvaluationAsync } from "src/app/store/features/evaluation/evaluation.actions";

export const fetchEvaluationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchEvaluationsAsync.request)),
		map(() =>
			apiAsync.request({
				url: `/evaluations`,
				method: "GET",
				withScope: true,
				onSuccess: fetchEvaluationsAsync.success,
				onFailure: fetchEvaluationsAsync.failure,
			}),
		),
	);

export const fetchEvaluationByIdEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchEvaluationByIdAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/evaluations/${ action.payload }`,
				method: "GET",
				withScope: true,
				onSuccess: (payload: SuccessPayload<DetailedEvaluation>) => fetchEvaluationByIdAsync.success({ ...payload, id: action.payload }),
				onFailure: (payload: FailurePayload) => fetchEvaluationByIdAsync.failure({ ...payload, id: action.payload }),
			}),
		),
	);

export const fetchExternalEvaluationByIdEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchExternalEvaluationByIdAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/external/evaluations/${ action.payload.evaluationId }`,
				method: "GET",
				withScope: false,
				headers: {
					ExternalEmail: action.payload.email,
					ExternalToken: action.payload.token,
				},
				withoutNotification: true,
				onSuccess: (payload: SuccessPayload<ExternalEvaluation>) => fetchExternalEvaluationByIdAsync.success({ id: action.payload.evaluationId, ...payload }),
				onFailure: (payload: FailurePayload) => fetchExternalEvaluationByIdAsync.failure({ id: action.payload.evaluationId, ...payload }),
			}),
		),
	);

export const createEvaluationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(createEvaluationAsync.request)),
		map(action =>
			apiAsync.request({
				url: "/evaluations",
				method: "POST",
				data: action.payload,
				withScope: true,
				onSuccess: createEvaluationAsync.success,
				onFailure: createEvaluationAsync.failure,
			}),
		),
	);

export const updateEvaluationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(updateEvaluationAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/evaluations/${ action.payload.id }`,
				method: "PUT",
				data: action.payload,
				withScope: true,
				onSuccess: updateEvaluationAsync.success,
				onFailure: updateEvaluationAsync.failure,
			}),
		),
	);

export const archiveEvaluationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(archiveEvaluationAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/evaluations/${ action.payload }/archive`,
				method: "POST",
				withScope: true,
				onSuccess: archiveEvaluationAsync.success,
				onFailure: archiveEvaluationAsync.failure,
			}),
		),
	);

export const shareEvaluationToRepresentativeEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(shareEvaluationToRepresentativeAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/evaluations/${ action.payload }/share-representative`,
				method: "POST",
				withScope: true,
				onSuccess: shareEvaluationToRepresentativeAsync.success,
				onFailure: shareEvaluationToRepresentativeAsync.failure,
			}),
		),
	);

export const undoShareEvaluationToRepresentativeEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(undoShareEvaluationToRepresentativeAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/evaluations/${ action.payload }/undo-share-representative`,
				method: "POST",
				withScope: true,
				onSuccess: undoShareEvaluationToRepresentativeAsync.success,
				onFailure: undoShareEvaluationToRepresentativeAsync.failure,
			}),
		),
	);

export const startEvaluationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(startEvaluationAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/evaluations/${ action.payload }/start`,
				method: "POST",
				withScope: true,
				onSuccess: startEvaluationAsync.success,
				onFailure: startEvaluationAsync.failure,
			}),
		),
	);

export const finishEvaluationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(finishEvaluationAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/evaluations/${ action.payload }/finish`,
				method: "POST",
				withScope: true,
				onSuccess: finishEvaluationAsync.success,
				onFailure: finishEvaluationAsync.failure,
			}),
		),
	);

export const generateEvaluationReportEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(generateEvaluationReportAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/evaluations/${ action.payload }/generate-report`,
				method: "POST",
				withScope: true,
				onSuccess: generateEvaluationReportAsync.success,
				onFailure: generateEvaluationReportAsync.failure,
			}),
		),
	);

export const publishEvaluationReportEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(publishEvaluationReportAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/evaluations/${ action.payload }/publish-report`,
				method: "POST",
				withScope: true,
				onSuccess: publishEvaluationReportAsync.success,
				onFailure: publishEvaluationReportAsync.failure,
			}),
		),
	);

export const deleteEvaluationByIdEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(deleteEvaluationByIdAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/evaluations/${ action.payload }`,
				method: "DELETE",
				withScope: true,
				onSuccess: deleteEvaluationByIdAsync.success,
				onFailure: deleteEvaluationByIdAsync.failure,
			}),
		),
	);
