/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { createAction } from "typesafe-actions";
import { ChangeEvaluationManagerRequestPayload, CreateEvaluationPayload, UpdateEvaluationPayload } from "src/app/types/api/evaluation.types";

export const uiCreateEvaluation = createAction("UI_EVALUATION__CREATE_EVALUATION")<CreateEvaluationPayload>();
export const uiUpdateEvaluation = createAction("UI_EVALUATION__UPDATE_EVALUATION")<UpdateEvaluationPayload>();
export const uiUpdateEvaluationQuestions = createAction("UI_EVALUATION__UPDATE_EVALUATION_QUESTIONS")<UpdateEvaluationPayload>();
export const uiDeleteEvaluation = createAction("UI_EVALUATION__DELETE_EVALUATION")<number>();

export const uiChangeEvaluationCoordinatorUser = createAction("UI_EVALUATION__CHANGE_EVALUATION_COORDINATOR_USER")<ChangeEvaluationManagerRequestPayload>();
export const uiArchiveEvaluation = createAction("UI_EVALUATION__ARCHIVE_EVALUATION")<number>();
export const uiShareEvaluationToRepresentative = createAction("UI_EVALUATION__SHARE_EVALUATION_TO_REPRESENTATIVE")<number>();
export const uiUndoShareEvaluationToRepresentative = createAction("UI_EVALUATION__UNDO_SHARE_EVALUATION_TO_REPRESENTATIVE")<number>();

export const uiStartEvaluation = createAction("UI_EVALUATION__START_EVALUATION")<number>();
export const uiFinishEvaluation = createAction("UI_EVALUATION__FINISH_EVALUATION")<number>();
export const uiGenerateEvaluationReport = createAction("UI_EVALUATION__GENERATE_EVALUATION_REPORT")<number>();
export const uiPublishEvaluationReport = createAction("UI_EVALUATION__PUBLISH_EVALUATION_REPORT")<number>();
