import { RouteObject } from "react-router/dist/lib/context";
import { lazy } from "react";

const AnswerSurveyView = lazy(() => import("src/app/views/Public/AnswerSurvey.view"));
const EvaluationExternalProfileView = lazy(() => import("src/app/views/Public/EvaluationExternalProfile.view"));

const publicRoutes: RouteObject[] = [
	{
		path: "/answer-survey/:surveyId",
		element: <AnswerSurveyView/>,
	}, {
		path: "/evaluations/:evaluationId",
		element: <EvaluationExternalProfileView/>,
	},
];

export default publicRoutes;
