import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import { Nullable } from "src/app/types/util.types";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateNullableField } from "src/app/utils/forms";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import useForm from "src/app/utils/hooks/useForm";
import { useEffect } from "react";
import { Button, Modal } from "flowbite-react";
import FileInput from "src/app/components/Form/FileInput.component";
import FilePreview from "src/app/components/Form/FilePreview.component";
import { ErrorCode, FileRejection } from "react-dropzone";

type ComponentProps = {
	evaluationId: number
	isOpen: boolean
	handleClose: () => void
	onImport: (file: File) => void
};

type Props =
	ReturnType<typeof mapStateToProps>
	& ComponentProps;

type ImportParticipantsForm = {
	file: Nullable<File>
}

const validator: FormValidator<ImportParticipantsForm> = {
	file: (file, optional) => validateNullableField(file, "Plik CSV jest wymagany", optional),
};

function ImportParticipantsModal(props: Props) {

	const {
		isOpen,
		handleClose,
		onImport,
		isUploading,
	} = props;

	const _handleSubmit = (values: ImportParticipantsForm) => {
		if (isNull(values.file)) return;

		onImport(values.file);
		handleClose();
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
		setError,
	} = useForm({
		file: createFormField(null),
	}, validator, _handleSubmit);

	useEffect(() => {
		if (!isOpen && !isUploading) {
			setForm({
				file: createFormField(null),
			});
		}
	}, [ isOpen, isUploading ]);

	return (
		<Modal
			show={ isOpen || isUploading }
			onClose={ handleClose }
			size="2xl"
			root={ document.body }
			key={ (isOpen || isUploading) ? "open" : "hidden" } // AutoFocus on input work with this
		>
			<Modal.Header>
				Zaimportuj oceniających
			</Modal.Header>
			<form onSubmit={ handleSubmit }>
				<Modal.Body className="overflow-visible">
					<div className="flex flex-col gap-2">
						<FileInput
							name="file"
							className="[&>div:first-child]:py-4 flex-grow"
							onChange={ file => {
								handleChange("file", file);
								handleBlur("file");
							} }
							options={ {
								accept: {
									"text/csv": [ ".csv" ],
								},
								onDropRejected: (fileRejections: FileRejection[]) => {
									handleChange("file", null);
									if (fileRejections.some(rejection => rejection.errors.some(error => error.code === ErrorCode.FileInvalidType))) {
										setError("file", "Niepoprawny format pliku. Akceptowalne rozszerzenie to .csv");
									} else if (fileRejections.some(rejection => rejection.errors.some(error => error.code === ErrorCode.FileTooLarge))) {
										setError("file", "Zbyt duży rozmiar pliku, maksymalny rozmiar to 100 MB");
									} else if (fileRejections.some(rejection => rejection.errors.some(error => error.code === ErrorCode.TooManyFiles))) {
										setError("file", "Przesłano zbyt dużo plików");
									}
								},
							} }
							error={ form.file.error }
						/>
						{
							isNotNull(form.file.value) &&
                            <FilePreview
                                path={ form.file.value.name }
                            />
						}
						{

						}
					</div>
				</Modal.Body>
				<Modal.Footer className="flex justify-end border-none pt-0">
					<Button
						onClick={ handleClose }
						color="gray"
					>
						<span>Anuluj</span>
					</Button>
					<Button
						type="submit"
						isProcessing={ isUploading }
					>
						<span>Importuj</span>
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
}

const mapStateToProps = (state: RootState, props: ComponentProps) => ({
	isUploading: didLoadingRecordExist(state, { loadableId: props.evaluationId, loadableType: LoadableType.IMPORT_PARTICIPANTS }),
});

export default connect(mapStateToProps)(ImportParticipantsModal);
