import { ParticipantType, SimpleParticipant, SurveyLanguage, UpdateParticipantPayload } from "src/app/types/api/participant.types";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateEmail, validateField } from "src/app/utils/forms";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import useForm from "src/app/utils/hooks/useForm";
import { useEffect } from "react";
import { Button, Modal } from "flowbite-react";
import Input from "src/app/components/Form/Input.component";
import Select from "src/app/components/Form/Select.component";
import { participantLanguageOptions, participantTypeOptions } from "src/app/utils/constants/constants";

type ComponentProps = {
	isOpen: boolean
	handleClose: () => void
	onUpdate: (payload: UpdateParticipantPayload) => void
	participant: SimpleParticipant
	evaluationId: number
};

type Props =
	ReturnType<typeof mapStateToProps>
	& ComponentProps;

type EditParticipantForm = {
	name: string
	email: string
	participantType: ParticipantType
	surveyLanguage: SurveyLanguage
}

const validator: FormValidator<EditParticipantForm> = {
	name: (name, optional) => validateField(name, "Nazwa jest wymagana", optional),
	email: validateEmail,
	participantType: (participantType, optional) => validateField(participantType, "Typ jest wymagany", optional),
	surveyLanguage: (surveyLanguage, optional) => validateField(surveyLanguage, "Język jest wymagany", optional),
};

function EditParticipantModal(props: Props) {

	const {
		isOpen,
		handleClose,
		onUpdate,
		participant,
		evaluationId,
		isUpdating,
	} = props;

	const _handleSubmit = (values: EditParticipantForm) => {
		onUpdate({
			id: participant.id,
			evaluationId,
			name: values.name,
			email: values.email,
			participantType: values.participantType,
			surveyLanguage: values.surveyLanguage,
		});
		handleClose();
	};

	const _getInitialState = () => ({
		name: createFormField(participant.name),
		email: createFormField(participant.email),
		participantType: createFormField(participant.participantType),
		surveyLanguage: createFormField(participant.surveyLanguage),
	});

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [ participant ]);

	useEffect(() => {
		if (!isOpen && !isUpdating) {
			setForm(_getInitialState());
		}
	}, [ isOpen, isUpdating ]);

	return (
		<Modal
			show={ isOpen || isUpdating }
			onClose={ handleClose }
			size="2xl"
			root={ document.body }
			key={ (isOpen || isUpdating) ? "open" : "hidden" } // AutoFocus on input work with this
		>
			<Modal.Header>
				Edytuj osobę oceniającą
			</Modal.Header>
			<form onSubmit={ handleSubmit }>
				<Modal.Body className="overflow-visible">
					<div className="flex flex-col gap-2">
						<Input
							formItem={ form.name }
							label="Nazwa"
							name="name"
							inputProps={ {
								type: "text",
								onChange: (e) => handleChange("name", e.target.value),
								onBlur: () => handleBlur("name"),
							} }
						/>
						<Input
							formItem={ form.email }
							label="Adres e-mail"
							name="email"
							inputProps={ {
								type: "email",
								onChange: (e) => handleChange("email", e.target.value),
								onBlur: () => handleBlur("email"),
							} }
						/>
						{
							participant.participantType !== ParticipantType.SUBJECT &&
                            <Select
                                label="Rola osoby"
                                options={ participantTypeOptions.filter(option => option.value !== ParticipantType.SUBJECT) }
                                formItem={ form.participantType }
                                isClearable={ false }
                                isSearchable={ false }
                                onChange={ option => {
									handleChange("participantType", option?.value ?? null);
									handleBlur("participantType");
								} }
                            />
						}
						<Select
							label="Język ankiety"
							options={ participantLanguageOptions }
							formItem={ form.surveyLanguage }
							isClearable={ false }
							isSearchable={ false }
							onChange={ option => {
								handleChange("surveyLanguage", option?.value ?? null);
								handleBlur("surveyLanguage");
							} }
						/>
					</div>
				</Modal.Body>
				<Modal.Footer className="flex justify-end border-none pt-0">
					<Button
						onClick={ handleClose }
						color="gray"
					>
						<span>Anuluj</span>
					</Button>
					<Button
						type="submit"
						isProcessing={ isUpdating }
					>
						<span>Zapisz</span>
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
}

const mapStateToProps = (state: RootState, props: ComponentProps) => ({
	isUpdating: didLoadingRecordExist(state, { loadableId: props.participant.id, loadableType: LoadableType.UPDATE_PARTICIPANT }),
});

export default connect(mapStateToProps)(EditParticipantModal);
