import { AxiosRequestHeaders, Method } from "axios";
import { PayloadAction, TypeConstant } from "typesafe-actions/dist/type-helpers";

export type SuccessPayload<T> = {
	success: true
	data: T
	meta?: SuccessPayloadMeta
};

export type SuccessPayloadWithId<T, R = number | string> = {
	success: true
	data: T
	id: R
	meta?: SuccessPayloadMeta
};

export type SuccessPayloadMeta = {
	pageIndex?: number
	totalCount?: number
}

export type FailurePayload = {
	success: false
	errors: Error[]
};

export type Response =
	SuccessPayload<any>
	| FailurePayload;

export type FailurePayloadWithId<R = number | string> = {
	success: false
	errors: Error[]
	id: R
};

export type Error = {
	httpStatus: number
	codeName: ErrorCodeName
	message: string
	operation?: string
	limitPerMinute?: number
	attemptsLeft?: number
	availableInSeconds?: number
};

export type ApiRequest = {
	url: string
	method: Method
	data?: any
	headers?: AxiosRequestHeaders
	withScope: boolean
	onSuccess: PayloadActionCreator<string, SuccessPayload<any>> /*| SuccessPayloadWithIdCreator<any>*/
	onFailure: PayloadActionCreator<string, FailurePayload>
	withoutNotification?: boolean
};

/*type SuccessPayloadWithIdCreator<T> = (payload: SuccessPayload<T>) => PayloadActionCreator<string, SuccessPayloadWithId<T>>;*/

//Overwrite typesafe-actions PayloadActionCreator
type PayloadActionCreator<TType extends TypeConstant, TPayload> = (payload: TPayload, camelize?: { camelize: boolean }) => PayloadAction<TType, TPayload>

/* Make onSuccess, onFailure fields optional because .handleAction gets crazy without it */
export type ApiSuccess<T> = {
	data: T
	onSuccess?: PayloadActionCreator<string, SuccessPayload<T> /*| SuccessPayloadWithIdCreator<T>*/>
};

export type ApiFailure<T> = {
	data: T
	onFailure?: PayloadActionCreator<string, T>
	withoutNotification?: boolean
};

export enum ErrorCodeName {
	// Generic
	GENERIC = "GENERIC",
	GENERIC_NOT_FOUND = "GENERIC_NOT_FOUND",
	GENERIC_NOT_SUPPORTED = "GENERIC_NOT_SUPPORTED",
	GENERIC_NOT_IMPLEMENTED = "GENERIC_NOT_IMPLEMENTED",
	GENERIC_THROTTLE_TOO_MANY_REQUESTS = "GENERIC_THROTTLE_TOO_MANY_REQUESTS",
	UNAUTHORIZED_SIGNATURE_INVALID = "UNAUTHORIZED_SIGNATURE_INVALID",
	GENERIC_METHOD_NOT_ALLOWED = "GENERIC_METHOD_NOT_ALLOWED",
	GENERIC_HEALTHCHECK_FAILED = "GENERIC_HEALTHCHECK_FAILED",

	// Auth
	GENERIC_UNAUTHENTICATED = "GENERIC_UNAUTHENTICATED",
	UNAUTHENTICATED_INVALID_CREDENTIALS = "UNAUTHORIZED_INVALID_CREDENTIALS",
	UNAUTHENTICATED_MISSING_2FA_OTP = "UNAUTHORIZED_MISSING_2FA_OTP",
	UNAUTHENTICATED_INVALID_2FA_OTP = "UNAUTHORIZED_INVALID_2FA_OTP",
	UNAUTHENTICATED_RESTRICTED_ACCESS = "UNAUTHORIZED_RESTRICTED_ACCESS",
	UNAUTHENTICATED_USER_EMAIL_NOT_CONFIRMED = "UNAUTHENTICATED_USER_EMAIL_NOT_CONFIRMED",

	// Validation
	GENERIC_VALIDATION_FAIL = "GENERIC_VALIDATION_FAIL",

	// Verdicts
	GENERIC_UNAUTHORIZED = "GENERIC_UNAUTHORIZED",
	GENERIC_INVALID_OPERATION = "GENERIC_INVALID_OPERATION",
}
