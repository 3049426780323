import { createSelector } from "reselect";
import { RootState } from "src/app/store/root.reducer";
import { StateReducer } from "src/app/types/redux.types";
import { DetailedEvaluation, ExternalEvaluation } from "src/app/types/api/evaluation.types";
import { initialStateReducer } from "src/app/utils/redux";
import { isNotNull } from "src/app/utils/typeguards";

const singleEvaluationsSelector = (state: RootState) => state.evaluation.singleEvaluation;
const externalEvaluationsSelector = (state: RootState) => state.evaluation.externalEvaluations;

export const getEvaluationById = createSelector(
	[
		singleEvaluationsSelector,
		(_, evaluationId: number) => evaluationId,
	],
	(singleEvaluations, evaluationId): StateReducer<DetailedEvaluation> => {
		const evaluation = singleEvaluations.find(evaluation => evaluation.id === evaluationId);
		if (isNotNull(evaluation)) {
			return evaluation.reducer;
		} else {
			return initialStateReducer as StateReducer<DetailedEvaluation>;
		}
	},
);

export const getExternalEvaluationById = createSelector(
	[
		externalEvaluationsSelector,
		(_, evaluationId: number) => evaluationId,
	],
	(externalEvaluations, evaluationId): StateReducer<ExternalEvaluation> => {
		const evaluation = externalEvaluations.find(evaluation => evaluation.id === evaluationId);
		if (isNotNull(evaluation)) {
			return evaluation.reducer;
		} else {
			return initialStateReducer as StateReducer<ExternalEvaluation>;
		}
	},
);
