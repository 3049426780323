/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { RootEpic } from "src/app/store/root.epic";
import { isActionOf } from "typesafe-actions";
import { uiCreateOrganization, uiDeleteOrganization, uiUpdateOrganization } from "src/app/store/features/ui/organization/ui.organization.actions";
import { filter, mergeMap, switchMap, take } from "rxjs/operators";
import { concat, merge, of } from "rxjs";
import { addLoadingRecord, removeLoadingRecord } from "src/app/store/features/ui/loading/ui.loading.actions";
import { LoadableType, LoadingRecord } from "src/app/types/ui/loading.types";
import { createOrganizationAsync, deleteOrganizationByIdAsync, updateOrganizationAsync } from "src/app/store/features/organization/organization.actions";
import { displayToast } from "src/app/store/features/message/message.actions";
import { ToastType } from "src/app/types/ui/message.types";

export const uiCreateOrganizationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiCreateOrganization)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableType: LoadableType.CREATE_ORGANIZATION };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(createOrganizationAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(createOrganizationAsync.success, action) || isActionOf(createOrganizationAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(createOrganizationAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Organization created successfully" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiUpdateOrganizationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiUpdateOrganization)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload.id, loadableType: LoadableType.UPDATE_ORGANIZATION };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(updateOrganizationAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(updateOrganizationAsync.success, action) || isActionOf(updateOrganizationAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(updateOrganizationAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Organization updated successfully" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiDeleteOrganizationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiDeleteOrganization)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload, loadableType: LoadableType.DELETE_ORGANIZATION };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(deleteOrganizationByIdAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(deleteOrganizationByIdAsync.success, action) || isActionOf(deleteOrganizationByIdAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(deleteOrganizationByIdAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Organization deleted successfully" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);
