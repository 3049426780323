import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { forgetSession } from "src/app/store/features/user/user.actions";
import { isAuthorized } from "src/app/store/features/user/user.selectors";
import { isNotNull } from "src/app/utils/typeguards";
import { displayToast } from "src/app/store/features/message/message.actions";
import { ToastType } from "src/app/types/ui/message.types";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

enum RouteAction {
	RESET_PASSWORD = "reset-password",
	ANSWER_EXTERNAL_SURVEY = "survey-answer-external",
	PREVIEW_EVALUATION = "preview-evaluation"
}

function CheckQueryParamsView(props: Props) {

	const {
		isAuthorized,
		displayToast,
		forgetSession,
	} = props;
	const { action = "" } = useParams();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const params = new URLSearchParams(location.search);

		switch (action as RouteAction) {
			// Doesn't check url structure - related view should do it
			case RouteAction.RESET_PASSWORD:
				const resetPasswordLink = `/reset-password${ location.search }`;
				if (isAuthorized) forgetSession();
				navigate(resetPasswordLink);
				break;
			case RouteAction.ANSWER_EXTERNAL_SURVEY:
				const surveyId = params.get("surveyId");
				const surveyEmail = params.get("email");
				const surveyToken = params.get("token");
				if (isNotNull(surveyId) && isNotNull(surveyEmail) && isNotNull(surveyToken)) {
					navigate({
						pathname: `/answer-survey/${ surveyId }`,
						search: `email=${ surveyEmail }&token=${ surveyToken }`,
					});
				} else {
					displayToast({ type: ToastType.ERROR, content: "Niepoprawna struktura linku" });
					navigate("/");
				}
				break;
			case RouteAction.PREVIEW_EVALUATION:
				const evaluationId = params.get("evaluationId");
				const evaluationEmail = params.get("email");
				const evaluationToken = params.get("token");
				if (isNotNull(evaluationId) && isNotNull(evaluationEmail) && isNotNull(evaluationToken)) {
					navigate({
						pathname: `/evaluations/${ evaluationId }`,
						search: `email=${ evaluationEmail }&token=${ evaluationToken }`,
					});
				} else {
					displayToast({ type: ToastType.ERROR, content: "Niepoprawna struktura linku" });
					navigate("/");
				}
				break;
			default:
				navigate("/");
				break;
		}
	}, []);

	return null;
}

const mapStateToProps = (state: RootState) => ({
	isAuthorized: isAuthorized(state),
});

const mapDispatchToProps = {
	forgetSession: forgetSession,
	displayToast: displayToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckQueryParamsView);
