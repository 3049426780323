/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2023.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { FormItem } from "src/app/types/ui/form.types";
import { Label, LabelProps, Textarea, TextareaProps } from "flowbite-react";
import { RefAttributes } from "react";
import classNames from "classnames";
import { isNotNull, isNull } from "src/app/utils/typeguards";

type Props = {
	className?: string
	formItem: FormItem<string>
	name: string // Friendly name for HTML Semantic
	inputProps?: TextareaProps & RefAttributes<HTMLTextAreaElement>
	labelProps?: LabelProps
	label: string | ReactNode
	displayErrorMessage?: boolean
	role?: string
}

function TextArea(props: Props) {

	const {
		className,
		formItem: {
			value,
			error,
			disabled,
		},
		name,
		inputProps = {},
		labelProps = {},
		label,
		displayErrorMessage = true,
		role,
	} = props;

	const _getErrorMessage = () => {
		if (isNull(error)) return null;

		if (displayErrorMessage) {
			if (error instanceof Array) {
				return (
					<ul className="form__error--list">
						{
							error.map((err, i) =>
								<li key={ i } className="mb-1">{ err }</li>,
							)
						}
					</ul>
				);
			} else {
				return error;
			}
		} else {
			return " ";
		}
	};

	return (
		<div
			className={
				classNames(
					"flex flex-col gap-y-0.5",
					className,
				)
			}
			role={ role }
		>
			<Label
				htmlFor={ name }
				{ ...labelProps }
			>
				{ label }
			</Label>
			<Textarea
				{ ...inputProps }
				name={ name }
				color={ isNotNull(error) ? "failure" : undefined }
				disabled={ disabled }
				helperText={ isNotNull(error) ? <><span className="font-medium" role="input-error">{ _getErrorMessage() }</span></> : inputProps.helperText }
				value={ isNotNull(value) ? value : undefined }
				className={ classNames(
					"p-[10px]",
					{ "dark:bg-dark-inputBg dark:border-dark-borderColor": !error },
					inputProps?.className,
				) }
			/>
		</div>
	);
}

export default TextArea;

