import { SnackbarMessageType } from "src/app/types/ui/message.types";
import { EnumDictionary } from "src/app/types/util.types";
import { HiCheckCircle, HiExclamationCircle, HiInformationCircle } from "react-icons/hi";
import { HiExclamationTriangle } from "react-icons/hi2";
import { Alert, FlowbiteColors } from "flowbite-react";
import { IconType } from "react-icons";

type SnackbarProps = {
	className?: string
	type: SnackbarMessageType
	message: string | ReactNode
	onDismiss?: () => void
};

type SnackbarDictionaryValue = {
	icon: IconType
	color: keyof FlowbiteColors
}

const snackbarDictionary: EnumDictionary<SnackbarMessageType, SnackbarDictionaryValue> = {
	[ SnackbarMessageType.ERROR ]: {
		icon: HiExclamationCircle,
		color: "failure",
	},
	[ SnackbarMessageType.INFO ]: {
		icon: HiInformationCircle,
		color: "info",
	},
	[ SnackbarMessageType.SUCCESS ]: {
		icon: HiCheckCircle,
		color: "success",
	},
	[ SnackbarMessageType.WARNING ]: {
		icon: HiExclamationTriangle,
		color: "warning",
	},
};

function SnackbarMessage(props: SnackbarProps) {
	const {
		className = "",
		type,
		message,
		onDismiss,
	} = props;

	const { icon, color } = snackbarDictionary[ type ];

	return (
		<Alert
			color={ color }
			icon={ icon }
			className={ className }
			onDismiss={ onDismiss }
		>
      		<span>
				<p>
					{ message }
				</p>
      		</span>
		</Alert>
	);
}

export default (SnackbarMessage);
