import { lazy } from "react";
import { RouteObject } from "react-router/dist/lib/context";

const UserListView = lazy(() => import("src/app/views/User/UserList.view"));
const UserProfileView = lazy(() => import("src/app/views/User/UserProfile.view"));
const EditUserView = lazy(() => import("src/app/views/User/EditUser.view"));
const CreateUserView = lazy(() => import("src/app/views/User/CreateUser.view"));

const userRoutes: RouteObject = {
	path: "users",
	children: [
		{
			index: true,
			element: <UserListView/>,
		}, {
			path: "create",
			element: <CreateUserView/>,
		}, {
			path: ":userId",
			element: <UserProfileView/>,
		}, {
			path: ":userId/edit",
			element: <EditUserView/>,
		},
	],
};

export default userRoutes;
