import { Nullable } from "src/app/types/util.types";
import { Organization, SimpleOrganization } from "src/app/types/api/organization.types";
import { Participant, ParticipantSurveyStatus, SurveyLanguage } from "src/app/types/api/participant.types";
import { Evaluation } from "src/app/types/api/evaluation.types";

export enum SurveyPersonType {
	EVALUATION_OF_OTHERS = "EVALUATION_OF_OTHERS",
	SELF_EVALUATION = "SELF_EVALUATION"
}

export enum QuestionType {
	INITIAL = "INITIAL",
	STANDARD = "STANDARD",
}

export enum QuestionAnswerType {
	SLIDER = "SLIDER",
	TEXT = "TEXT"
}

export type FetchExternalSurveyRequest = {
	surveyId: number
	email: string
	token: string
}

export type SimpleSurvey = { // SurveyViewModel::simpleResource
	id: number
	emailToken: string
	organizationId: string
	organization: Nullable<SimpleOrganization>
	createdAt: string
	updatedAt: string
}

export type Survey = // SurveyViewModel::standardResource
	SimpleSurvey
	& {}

export type DetailedSurvey = // SurveyViewModel::detailedResource
	Survey
	& {
		organization: Organization
		participant: Participant
		evaluation: Evaluation
		content: SurveyContent
	}

export type ExternalSurvey = // SurveyExternalViewModel::detailedResource
	{
		id: number
		status: ParticipantSurveyStatus
		content: SurveyContent
	}

export type SurveyContent = { // SurveyContentViewModel::standardResource
	type: SurveyPersonType
	language: SurveyLanguage
	questions: SurveyQuestion[]
}

export type SurveyQuestion = // SurveyQuestionViewModel::standardResource
	{
		id: number
		index: number
		questionType: QuestionType
		title: string
	}
	& ({
		   answerType: QuestionAnswerType.TEXT
		   answerTextValue: Nullable<string>
	   } | {
		   answerType: QuestionAnswerType.SLIDER
		   answerNumberValue: Nullable<number>
	   });

export type SurveyTextQuestion =
	SurveyQuestion
	& {
		answerType: QuestionAnswerType.TEXT
		answerTextValue: Nullable<string>
	};

export type SurveySliderQuestion =
	SurveyQuestion
	& {
		answerType: QuestionAnswerType.SLIDER
		answerNumberValue: Nullable<number>
	};

export type SaveExternalSurveyPayload = {
	id: number
	email: string
	token: string
	questions: SaveSurveyQuestion[]
}

export type SaveSurveyPayload = {
	surveyId: number
	evaluationId: number
	questions: SaveSurveyQuestion[]
}

export type SubmitSurveyPayload = {
	id: number
	evaluationId: number
	questions: SaveSurveyQuestion[]
}

export type SaveSurveyQuestion =
	{
		id: number
	}
	& ({
		   answerType: QuestionAnswerType.TEXT
		   answerTextValue: string
	   } | {
		   answerType: QuestionAnswerType.SLIDER
		   answerNumberValue: number
	   })
