import { BREADCRUMB_PORTAL_ID } from "src/app/components/Layout/Breadcrumbs.component";
import { createPortal } from "react-dom";
import { isNull } from "src/app/utils/typeguards";
import { PropsWithChildren } from "react";
import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";

function BreadcrumbPortal(props: PropsWithChildren) {

	const {
		children,
	} = props;

	const breadcrumbPortal = document.getElementById(BREADCRUMB_PORTAL_ID);

	if (isNull(breadcrumbPortal)) return null;

	return createPortal(
		<>{ children }</>,
		breadcrumbPortal,
	);
}

const mapStateToProps = (state: RootState) => ({
	breadcrumbHeight: state.ui.layout.breadcrumbHeight, // need to be here for rerender
})

export default connect(mapStateToProps)(BreadcrumbPortal);
