import { RouteObject } from "react-router/dist/lib/context";
import DefaultRoute from "src/app/hoc/routes/Default.route";
import AuthRoute from "src/app/hoc/routes/Auth.route";
import authRoutes from "src/app/utils/routes/auth.routes";
import PrivateRoute from "src/app/hoc/routes/Private.route";
import privateRoutes from "src/app/utils/routes/private.routes";
import NotFoundView from "src/app/views/Utils/NotFound.view";
import CheckQueryParamsView from "src/app/views/Utils/CheckQueryParams.view";
import ImpersonateUserView from "src/app/views/Utils/ImpersonateUser.view";
import { lazy } from "react";
import PublicRoute from "src/app/hoc/routes/Public.route";
import publicRoutes from "src/app/utils/routes/public.routes";

const PublicLayout = lazy(() => import("src/app/hoc/layouts/Public.layout"));
const AuthLayout = lazy(() => import("src/app/hoc/layouts/Auth.layout"));
const DashboardLayout = lazy(() => import("src/app/hoc/layouts/Dashboard.layout"));

export const appRoutes: RouteObject[] = ([
	{
		index: true,
		element: <DefaultRoute/>,
	},
	{
		element: <PublicRoute/>,
		children: [
			{
				element: <PublicLayout/>,
				children: publicRoutes,
			},
		],
	},
	{
		element: <AuthRoute/>,
		children: [
			{
				element: <AuthLayout/>,
				children: authRoutes,
			},
		],
	},
	{
		element: <PrivateRoute/>,
		children: [
			{
				element: <DashboardLayout/>,
				children: [
					{
						path: "/organization/:scopeOrganizationId",
						children: privateRoutes,
					},
					{
						path: "/impersonate-user",
						element: <ImpersonateUserView/>,
					},
					{
						// Not found page redirect to /login when isAuthorized === false
						path: "*",
						element: <NotFoundView/>,
					},
				],
			},
		],
	},
	{
		path: "/route/:action",
		element: <CheckQueryParamsView/>,
	},
]);
