// Toasts
export enum ToastType {
	INFO = "info",
	SUCCESS = "success",
	WARNING = "warning",
	ERROR = "error"
}

export type DisplayToastPayload = {
	type: ToastType
	content: string
};

// Messages
export enum View {
	LOGIN = "LOGIN",
	FORGOT_PASSWORD = "FORGOT_PASSWORD",
	FORGOT_PASSWORD_MESSAGE = "FORGOT_PASSWORD_MESSAGE",
	TWO_FACTOR_AUTHENTICATION_MODAL = "TWO_FACTOR_AUTHENTICATION_MODAL"
}

export enum SnackbarMessageType {
	ERROR = "ERROR",
	WARNING = "WARNING",
	INFO = "INFO",
	SUCCESS = "SUCCESS"
}

export type SnackbarMessage = {
	view: View
	type: SnackbarMessageType
	message: string | string[] | ReactNode
};

export type ReducerSnackbarMessage =
	SnackbarMessage
	& {
		id: number
	};
